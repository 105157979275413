import React, { useState } from 'react';
import styled from 'styled-components';
import { size } from '../../globalStyles.js';
import SemaforoDirecta from '../UI/SemaforoDirecta.js';
import SemaforoInversa from '../UI/SemaforoInversa.js';

const CardCuadrado = (props) => {
  const [colorBorde, setColorBorde] = useState(null);

  const updateColorBorde = (color) => {
    setColorBorde(color);
  };

  const truncToPrecision = (num, precision) => {
    return Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision);
  };

  return (
    <Container>
      <LabelCont>
        <Label>{props.etiqueta}</Label>
      </LabelCont>

      {colorBorde === 'verde' ? (
        <DimensionVerde>
          <span style={{ fontWeight: 'bold' }}>
            {truncToPrecision(props.resultado, props.precision)}&nbsp;&nbsp;
          </span>{' '}
          {props.unidad}
        </DimensionVerde>
      ) : null}
      {colorBorde === 'naranja' ? (
        <DimensionNaranja>
          <span style={{ fontWeight: 'bold' }}>
            {truncToPrecision(props.resultado, props.precision)}&nbsp;&nbsp;
          </span>{' '}
          {props.unidad}
        </DimensionNaranja>
      ) : null}
      {colorBorde === 'roja' ? (
        <DimensionRoja>
          <span style={{ fontWeight: 'bold' }}>
            {truncToPrecision(props.resultado, props.precision)}&nbsp;&nbsp;
          </span>{' '}
          {props.unidad}
        </DimensionRoja>
      ) : null}
      {colorBorde === null || colorBorde === undefined ? (
        <Dimension>
          <span style={{ fontWeight: 'bold' }}>
            {truncToPrecision(props.resultado, props.precision)}&nbsp;&nbsp;
          </span>{' '}
          {props.unidad}
        </Dimension>
      ) : null}

      {props.semaforoInversa && (
        <SemaforoInversa
          resultado={props.resultado}
          limite1={props.limite1}
          limite2={props.limite2}
          updateColorBorde={updateColorBorde}
        />
      )}
      {props.semaforoDirecta && (
        <SemaforoDirecta
          resultado={props.resultado}
          limite1={props.limite1}
          limite2={props.limite2}
          updateColorBorde={updateColorBorde}
        />
      )}
      {!(props.semaforoDirecta || props.semaforoInversa) && (
        <div style={{ height: '64px' }}></div>
      )}

      <Description>{props.description}</Description>
    </Container>
  );
};

export default CardCuadrado;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 435px;
  background: var(--gdwhite);
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  color: var(--verydark);
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 2rem 1rem;

  @media (max-width: ${size.md}) {
    width: 97.5%;
    height: auto;
  }
`;

const Description = styled.p`
  color: var(--verydark);
  line-height: 1.2;
  text-align: justify;
  max-width: 390px;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const LabelCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark);
  border-radius: 5px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 107px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const Label = styled.h5`
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
`;

const Dimension = styled.div`
  background: #a6a6a638;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.5rem;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid var(--verydark);
  margin-bottom: 1rem;
`;

const DimensionVerde = styled(Dimension)`
  border-bottom: 2px solid rgb(33, 156, 35);
`;
const DimensionNaranja = styled(Dimension)`
  border-bottom: 2px solid rgb(240, 148, 0);
`;
const DimensionRoja = styled(Dimension)`
  border-bottom: 2px solid var(--danger);
`;
