import React from 'react';
import styled from 'styled-components';
import { Column, Row } from '../../globalStyles';

const CardSuma = (props) => {
  const truncToPrecisionZeros = (num, precision) => {
    return (
      Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision)
    ).toFixed(precision);
  };

  return (
    <Container>
      <Row stackMobile style={{ columnGap: '3rem', rowGap: '2rem' }}>
        <Column stackMobile width={45} alignY={'start'} alignX={'center'}>
          <LabelCont>
            <Label>{props.etiqueta}</Label>
          </LabelCont>
          <Description>{props.description}</Description>
        </Column>
        <Column stackMobile width={55} alignY={'center'} alignX={'end'}>
          <Sumandos>
            {props.sumandos.map((elem, idx) => (
              <>
                <SumLabel>
                  <p>
                    {elem[0]}&nbsp;
                    {<sup>{idx}</sup>}
                  </p>
                </SumLabel>

                <SumDimension>
                  {truncToPrecisionZeros(elem[2], props.precision)}
                </SumDimension>
                <SumUnit>{props.unidad}</SumUnit>
              </>
            ))}
          </Sumandos>
          <Dimension>
            <span style={{ fontWeight: 'bold' }}>
              {truncToPrecisionZeros(props.resultado, props.precision)}
              &nbsp;&nbsp;
            </span>{' '}
            {props.unidad}
          </Dimension>
          <AclaraDim>{props.comment}</AclaraDim>
          {props.sumandos.map((elem, idx) => (
            <SumDesc>
              <p>
                <sup>{idx}</sup>&nbsp;{elem[1]}
              </p>
            </SumDesc>
          ))}
        </Column>
      </Row>
    </Container>
  );
};

export default CardSuma;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97.5%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 2rem 1rem;
`;

const LabelCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark);
  border-radius: 5px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 107px;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const Label = styled.h5`
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
`;

const Description = styled.p`
  color: var(--verydark);
  line-height: 1.2;
  text-align: justify;
  max-width: 390px;
  font-size: 1rem;
  font-weight: bold;

  p {
    margin-bottom: 1rem;
  }
`;

const Dimension = styled.div`
  background: #a6a6a638;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid var(--verydark);
  margin-bottom: 1rem;
  align-self: flex-end;
`;

const Sumandos = styled.div`
  display: grid;
  grid-template-columns: 2fr 2rem 1.7rem;
  grid-template-areas: 'label dim unit';
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  border-bottom: 1px solid var(--verydark);
  margin-bottom: 0.5rem;
  width: 100%;
`;

const SumLabel = styled.h5`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.2;
  padding: 0 0.5rem 0 0;
  grid-area: 'label';
`;
const SumDesc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: justify;
  font-size: 0.7rem;
  line-height: 1.2;
  grid-area: 'desc';
  width: 100%;

  sup {
    font-size: 0.6rem;
  }
`;
const SumDimension = styled.p`
  grid-area: 'dim';
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AclaraDim = styled.em`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const SumUnit = styled.p`
  grid-area: 'unit';
`;
