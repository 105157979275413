// icon:arrow-up | Tabler Icons https://tablericons.com/ | Csaba Kissi

function IconArrowUp(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M12 5v14M18 11l-6-6M6 11l6-6" />
    </svg>
  );
}

export default IconArrowUp;
