export const eventos = [
  {
    Evento: 6,
    HR: 0.00093,
  },
  {
    Evento: 10,
    HR: 0.0029,
  },
  {
    Evento: 12,
    HR: 0.0045,
  },
  {
    Evento: 17,
    HR: 0.011,
  },
  {
    Evento: 17.01,
    HR: 0.012,
  },
  {
    Evento: 26,
    HR: 0.083,
  },
  {
    Evento: 43,
    HR: 0.1,
  },
  {
    Evento: 44,
    HR: 0.062,
  },
  {
    Evento: 45,
    HR: 0.54,
  },
  {
    Evento: 63,
    HR: 0.44,
  },
  {
    Evento: 87,
    HR: 0.5,
  },
  {
    Evento: 91,
    HR: 0.49,
  },
];

export const ticksX = [0.0001, 0.001, 0.01, 0.1, 1, 5];
export const ticksY = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
