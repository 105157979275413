//CORE
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
//STYLES
import {
  FullSectionContainer,
  ResponsiveContainer,
  SectionTitle,
  size,
  truncToPrecision,
} from '../../globalStyles';
//IMAGES
import coronaVirus from '../../images/coronavirusBlue.svg';
//COMPONENTS
import Card from '../UI/Card';
import CardCuadrado from '../UI/CardCuadrado.js';
import NavigationButtons from '../UI/NavigationButtons.js';
import CardSumaDescBelow from '../UI/CardSumaDescBelow.js';
import CardList from '../UI/CardList.js';
import NivelRiesgo from '../UI/NivelRiesgo';
//DATA
import { EFICIENCIA_RENOVACION, LIMITE_PPM } from '../../data/constantes';
//GRAPH
import ReactLineChart from '../rechart/LineChart.js';
import TasaAtaqueGraph from '../rechart/TasaAtaque.js';
import BrotesGraph from '../rechart/Brotes.js';
import legendMobile from '../../images/leyendasm.svg';
import legendMobileEN from '../../images/leyendasmEN.svg';
import legendDesktop from '../../images/leyendaxl.svg';
import legendDesktopEN from '../../images/leyendaxlEN.svg';
import legendMobileTA from '../../images/leyendasmTA.svg';
import legendMobileTAEN from '../../images/leyendasmTAEN.svg';
import legendDesktopTA from '../../images/leyendaxlTA.svg';
import legendDesktopTAEN from '../../images/leyendaxlTAEN.svg';
import legendMobileBrote from '../../images/leyendasmBrote.svg';
import legendMobileBroteEN from '../../images/leyendasmBroteEN.svg';
import legendDesktopBrote from '../../images/leyendaxlBrote.svg';
import legendDesktopBroteEN from '../../images/leyendaxlBroteEN.svg';
import xasis from '../../images/xasis.svg';
import xasisEN from '../../images/xasisEN.svg';
import yasis from '../../images/yasis.svg';
import yasisEN from '../../images/yasisEN.svg';
import xasisTA from '../../images/xasisTA.svg';
import xasisTAEN from '../../images/xasisTAEN.svg';
import yasisTA from '../../images/yasisTA.svg';
import yasisTAEN from '../../images/yasisTAEN.svg';

//Lang
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';
import FormNavigation from './FormNavigation.js';

const Resultados = (props) => {
  const lang = useContext(langContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Correccion de personas infectivas cuando se introduce que son mas o igual que las presentes.
  useEffect(() => {
    if (props.stateData.infectivas >= props.stateData.personas) {
      props.updateDatos(props.stateData.personas - 1, 'infectivas');
    }
  }, [props.stateData.infectivas, props.stateData.personas]);

  const [limites, setLimites] = useState({});
  const [datos, setDatos] = useState({});

  useEffect(() => {
    setLimites((state) => ({
      ...state,
      harvardCo2: 300 + props.stateData.co2Exterior,
      ida2Co2: 500 + props.stateData.co2Exterior,
    }));
  }, [props.stateData.co2Exterior]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tiempoAerosolesInfectivosAire: Number(
        truncToPrecisionZeros(
          -(
            Math.log(1 - EFICIENCIA_RENOVACION) /
            props.stateData.tasaPerdidaPrimerOrden
          ) * 60,
          0
        )
      ),
    }));
  }, [props.stateData.tasaPerdidaPrimerOrden]);

  const truncToPrecisionZeros = (num, precision) => {
    return (
      Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision)
    ).toFixed(precision);
  };

  const [data, setData] = useState([]);
  const [domainMaxY, setDomainMaxY] = useState(1100);
  const [duracion, setDuracion] = useState(60);
  const [nivelDuracion, setNivelDuracion] = useState(420);

  // Funcion para calcular valor ppm Interior en cualquier momento dado (Grafica)
  const ppmT = useCallback(
    (t) => {
      return (
        (props.stateData.paramA0 *
          Math.exp(
            (-props.stateData.ventilacionMetrosCubSeg * (t * 60)) /
              props.stateData.volumen
          ) +
          props.stateData.paramQ) *
        1000000
      );
    },
    [
      props.stateData.paramA0,
      props.stateData.ventilacionMetrosCubSeg,
      props.stateData.volumen,
      props.stateData.paramQ,
    ]
  );

  // Funcion para calcular valor ppm Ficticio sumando efecto de medidas adicionales de control en cualquier momento dado (Grafica)
  const ppmTHVAC = useCallback(
    (t) => {
      return (
        (props.stateData.paramA0HVAC *
          Math.exp(
            (-props.stateData.ventilacionMetrosCubSegHVAC * (t * 60)) /
              props.stateData.volumen
          ) +
          props.stateData.paramQHVAC) *
        1000000
      );
    },
    [
      props.stateData.paramA0HVAC,
      props.stateData.ventilacionMetrosCubSegHVAC,
      props.stateData.volumen,
      props.stateData.paramQHVAC,
    ]
  );

  // Calcular Coordenadas para Marks en la grafica
  useEffect(() => {
    const minutesArr = [];
    for (let i = 0; i < props.stateData.duracion + 61; i++) {
      minutesArr.push(i);
    }
    const co2Data = () =>
      minutesArr.map((min) => {
        const x = min;
        const y = Math.trunc(ppmT(min));
        const yHVAC = Math.trunc(ppmTHVAC(min));
        return {
          Minuto: x,
          'Nivel de CO2 en interior': y,
          'Nivel de CO2 en exterior': props.stateData.co2Exterior,
          'Nivel de CO2 en interior con filtrado/UV': yHVAC,
          'Límite de CO2 según normativa': LIMITE_PPM,
          'Límite de CO2 según Havard': props.stateData.co2Exterior + 300,
        };
      });
    setData(co2Data);
  }, [props.stateData.duracion, ppmT, ppmTHVAC, props.stateData.co2Exterior]);

  useEffect(() => {
    const yDuracion = Math.trunc(ppmT(props.stateData.duracion));
    const duracion = Number(props.stateData.duracion);
    setDuracion(duracion);
    setNivelDuracion(yDuracion);
  }, [props.stateData.duracion, ppmT]);

  useEffect(() => {
    const maxDataArr = data.map((obj) => {
      const valuesArr = Object.values(obj);
      return Math.max(...valuesArr);
    });
    const max = Math.max(...maxDataArr);
    setDomainMaxY(max);
  }, [data]);

  return (
    <StyledFullSectionContainer light>
      <FormattedMessage id="resultados.pagetitle" defaultMessage="Resultados">
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Obtención y presentación visual de resultados relacionados con la infección por COViD-19, descripción ilustrativa y análisis"
            />
          </Helmet>
        )}
      </FormattedMessage>

      <Imagen src={coronaVirus} alt="Fondo que ilustra el COViD-19" />

      <StyledResponsiveContainer>
        <Results>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <FormNavigation
              goBack={'/ventilacion'}
              disableNext
              style={{ flexShrink: 1 }}
            />

            <LinkImpresion to="/simplificado">
              <FormattedMessage
                id="resultados.versionimpresion"
                defaultMessage="Versión de impresión"
              />
            </LinkImpresion>
          </div>

          <SeccResults>
            <TitleCont>
              <Title>
                <FormattedMessage
                  id="resultados.title"
                  defaultMessage="RESULTADOS"
                />
              </Title>
            </TitleCont>
            <Texto>
              {props.stateData.esCalcIA ? (
                <FormattedMessage
                  id="resultados.short.ia"
                  defaultMessage="En este recinto de <span>{volumen}</span> m³ de volumen, asumiendo un incidencia acumulada de <span>{ia}</span> casos / 100.000 habitantes, durante <span>{duracion}</span> minutos, dada una ventilación de <span>{renovaciones}</span> renovaciones/hora, existe un:<ul><li>RIESGO de infección por transmisión de aerosoles: <spanUpper>{riesgo1}</spanUpper>.</li><li>RIESGO de infección relativo asociado: <spanUpper>{riesgo2}</spanUpper>.</li><li>Promedio estadístico de casos de infección: <span>{casosResultantes}</span> casos (Tasa de ataque de <span>{tasaAtaque}</span> %).</li><li>Nivel de CO2 promedio: <span>{co2ProporcionMediaMezcla}</span> ppm.</li><li>Valor de CO2 máximo: <span>{co2IntDuracion}</span> ppm.</li></ul>"
                  values={{
                    span: (words) => <span>{words}</span>,
                    spanUpper: (words) => (
                      <span style={{ textTransform: 'uppercase' }}>
                        {words}
                      </span>
                    ),
                    ul: (words) => <ul>{words}</ul>,
                    li: (words) => <li>{words}</li>,
                    volumen: props.stateData.volumen,
                    ia: props.stateData.casosIA100k,
                    duracion: props.stateData.duracion,
                    renovaciones: props.stateData.renovacionesAireDefinitivo,
                    riesgo1: (
                      <NivelRiesgo
                        resultado={props.stateData.riesgoInfeccion}
                        limite1={props.stateData.limiteHInf}
                        limite2={props.stateData.limiteHSup}
                      />
                    ),
                    riesgo2: (
                      <NivelRiesgo
                        resultado={props.stateData.riesgoInfeccionRelativo}
                        limite1={props.stateData.limiteHrInf}
                        limite2={props.stateData.limiteHrSup}
                      />
                    ),
                    casosResultantes: truncToPrecision(
                      props.stateData.casosResultantes,
                      2
                    ),
                    tasaAtaque: truncToPrecision(
                      props.stateData.tasaAtaque * 100,
                      2
                    ),
                    co2ProporcionMediaMezcla: truncToPrecision(
                      props.stateData.co2ProporcionMediaMezcla,
                      0
                    ),
                    co2IntDuracion: truncToPrecision(
                      props.stateData.co2IntDuracion,
                      0
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="resultados.short"
                  defaultMessage="En este recinto de <span>{volumen}</span> m³ de volumen, en el que permanecen <span>{personas}</span> ocupantes durante <span>{duracion}</span> minutos, dada una ventilación de <span>{renovaciones}</span> renovaciones/hora, existe un:<ul><li>RIESGO de infección por transmisión de aerosoles: <spanUpper>{riesgo1}</spanUpper>.</li><li>RIESGO de infección relativo asociado: <spanUpper>{riesgo2}</spanUpper>.</li><li>Promedio estadístico de casos de infección: <span>{casosResultantes}</span> casos (Tasa de ataque de <span>{tasaAtaque}</span> %).</li><li>Nivel de CO2 promedio: <span>{co2ProporcionMediaMezcla}</span> ppm.</li><li>Valor de CO2 máximo: <span>{co2IntDuracion}</span> ppm.</li></ul>"
                  values={{
                    span: (words) => <span>{words}</span>,
                    spanUpper: (words) => (
                      <span style={{ textTransform: 'uppercase' }}>
                        {words}
                      </span>
                    ),
                    ul: (words) => <ul>{words}</ul>,
                    li: (words) => <li>{words}</li>,
                    volumen: props.stateData.volumen,
                    personas: props.stateData.personas,
                    duracion: props.stateData.duracion,
                    renovaciones: props.stateData.renovacionesAireDefinitivo,
                    riesgo1: (
                      <NivelRiesgo
                        resultado={props.stateData.riesgoInfeccion}
                        limite1={props.stateData.limiteHInf}
                        limite2={props.stateData.limiteHSup}
                      />
                    ),
                    riesgo2: (
                      <NivelRiesgo
                        resultado={props.stateData.riesgoInfeccionRelativo}
                        limite1={props.stateData.limiteHrInf}
                        limite2={props.stateData.limiteHrSup}
                      />
                    ),
                    casosResultantes: truncToPrecision(
                      props.stateData.casosResultantes,
                      2
                    ),
                    tasaAtaque: truncToPrecision(
                      props.stateData.tasaAtaque * 100,
                      2
                    ),
                    co2ProporcionMediaMezcla: truncToPrecision(
                      props.stateData.co2ProporcionMediaMezcla,
                      0
                    ),
                    co2IntDuracion: truncToPrecision(
                      props.stateData.co2IntDuracion,
                      0
                    ),
                  }}
                />
              )}
            </Texto>

            <SectionBar>
              <h4>
                <FormattedMessage
                  id="resultados.subtitle1"
                  defaultMessage="PARÁMETROS DE OCUPACIÓN"
                />
              </h4>
            </SectionBar>
            <CardList
              etiqueta={
                <FormattedMessage
                  id="resultados.cardlist1.etiqueta"
                  defaultMessage="Ocupación"
                />
              }
              listTitle={
                <FormattedMessage
                  id="resultados.cardlist1.listtitle"
                  defaultMessage="Valores Introducidos"
                />
              }
              resultadosTitle={
                <FormattedMessage
                  id="resultados.cardlist1.resultadostitle"
                  defaultMessage="Parámetros de ocupación"
                />
              }
              list={[
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem1.label"
                    defaultMessage="Ocupantes"
                  />, // label
                  props.stateData.personas, // dimension
                  <FormattedMessage
                    id="gen.unidad.short.ocup"
                    defaultMessage="ocup."
                  />, // units
                  0, // precision
                ],
                props.stateData.esCalcIA
                  ? [
                      <FormattedMessage
                        id="input37.label"
                        defaultMessage="Incidencia acumulada"
                      />,
                      props.stateData.casosIA100k,
                      ' ',
                      0,
                    ]
                  : [
                      <FormattedMessage
                        id="resultados.cardlist1.listelem2.label"
                        defaultMessage="Ocupantes infectivos"
                      />,
                      props.stateData.infectivas,
                      <FormattedMessage
                        id="gen.unidad.short.ocup"
                        defaultMessage="ocup."
                      />,
                      0,
                    ],
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem3.label"
                    defaultMessage="Superficie del recinto"
                  />,
                  props.stateData.superficie,
                  ['m', <sup>2</sup>],
                  1,
                ],
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem4.label"
                    defaultMessage="Volumen"
                  />,
                  props.stateData.volumen,
                  ['m', <sup>3</sup>],
                  1,
                ],
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem5.label"
                    defaultMessage="Población vacunada pauta completa"
                  />,
                  props.stateData.indicePobInmune,
                  ['%'],
                  0,
                ],
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem6.label"
                    defaultMessage="Inmunidad proporcionada por las vacunas"
                  />,
                  props.stateData.indiceInmunidadPersonal,
                  ['%'],
                  0,
                ],
              ]}
              resultados={[
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem7.label"
                    defaultMessage="Densidad de ocupación"
                  />,
                  props.stateData.densidadSup,
                  <FormattedMessage
                    id="gen.unidad.metro2ocup"
                    defaultMessage="m<sup>2</sup> / ocup."
                    values={{
                      sup: (words) => <sup>{words}</sup>,
                    }}
                  />,
                  1,
                ],
                [
                  <FormattedMessage
                    id="resultados.cardlist1.listelem8.label"
                    defaultMessage="Volumen de aire por ocupante"
                  />,
                  props.stateData.densidadVol,
                  <FormattedMessage
                    id="gen.unidad.metro3ocup"
                    defaultMessage="m<sup>3</sup> / ocup."
                    values={{
                      sup: (words) => <sup>{words}</sup>,
                    }}
                  />,
                  1,
                ],
              ]}
              description={
                <article>
                  <p>
                    <FormattedMessage
                      id="resultados.cardlist1.description.p1"
                      defaultMessage="En este apartado se recoge la cantidad de espacio y de volumen de aire del que disfruta cada persona."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.cardlist1.description.p2"
                      defaultMessage="También se realiza un resumen de los principales parámetros introducidos relacionados con la ocupación."
                    />
                  </p>
                </article>
              }
            />
            <SectionBar>
              <h4>
                <FormattedMessage
                  id="resultados.subtitle2"
                  defaultMessage="VENTILACIÓN"
                />
              </h4>
            </SectionBar>
            <Card
              etiqueta={
                <FormattedMessage
                  id="resultados.card2.etiqueta"
                  defaultMessage="Caudal de ventilación por ocupante"
                />
              }
              resultado={props.stateData.renovacionesAireDefinitivoOcupante}
              unidad={
                <FormattedMessage
                  id="gen.lsocup"
                  defaultMessage="l / s · ocup"
                />
              }
              precision={1}
              limite1={12.5}
              limite2={14}
              semaforoDirecta
              description={
                <article>
                  <p>
                    <FormattedMessage
                      id="resultados.card2.description.p1"
                      defaultMessage="Cantidad de litros de aire de renovación exterior que se introduce por cada segundo en el recinto estudiado, repartido entre el número total de ocupantes. Permite evaluar si la ventilación es adecuada, escasa o mayor de lo requerido al vincularse a la cantidad de ocupantes."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.card2.description.p2"
                      defaultMessage="Según las condiciones de ocupación y volumen del recinto, esto equivale a {renovacionesAire} renovaciones de un volumen de aire equivalente al contenido por el recinto en una hora (ACH)."
                      values={{
                        renovacionesAire: props.stateData.renovacionesAire,
                      }}
                    />
                  </p>
                </article>
              }
            />
            <SectionBar>
              <h4>
                <FormattedMessage
                  id="resultados.subtitle3"
                  defaultMessage="EMISIÓN E INHALACIÓN"
                />
              </h4>
            </SectionBar>
            <Card
              etiqueta={
                <FormattedMessage
                  id="resultados.card3.etiqueta"
                  defaultMessage="Quanta inhalada por ocupante"
                />
              }
              resultado={props.stateData.quantaPersona}
              unidad={'quanta'}
              precision={3}
              description={
                <article>
                  <p>
                    <FormattedMessage
                      id="resultados.card3.description.p1"
                      defaultMessage="Inhalar un 'quanta' equivale a tomar la dosis de aerosol requerida para causar infección en el 63% de las personas susceptibles, según el modelo probabilístico de Wells-Riley."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.card3.description.p2"
                      defaultMessage="Esta dosis depende de la concentración media de bio-aerosoles con carga infectiva en el periodo de exposición y del volumen de aire inhalado, entre otros factores."
                    />
                  </p>
                </article>
              }
            />
            <CardSumaDescBelow
              etiqueta={
                <FormattedMessage
                  id="resultados.card4.etiqueta"
                  defaultMessage="Tasa de pérdida de primer orden"
                />
              }
              resultado={props.stateData.tasaPerdidaPrimerOrden}
              unidad={['h', <sup>-1</sup>]}
              comment={
                <FormattedMessage
                  id="resultados.card4.comment"
                  defaultMessage="Los aerosoles permanecen un promedio de {tiempoAerosolesInfectivosAire} minutos con su carga vírica viable"
                  values={{
                    tiempoAerosolesInfectivosAire:
                      datos.tiempoAerosolesInfectivosAire,
                  }}
                />
              }
              precision={2}
              description={
                <article>
                  <p>
                    <FormattedMessage
                      id="resultados.card4.description.p1"
                      defaultMessage="Velocidad en la que se reduce la carga vírica infectiva del aire, transportada mediante bio-aerosoles."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.card4.description.p2"
                      defaultMessage="Conforme mayor sea el valor de esta tasa, menor será la fracción de dosis vírica inhalada por cada uno de los ocupantes (la cual determina la probabilidad de infección)."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.card4.description.p3"
                      defaultMessage="Este parámetro ha de evaluarse según la densidad de ocupación y el volumen de aire por ocupante."
                    />
                  </p>
                </article>
              }
              sumandos={[
                [
                  <FormattedMessage
                    id="resultados.card4.sumandos1.label"
                    defaultMessage="Ventilación"
                  />,
                  <FormattedMessage
                    id="resultados.card4.sumandos1.description"
                    defaultMessage="Impacto de la entrada de aire de renovación exterior en el proceso de dilución de bio-aerosoles con posible carga infectiva."
                  />,
                  props.stateData.renovacionesAireDefinitivo,
                ],
                [
                  <FormattedMessage
                    id="resultados.card4.sumandos2.label"
                    defaultMessage="Tasa de descomposición de infectividad"
                  />,
                  <FormattedMessage
                    id="resultados.card4.sumandos2.description"
                    defaultMessage="Impacto de la desintegración de los viriones en los bio-aerosoles al exponerse al ambiente exterior."
                  />,
                  props.stateData.tasaDescomposicion,
                ],
                [
                  <FormattedMessage
                    id="resultados.card4.sumandos3.label"
                    defaultMessage="Tasa de deposición en superficies"
                  />,
                  <FormattedMessage
                    id="resultados.card4.sumandos3.description"
                    defaultMessage="Impacto de la caída y deposición de los bio-aerosoles con carga vírica en las superficies y mobiliario del recinto."
                  />,
                  props.stateData.tasaDeposicion,
                ],
                [
                  <FormattedMessage
                    id="resultados.card4.sumandos4.label"
                    defaultMessage="Medidas adicionales de control"
                  />,
                  <FormattedMessage
                    id="resultados.card4.sumandos4.description"
                    defaultMessage="Impacto de las estrategias combinadas de filtrado de aire y desinfección por radiación ultravioleta."
                  />,
                  props.stateData.caudalSaneado,
                ],
              ]}
            />
            <SectionBar>
              <h4>
                <FormattedMessage
                  id="resultados.subtitle4"
                  defaultMessage="RESULTADOS CONDICIONALES DE INFECCIÓN"
                />
              </h4>
            </SectionBar>
            <Wrapper
              style={{
                gap: '0.5rem',
                background: 'none',
                boxShadow: 'none',
                padding: '0',
              }}
            >
              <CardCuadrado
                etiqueta={
                  <FormattedMessage
                    id="resultados.card5.etiqueta"
                    defaultMessage="Probabilidad de infección por individuo (Tasa de ataque)"
                  />
                }
                resultado={props.stateData.probInfeccionPersona * 100}
                unidad={'%'}
                precision={4}
                description={
                  <article>
                    <p>
                      <FormattedMessage
                        id="resultados.card5.description"
                        defaultMessage="Se obtiene al aplicar el modelo de infección de Wells-Riley a la cantidad de dosis infecciosas inhaladas (quanta) por un individuo que ha permanecido en el recinto durante todo el evento."
                      />
                    </p>
                  </article>
                }
              />
              <CardCuadrado
                etiqueta={
                  <FormattedMessage
                    id="resultados.card6.etiqueta"
                    defaultMessage="Número de personas susceptibles de enfermar"
                  />
                }
                resultado={props.stateData.personasSusceptiblesEnfermar}
                unidad={
                  <FormattedMessage
                    id="gen.unidad.ocupantes"
                    defaultMessage="Ocupantes"
                  />
                }
                precision={4}
                description={
                  <article>
                    <p>
                      <FormattedMessage
                        id="resultados.card6.description"
                        defaultMessage="Total de personas que pueden ser infectadas en el recinto, una vez tenido en cuenta el efecto de las vacunas y previamente descontados el número de ocupantes infectivos."
                      />
                    </p>
                  </article>
                }
              />

              <CardCuadrado
                etiqueta={
                  <FormattedMessage
                    id="resultados.card7.etiqueta"
                    defaultMessage="Casos de COViD-19 resultantes"
                  />
                }
                resultado={props.stateData.casosResultantes}
                unidad={
                  <FormattedMessage
                    id="gen.unidad.casos"
                    defaultMessage="casos"
                  />
                }
                precision={4}
                description={
                  <article>
                    <p>
                      <FormattedMessage
                        id="resultados.card7.description"
                        defaultMessage="Este parámetro se obtiene al multiplicar la probabilidad de infección de un individuo por el número de personas susceptibles presentes en el recinto."
                      />
                    </p>
                  </article>
                }
              />
            </Wrapper>
            <SectionBar>
              <h4>
                <FormattedMessage
                  id="resultados.subtitle8"
                  defaultMessage="RIESGO DE INFECCIÓN"
                />
              </h4>
            </SectionBar>
            <Card
              etiqueta={
                <FormattedMessage
                  id="resultados.card8.etiqueta"
                  defaultMessage="Riesgo de infección"
                />
              }
              resultado={props.stateData.riesgoInfeccion}
              unidad={
                <FormattedMessage
                  id="gen.unidad.h2ocupm3"
                  defaultMessage="h<sup>2</sup> · ocup / m<sup>3</sup>"
                  values={{
                    sup: (words) => <sup>{words}</sup>,
                  }}
                />
              }
              precision={4}
              limite1={props.stateData.limiteHInf}
              limite2={props.stateData.limiteHSup}
              description={
                <article>
                  <p>
                    <FormattedMessage
                      id="resultados.card8.description.p1"
                      defaultMessage="Indicador de riesgo en términos de tamaño del brote epidémico. Se define a través del número probable de núcleos de partículas infecciosas inhaladas por todas las personas susceptibles a partir de una única persona infectada, vinculándose con un periodo dado de exposición y para un recinto de un volumen determinado."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.card8.description.p2"
                      defaultMessage="Este parámetro, al basarse en la cantidad inhalada de aerosoles potencialmente infectivos, está determinado por:"
                    />
                  </p>
                  <ul>
                    <li>
                      <FormattedMessage
                        id="resultados.card8.description.list1"
                        defaultMessage="El número total de personas susceptibles a la enfermedad (relación directa; a mayor número de personas susceptibles, mayor será el riesgo de brote epidémico)."
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="resultados.card8.description.list2"
                        defaultMessage="Tiempo de exposición (relación directa y cuadrática; el tiempo afectará en gran medida al riesgo de brote epidémico)."
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="resultados.card8.description.list3"
                        defaultMessage="Volumen del recinto (relación inversa; a mayor cantidad de aire en el recinto, menor será el riesgo de brote epidémico)."
                      />
                    </li>
                  </ul>
                </article>
              }
              semaforoInversa
            />
            <Card
              etiqueta={
                <FormattedMessage
                  id="resultados.card9.etiqueta"
                  defaultMessage="Riesgo de infección relativo"
                />
              }
              resultado={props.stateData.riesgoInfeccionRelativo}
              unidad={['h', <sup>2&nbsp;</sup>, '/ m', <sup>3</sup>]}
              limite1={props.stateData.limiteHrInf}
              limite2={props.stateData.limiteHrSup}
              precision={4}
              description={
                <article>
                  <p>
                    <FormattedMessage
                      id="resultados.card9.description.p1"
                      defaultMessage="Indicador de riesgo en términos de tamaño del brote epidémico, derivado del parámetro de riesgo de infección."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="resultados.card9.description.p2"
                      defaultMessage="Se define a través del número probable de núcleos de partículas infecciosas inhaladas por un único ocupante susceptible, siendo emitidas por una única persona infectada, todo ello vinculado a un periodo dado de exposición y para un recinto de un volumen determinado."
                    />
                  </p>
                </article>
              }
              semaforoInversa
            />
            <Grafico>
              <TituloGrafica>
                <h4>
                  <FormattedMessage
                    id="resultados.subtitle5"
                    defaultMessage="ANÁLISIS GRÁFICO DE LA TASA DE ATAQUE"
                  />
                </h4>
              </TituloGrafica>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  margin: '1rem 0 0 0',
                }}
              >
                <BrotesGraph />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  margin: '1rem 0',
                }}
              >
                {lang.locale === 'es' ? (
                  <img
                    src={yasisTA}
                    alt="Etiqueta para eje y"
                    style={{ position: 'absolute', left: '4px' }}
                  />
                ) : (
                  <img
                    src={yasisTAEN}
                    alt="Label for y asis"
                    style={{ position: 'absolute', left: '4px' }}
                  />
                )}

                <TasaAtaqueGraph
                  limiteHrInf={props.stateData.limiteHrInf}
                  limiteHrSup={props.stateData.limiteHrSup}
                  riesgoInfeccionRelativo={
                    props.stateData.riesgoInfeccionRelativo
                  }
                  tasaAtaque={props.stateData.tasaAtaque}
                  dataGraphTA={props.stateData.dataGraphTA}
                />
              </div>
              {lang.locale === 'es' ? (
                <img
                  className="xAsisLabel"
                  src={xasisTA}
                  alt="Etiqueta para eje x"
                  style={{ position: 'absolute', top: '710px' }}
                />
              ) : (
                <img
                  className="xAsisLabel"
                  src={xasisTAEN}
                  alt="Label for x asis"
                  style={{ position: 'absolute', top: '710px' }}
                />
              )}

              {lang.locale === 'es' ? (
                <img
                  src={legendMobileBrote}
                  alt="Leyenda de gráfico de brotes de COViD-19 para móviles"
                  className="showMobile"
                />
              ) : (
                <img
                  src={legendMobileBroteEN}
                  alt="Leyenda de gráfico de brotes de COViD-19 para móviles"
                  className="showMobile"
                />
              )}

              {lang.locale === 'es' ? (
                <img
                  src={legendMobileTA}
                  alt="Leyenda de gráfico de tasa de ataque para móviles"
                  className="showMobile"
                />
              ) : (
                <img
                  src={legendMobileTAEN}
                  alt="Leyenda de gráfico de tasa de ataque para móviles"
                  className="showMobile"
                />
              )}

              {lang.locale === 'es' ? (
                <img
                  src={legendDesktopBrote}
                  alt="Leyenda de gráfico de brotes de COViD-19 para ordenador personal"
                  className="showDesktop"
                />
              ) : (
                <img
                  src={legendDesktopBroteEN}
                  alt="Leyenda de gráfico de brotes de COViD-19 para ordenador personal"
                  className="showDesktop"
                />
              )}

              {lang.locale === 'es' ? (
                <img
                  src={legendDesktopTA}
                  alt="Leyenda de gráfico de tasa de ataque para ordenador personal"
                  className="showDesktop"
                />
              ) : (
                <img
                  src={legendDesktopTAEN}
                  alt="Leyenda de gráfico de tasa de ataque para ordenador personal"
                  className="showDesktop"
                />
              )}

              <Description>
                <FormattedMessage
                  id="resultados.subtitle5.description.p1"
                  defaultMessage="* En esta gráfica en dos partes se muestra:"
                />
                <ul>
                  <span className="part__title">
                    <FormattedMessage
                      id="resultados.subtitle5.description.prelist1"
                      defaultMessage="Parte superior:"
                    />
                  </span>

                  <FormattedMessage
                    id="resultados.subtitle5.description.list1"
                    defaultMessage="El rango de riesgo de infección relativo (Hr) para determinados eventos en espacios interiores. Se marcan los límites de dicho rango según las medidas preventivas tomadas:"
                  />

                  <li>
                    <FormattedMessage
                      id="resultados.subtitle5.description.list2"
                      defaultMessage="Entorno pandémico (bajo riesgo): Límite inferior de riesgo para aquellos espacios en los que se toman medidas de mitigación como ventilación monitorizada y uso de mascarillas ajustadas."
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="resultados.subtitle5.description.list3"
                      defaultMessage="Entorno pre-pandémico: Límite superior de riesgo para aquellos espacios en los que no se toman medidas de mitigación. De haber ventilación en estos espacios, se considera la existente (caso real) o la normativa (caso teórico)."
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="resultados.subtitle5.description.list4"
                      defaultMessage="Entorno pre-pandémico (50% ventilación): Límite superior de riesgo para aquellos espacios en los que no se toman medidas de mitigación y la ventilación existente es la mitad de la esperada por normativa (solo para casos teóricos)."
                    />
                  </li>
                </ul>
                <span className="part__title">
                  <FormattedMessage
                    id="resultados.subtitle5.description.prelist2"
                    defaultMessage="Parte inferior: "
                  />
                </span>
                <FormattedMessage
                  id="resultados.subtitle5.description.list5"
                  defaultMessage="Distribución del riesgo de infección relativo (Hr) en función de la Tasa de Ataque. Se incluyen los brotes COViD-19 documentados y la curva prevista a partir de ellos, tanto para la variante original como para la variante Delta. Es posible comparar el valor de Hr calculado para el evento estudiado con los diferentes rangos de Hr obtenidos en la gráfica superior en eventos similares, a fin de establecer si existe margen de mejora."
                />
              </Description>
            </Grafico>
            <SectionBar>
              <h4>
                <FormattedMessage
                  id="resultados.subtitle6"
                  defaultMessage="CO<sub>2</sub> COMO RIESGO INDIRECTO"
                  values={{
                    sub: (words) => <sub>{words}</sub>,
                  }}
                />
              </h4>
            </SectionBar>
            <Wrapper
              style={{
                gap: '0.5rem',
                background: 'none',
                boxShadow: 'none',
                padding: '0',
              }}
            >
              <Introduction>
                <FormattedMessage
                  id="resultados.subtitle6.content"
                  defaultMessage="<p>El valor de CO<sub>2</sub> existente en un recinto ocupado puede utilizarse como indicador indirecto de la calidad ambiental.</p><p>Este nivel está directamente determinado por la respiración de las personas (si no hay procesos de combustión en su interior) y por la tasa de ventilación (que permite reducir la concentración de CO<sub>2</sub>).</p><p>Así, a mayor nivel de CO<sub>2</sub> en un interior, menor será la calidad ambiental.</p><p>Asimismo, se puede relacionar indirectamente el valor medido de CO<sub>2</sub> con el nivel de riesgo de transmisión aérea de enfermedades por aerosoles, como la COViD-19, dado que la emisión de bio-aerosoles potencialmente infectivos se realiza al respirar, hablar y gritar, como sucede con la exhalación de CO<sub>2</sub>.</p><p>Tanto la emisión de bio-aerosoles como la de CO<sub>2</sub> derivadas del proceso respiratorio dependen de la actividad que realizan los ocupantes, aumentando ambos parámetros al incrementarse el nivel de actividad (tasa metabólica).</p>"
                  values={{
                    sub: (words) => <sub>{words}</sub>,
                    p: (words) => <p>{words}</p>,
                  }}
                />
              </Introduction>
              <CardCuadrado
                etiqueta={
                  <FormattedMessage
                    id="resultados.card10.etiqueta"
                    defaultMessage="Proporción media de mezcla de CO<sub>2</sub>"
                    values={{
                      sub: (words) => <sub>{words}</sub>,
                    }}
                  />
                }
                resultado={props.stateData.co2ProporcionMediaMezcla}
                unidad={['ppm CO', <sub>2</sub>]}
                precision={0}
                limite1={limites.harvardCo2}
                limite2={limites.ida2Co2}
                semaforoInversa
                description={
                  <article>
                    <p>
                      <FormattedMessage
                        id="resultados.card10.description"
                        defaultMessage="Nivel de CO<sub>2</sub> absoluto promedio al que han estado expuestos los ocupantes en el recinto durante la duración del evento, considerando que el aire interior tiene un comportamiento homogéneo."
                        values={{
                          sub: (words) => <sub>{words}</sub>,
                        }}
                      />
                    </p>
                  </article>
                }
              />
              <CardCuadrado
                etiqueta={
                  <FormattedMessage
                    id="resultados.card11.etiqueta"
                    defaultMessage="CO<sub>2</sub> a inhalar en 1 hora para 1% de infección"
                    values={{
                      sub: (words) => <sub>{words}</sub>,
                    }}
                  />
                }
                resultado={props.stateData.co2Hora1PorCiento}
                unidad={'ppm'}
                precision={0}
                description={
                  <article>
                    <p>
                      <FormattedMessage
                        id="resultados.card11.description"
                        defaultMessage="Indicador basado en el nivel promedio de CO<sub>2</sub> que ha de darse en un recinto durante una hora para alcanzar un riesgo de contagio de un uno por ciento."
                        values={{
                          sub: (words) => <sub>{words}</sub>,
                        }}
                      />
                    </p>
                  </article>
                }
              />
              <CardCuadrado
                etiqueta={
                  <FormattedMessage
                    id="resultados.card12.etiqueta"
                    defaultMessage="Fracción de aire reinhalado"
                  />
                }
                resultado={props.stateData.aireReinhalado * 100}
                unidad={'%'}
                precision={2}
                description={
                  <FormattedMessage
                    id="resultados.card12.description"
                    defaultMessage="Porcentaje de aire inhalado por un ocupante que ya ha sido previamente respirado por otra persona en el recinto."
                  />
                }
                semaforoInversa
                limite1={truncToPrecisionZeros((100 * 300) / 38000, 2)}
                limite2={truncToPrecisionZeros((100 * 500) / 38000, 2)}
              />

              <Grafico>
                <TituloGrafica>
                  <h4>
                    <FormattedMessage
                      id="resultados.subtitle7"
                      defaultMessage="ANÁLISIS GRÁFICO DE LA EVOLUCIÓN DEL CO<sub>2</sub>"
                      values={{
                        sub: (words) => <sub>{words}</sub>,
                      }}
                    />
                  </h4>
                </TituloGrafica>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    margin: '1rem 0',
                  }}
                >
                  {lang.locale === 'es' ? (
                    <img
                      src={yasis}
                      alt="Etiqueta para eje y"
                      style={{ position: 'absolute', left: '4px' }}
                    />
                  ) : (
                    <img
                      src={yasisEN}
                      alt="Etiqueta para eje y"
                      style={{ position: 'absolute', left: '4px' }}
                    />
                  )}

                  <ReactLineChart
                    data={data}
                    domain={domainMaxY}
                    duracion={duracion}
                    nivelDuracion={nivelDuracion}
                  />
                </div>
                {lang.locale === 'es' ? (
                  <img
                    src={xasis}
                    alt="Etiqueta para eje x"
                    style={{ position: 'absolute', top: '500px' }}
                  />
                ) : (
                  <img
                    src={xasisEN}
                    alt="Etiqueta para eje x"
                    style={{ position: 'absolute', top: '500px' }}
                  />
                )}

                {lang.locale === 'es' ? (
                  <img
                    src={legendMobile}
                    alt="Leyenda de gráfico de evolución del dioxido de carbono para móviles"
                    className="showMobile"
                  />
                ) : (
                  <img
                    src={legendMobileEN}
                    alt="Leyenda de gráfico de evolución del dioxido de carbono para móviles"
                    className="showMobile"
                  />
                )}

                {lang.locale === 'es' ? (
                  <img
                    src={legendDesktop}
                    alt="Leyenda de gráfico de evolución del dioxido de carbono para ordenador personal"
                    className="showDesktop"
                  />
                ) : (
                  <img
                    src={legendDesktopEN}
                    alt="Leyenda de gráfico de evolución del dioxido de carbono para ordenador personal"
                    className="showDesktop"
                  />
                )}

                <Description>
                  <FormattedMessage
                    id="resultados.subtitle7.description"
                    defaultMessage="* Este nivel equivalente de CO2 se ha obtenido mediante la suposición ficticia de que el caudal purificado actúa como aire de renovación exterior. No se corresponde por tanto a una situación real, dado que la purificación NO MODIFICA el nivel de CO2. Así, sirve meramente como indicador de la mejora de la calidad ambiental por la purificación (sin afectar al nivel real de CO<sub>2</sub> de la sala)."
                    values={{
                      sub: (words) => <sub>{words}</sub>,
                    }}
                  />
                </Description>
              </Grafico>
            </Wrapper>
          </SeccResults>
        </Results>

        <NavigationButtons
          to="/ventilacion"
          align={'center'}
          back={'red'}
          textES={'Atrás'}
          textEN={'Back'}
        />
      </StyledResponsiveContainer>
    </StyledFullSectionContainer>
  );
};

export default Resultados;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  sub {
    font-size: 0.7rem;
  }
`;

const SeccResults = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--gdwhite);
  backdrop-filter: blur(3px);
  padding: 0.5rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
`;

const TitleCont = styled.div`
  background: var(--gdverydark);
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  backdrop-filter: blur(3px);
  width: 100%;
  margin-bottom: 2rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const Title = styled(SectionTitle)`
  color: var(--verylight);
  padding: 0;
  font-size: 1.8rem;
`;

const SectionBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 97.5%;
  min-height: 3.5rem;
  background: #e5e9eb;

  padding: 0.5rem;
  margin: 1.5rem 0 1rem 0;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  color: var(--verydark);

  h4 {
    width: 100%;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  background: var(--gdwhite);
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  color: var(--verydark);
  margin-bottom: 1rem;
`;

const TituloGrafica = styled.div`
  background: var(--gdverydark);
  padding: 0.5rem;

  margin: 0 0 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  h4 {
    text-align: center;
    font-size: 1rem;
    color: white;
    padding: 0.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

    @media (max-width: 800px) {
      max-width: 312px;
    }

    sub {
      font-size: 0.7rem;
    }
  }
`;

const Grafico = styled.div`
  width: 97.5%;
  position: relative;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 2rem 0.5rem;

  .xAsisLabel {
    @media (max-width: 784px) {
      top: 725px !important;
    }
  }

  img {
    padding-top: 1rem;
  }

  .showMobile {
    width: 100%;
    max-width: 321.95px;

    @media (min-width: 801px) {
      display: none;
    }
  }

  .showDesktop {
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 97.5%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 2rem 4rem;

  @media (max-width: ${size.md}) {
    padding: 2rem 1rem;
  }

  p {
    color: var(--verydark);
    line-height: 1.2;
    text-align: justify;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const Description = styled.h5`
  font-size: 0.7rem;
  font-weight: bold;
  color: grey;
  text-align: justify;
  padding: 1.5rem 1rem;

  .part__title {
    color: black;
  }

  ul {
    margin-bottom: 0.5rem;
    li {
      margin-left: 1rem;
    }
  }
`;

const LinkImpresion = styled(Link)`
  display: flex;
  justify-content: end;
  color: white;
  background: var(--verydark);
  margin-bottom: 0.2rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  max-width: 250px;
  text-align: center;
  border: none;
  font-size: 0.9rem;
  font-weight: bold;

  &:hover {
    background: #d4d4d4;
    color: var(--verydark);
  }
`;

const Texto = styled.p`
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: normal;
  color: black;
  padding: 1rem 1rem 1.5rem 1rem;
  text-align: justify;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 0.9rem;
  }

  sub {
    font-size: 0.6rem;
  }

  ul {
    li {
      margin-left: 1.2rem;
      padding-left: 0.5rem;
    }
  }
`;

const Imagen = styled.img`
  position: fixed;
  bottom: -60px;
  right: -60px;
  width: 600px;
  z-index: 1;

  @media (max-width: ${size.sm}) {
    width: 250px;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  z-index: 2;
`;

const StyledFullSectionContainer = styled(FullSectionContainer)`
  z-index: 0;
  overflow: hidden;
`;
