import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import { eventos, ticksX, ticksY } from '../../data/tasaAtaque';

const TasaAtaqueGraph = ({
  limiteHrInf,
  limiteHrSup,
  riesgoInfeccionRelativo,
  tasaAtaque,
  dataGraphTA,
}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={dataGraphTA} syncId="anyId">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="HR"
          scale="log"
          ticks={ticksX}
          style={{
            fontSize: '0.7rem',
          }}
        />
        <YAxis
          ticks={ticksY}
          scale="linear"
          style={{
            fontSize: '0.7rem',
          }}
        />

        <Tooltip />
        <ReferenceLine
          x={limiteHrInf}
          stroke="LimeGreen"
          strokeWidth={2}
          strokeDasharray="10 10"
        />
        <ReferenceLine
          x={limiteHrSup}
          stroke="OrangeRed"
          strokeWidth={2}
          strokeDasharray="10 10"
        />
        <Line
          type="monotone"
          dataKey="TA"
          stroke="#1565C0"
          dot={false}
          activeDot={{ r: 5 }}
        />
        <Line
          type="monotone"
          dataKey="TAD"
          stroke="#EEA344"
          dot={false}
          activeDot={{ r: 5 }}
        />
        {eventos.map((elem) => (
          <ReferenceDot x={elem.HR} y={elem.Evento} fill="DodgerBlue" r={3} />
        ))}
        <ReferenceDot
          x={riesgoInfeccionRelativo}
          y={tasaAtaque * 100}
          fill="#cc0000"
          r={5}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default TasaAtaqueGraph;
