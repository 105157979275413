import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import {
  FullSectionContainer,
  ResponsiveContainer,
  SectionTitle,
  SectionSubtitle,
  InnerContainer,
  Button,
  AclaracionIndividual,
  Column,
  Row,
  ceilToPrecisionString,
} from '../../globalStyles';
import inputObj from '../../data/inputs';
import InputRange from '../UI/InputRange';
import InputSelect from '../UI/InputSelect';
import InputDisabled from '../UI/InputDisabled.js';
import NavigationButtons from '../UI/NavigationButtons';
import ProgressBar from '../UI/ProgressBar.js';
import styled from 'styled-components';
import {
  VENTILACION_POR_OCUPANTE,
  ACH_HABITUALES,
} from '../../data/constantes.js';
import achIllustration from '../../images/achIllustration.png';
import mascarilla from '../../images/mascarilla.svg';
import ventilacion from '../../images/ventilacion.svg';

import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';
import FormNavigation from './FormNavigation';

const Ventilacion = (props) => {
  const lang = useContext(langContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showValoresOrientativos, setShowValoresOrientativos] = useState(false);

  const handleShowValoresOrientativos = () => {
    setShowValoresOrientativos((state) => !state);
  };

  const [selectNormaUso, setSelectNormaUso] = useState(0);

  const handleChangeNormaUso = (e) => {
    setSelectNormaUso(e.target.value);
  };

  const achSegunNorma = (valorNorma) => {
    if (valorNorma)
      return (
        Math.trunc(
          ((3.6 * props.stateData.personas * valorNorma) /
            props.stateData.volumen) *
            10
        ) / 10
      );
    else
      return (
        Math.trunc(
          ((30 * props.stateData.personas) / props.stateData.volumen) * 10
        ) / 10
      );
  };

  const renovacionesAireDefinitivoOcupanteNorma = (valorNorma) => {
    if (valorNorma)
      return (
        Math.trunc(
          ((achSegunNorma(valorNorma) * props.stateData.volumen) /
            3.6 /
            props.stateData.personas) *
            10
        ) / 10
      );
    else
      return (
        Math.trunc(
          ((props.stateData.renovacionesAireDefinitivo *
            props.stateData.volumen) /
            3.6 /
            props.stateData.personas) *
            10
        ) / 10
      );
  };

  const [showQueEsACH, setShowQueEsACH] = useState(false);

  const handleShowQueEsACH = () => {
    setShowQueEsACH((state) => !state);
  };

  const [showValoresMedibles, setShowValoresMedibles] = useState(false);

  const handleShowValoresMedibles = () => {
    setShowValoresMedibles((state) => !state);
  };

  const [showAyudaACH, setShowAyudaACH] = useState(false);

  const handleShowAyudaACH = () => {
    setShowAyudaACH((state) => !state);
  };
  return (
    <FullSectionContainer white>
      <FormattedMessage id="ventilacion.pagetitle" defaultMessage="Ventilación">
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Formulario sobre ventilación del recinto y otros parámetros relacionados que influyen en el riesgo de infección por COViD-19"
            />
          </Helmet>
        )}
      </FormattedMessage>

      <ResponsiveContainer>
        <section>
          <FormNavigation goBack={'/actividad'} goNext={'/resultados'} />
          <SectionTitle primary>
            <FormattedMessage
              id="ventilacion.title"
              defaultMessage="Nivel de ventilación y medidas de protección individuales"
            />
          </SectionTitle>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ventilacion.subtitle1"
              defaultMessage="Ventilación: Aporte de aire exterior"
            />
          </SectionSubtitle>
          <h5 style={{ marginBottom: '2rem' }}>
            <FormattedMessage
              id="ventilacion.subtitle1.content1"
              defaultMessage="En caso de desconocerse el nivel de ventilación (ACH), se puede estimar mediante el nivel de CO<sub>2</sub> medido en el recinto."
              values={{
                sub: (words) => <sub>{words}</sub>,
              }}
            />
          </h5>
          <Row style={{ marginBottom: '2rem' }}>
            <Column stackMobile width={50}>
              <>
                <InnerContainer>
                  {inputObj
                    .filter(
                      (input) =>
                        input.section === 'Ventilacion' && input.tab.includes(0)
                    )
                    .map((input) =>
                      input.type === 'range' ? (
                        <InputRange
                          key={input.id}
                          uid={input.id}
                          updateDatos={props.updateDatos}
                          name={input.name}
                          label={input.label}
                          xstep={input.xstep}
                          precision={input.precision}
                          xmin={input.xmin}
                          xmax={input.xmax}
                          byDefault={input.byDefault}
                          description={input.description}
                          stateData={props.stateData}
                        />
                      ) : (
                        <InputSelect
                          key={input.id}
                          uid={input.id}
                          updateDatos={props.updateDatos}
                          name={input.name}
                          label={input.label}
                          optionsES={input.optionsES}
                          optionsEN={input.optionsEN}
                          values={input.values}
                          byDefault={input.byDefault}
                          description={input.description}
                          stateData={props.stateData}
                        />
                      )
                    )}
                  <TablasCont style={{ maxWidth: '320px' }}>
                    <Tabla>
                      <thead>
                        <tr>
                          <th scope="col">
                            <FormattedMessage
                              id="ventilacion.subtitle1.table.1.1"
                              defaultMessage="Nivel ventilación"
                            />
                          </th>
                          <th scope="col">
                            <FormattedMessage
                              id="ventilacion.subtitle1.table.1.2"
                              defaultMessage="Valor de referencia rápida"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <FormattedMessage
                              id="ventilacion.subtitle1.table.2.1"
                              defaultMessage="Adecuado"
                            />
                          </th>
                          <td>
                            {ceilToPrecisionString(
                              props.stateData.ventilacionAdecuada,
                              1
                            )}{' '}
                            ACH
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <FormattedMessage
                              id="ventilacion.subtitle1.table.3.1"
                              defaultMessage="Medio"
                            />
                          </th>
                          <td>
                            {ceilToPrecisionString(
                              props.stateData.ventilacionMedia,
                              1
                            )}{' '}
                            ACH
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <FormattedMessage
                              id="ventilacion.subtitle1.table.4.1"
                              defaultMessage="Inadecuado"
                            />
                          </th>
                          <td>
                            {ceilToPrecisionString(
                              props.stateData.ventilacionInadecuada,
                              1
                            )}{' '}
                            ACH
                          </td>
                        </tr>
                      </tbody>
                    </Tabla>
                  </TablasCont>

                  <h5 style={{ maxWidth: '320px', marginBottom: '1rem' }}>
                    <FormattedMessage
                      id="ventilacion.subtitle1.content2"
                      defaultMessage="Con el caudal de ventilación seleccionado, es posible obtener un nivel de CO<sub>2</sub> en el recinto (medido en partes por millón, ppm) de:"
                      values={{
                        sub: (words) => <sub>{words}</sub>,
                      }}
                    />
                  </h5>
                  <InputDisabled
                    label={[
                      'Nivel promedio de CO',
                      <sub>2</sub>,
                      ' durante el evento (ppm)',
                    ]}
                    labelEN={[
                      'Mean CO',
                      <sub>2</sub>,
                      ' during the event (ppm)',
                    ]}
                    stateData={props.stateData}
                    dimension={props.stateData.promedioPPM}
                    precision={0}
                    description=""
                  />
                  <InputDisabled
                    label={[
                      `Nivel máximo de CO`,
                      <sub>2&nbsp;</sub>,
                      `en el minuto ${props.stateData.duracion} (ppm)`,
                    ]}
                    labelEN={[
                      `Maximum CO`,
                      <sub>2&nbsp;</sub>,
                      ` concentration at minute ${props.stateData.duracion} (ppm)`,
                    ]}
                    stateData={props.stateData}
                    dimension={props.stateData.co2IntDuracion}
                    precision={0}
                    description=""
                  />
                  <AclaracionesBtn transparent onClick={handleShowQueEsACH}>
                    <FormattedMessage
                      id="ventilacion.aclaracion1"
                      defaultMessage="¿Qué significa 'ACH'?"
                    />
                  </AclaracionesBtn>
                  <Aclaraciones>
                    {showQueEsACH ? (
                      <div>
                        <img
                          src={achIllustration}
                          alt="Explicacion visual de que es un ACH"
                          width="100%"
                          style={{ paddingBottom: '1rem' }}
                        />
                        <h5>
                          <FormattedMessage
                            id="ventilacion.aclaracion1.content"
                            defaultMessage="<em>Air Changes per Hour</em>. Un valor de un ACH hace referencia a una renovación de aire equivalente al volumen del recinto en una hora."
                            values={{
                              em: (words) => <em>{words}</em>,
                            }}
                          />
                        </h5>
                      </div>
                    ) : null}
                  </Aclaraciones>
                  <AclaracionesBtn transparent onClick={handleShowAyudaACH}>
                    <FormattedMessage
                      id="ventilacion.aclaracion2"
                      defaultMessage="¿Qué valor introducir en ACH?"
                    />
                  </AclaracionesBtn>
                  <Aclaraciones>
                    {showAyudaACH ? (
                      <div>
                        <h5>
                          <FormattedMessage
                            id="ventilacion.aclaracion2.content.p1"
                            defaultMessage="Desplace la barra de 'Caudal de aire exterior (ACH)'' y verá cómo cambian las ppm de CO<sub>2</sub>."
                            values={{
                              sub: (words) => <sub>{words}</sub>,
                            }}
                          />
                        </h5>
                        <h5 style={{ marginBottom: '1.2rem' }}>
                          <FormattedMessage
                            id="ventilacion.aclaracion2.content.p2"
                            defaultMessage="Ajuste las ACH al valor recomendado en 'Valores normativos' o 'Valores usuales medidos' que más se ajuste en cada caso."
                          />
                        </h5>

                        <h5
                          style={{ fontWeight: 'bold', marginBottom: '0.3rem' }}
                        >
                          <FormattedMessage
                            id="ventilacion.aclaracion2.content.subtitle1"
                            defaultMessage="Cómo calcular las ACH si dispone de un medidor de CO<sub>2</sub>:"
                            values={{
                              sub: (words) => <sub>{words}</sub>,
                            }}
                          />
                        </h5>
                        <h5>
                          <FormattedMessage
                            id="ventilacion.aclaracion2.content.subtitle1.p1"
                            defaultMessage="Es posible ajustar el 'Caudal de aire exterior (ACH)' hasta que el valor medido de CO<sub>2</sub> ya estabilizado en dicho evento coincida con el 'Nivel máximo de CO<sub>2</sub>...' que le indica la herramienta."
                            values={{
                              sub: (words) => <sub>{words}</sub>,
                            }}
                          />
                        </h5>
                        <h5>
                          <FormattedMessage
                            id="ventilacion.aclaracion2.content.subtitle1.p2"
                            defaultMessage="Para estimar la 'tasa de ventilación (en ACH) de cualquier recinto mediante el uso de un medidor de CO<sub>2</sub> con registro de datos' de forma más precisa puede usar <a>esta Hoja de cálculo</a>."
                            values={{
                              a: (words) => (
                                <a
                                  href="https://personal.us.es/mcampano/publicaciones/herramientas-de-calculo/2021_calculo-ventilacion-por-caida-de-co2-v1-3/"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {words}
                                </a>
                              ),
                              sub: (words) => <sub>{words}</sub>,
                            }}
                          />
                        </h5>
                      </div>
                    ) : null}
                  </Aclaraciones>
                  <AclaracionesBtn
                    transparent
                    onClick={handleShowValoresOrientativos}
                  >
                    <FormattedMessage
                      id="ventilacion.aclaracion3"
                      defaultMessage="Valores normativos"
                    />
                  </AclaracionesBtn>
                  <Aclaraciones>
                    {showValoresOrientativos ? (
                      <div>
                        <h5>
                          <FormattedMessage
                            id="ventilacion.aclaracion3.content"
                            defaultMessage="Puede comprobar los valores orientativos deseables, en función de la norma y uso de la edificación. Estos valores normativos están adaptados a la ocupación y el volumen del recinto."
                          />
                        </h5>
                        <select
                          onChange={handleChangeNormaUso}
                          value={selectNormaUso}
                        >
                          {lang.locale === 'es' ? (
                            <>
                              <option disabled value="0">
                                Seleccione norma y uso
                              </option>
                              <option value="1">
                                CTE - Residencial vivienda
                              </option>
                              <option value="2">
                                RITE - IDA 1 (calidad óptima)
                              </option>
                              <option value="3">
                                RITE - IDA 2 (buena calidad)
                              </option>
                              <option value="4">
                                RITE - IDA 3 (calidad media)
                              </option>
                              <option value="5">
                                RD 486/97 - Lugares de trabajo
                              </option>
                            </>
                          ) : (
                            <>
                              <option disabled value="0">
                                Define standard and use
                              </option>
                              <option value="1">
                                CTE - Residential building
                              </option>
                              <option value="2">
                                RITE - IDA 1 (indoor air quality 1, optimal)
                              </option>
                              <option value="3">
                                RITE - IDA 2 (good quality)
                              </option>
                              <option value="4">
                                RITE - IDA 3 (intermediate quality)
                              </option>
                              <option value="5">RD 486/97 - Workplaces</option>
                            </>
                          )}
                        </select>

                        <p>
                          {selectNormaUso === '1' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option1.p1"
                              defaultMessage="Determinado según número y tipo de recinto (Suele ser mayor en edificios previos al CTE)"
                            />
                          )}
                          {selectNormaUso === '2' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option2.p1"
                              defaultMessage="Hospitales, clínicas, laboratorios y guarderías"
                            />
                          )}
                          {selectNormaUso === '3' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option3.p1"
                              defaultMessage="Oficinas, residencias (zonas comunes de hoteles y similares, residencias de ancianos/estudiantes), salas de lectura, museos, salas de tribunales aulas de enseñanza/asimilables y piscinas"
                            />
                          )}
                          {selectNormaUso === '4' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option4.p1"
                              defaultMessage="Edificios comerciales, cines, teatros, salones de actos, habitaciones de hoteles y similares, restaurantes/cafeterías/bares, salas de fiestas y gimnasios/locales para deporte (salvo piscinas)"
                            />
                          )}
                          {selectNormaUso === '5' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option5.p1"
                              defaultMessage="Aplicable a todo lugar de trabajo, incluso zonas comunes. Mínimo 30 m³/h de aire limpio por trabajador (trabajos sedentarios en ambientes no calurosos ni contaminados por humo de tabaco)"
                            />
                          )}
                        </p>
                        <p>
                          {selectNormaUso === '1' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option1.p2"
                              defaultMessage="Valor orientativo de 0.5 a 1.5 ACH para un caudal de {renovaciones1} a {renovaciones2} l/s por persona"
                              values={{
                                renovaciones1:
                                  renovacionesAireDefinitivoOcupanteNorma(0.5),
                                renovaciones2:
                                  renovacionesAireDefinitivoOcupanteNorma(1.5),
                              }}
                            />
                          )}
                          {selectNormaUso === '2' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option2.p2"
                              defaultMessage="Valor orientativo de {achNorma} ACH para un caudal de {renovaciones} l/s por persona"
                              values={{
                                achNorma: achSegunNorma(
                                  VENTILACION_POR_OCUPANTE.IDA1
                                ),
                                renovaciones:
                                  renovacionesAireDefinitivoOcupanteNorma(
                                    VENTILACION_POR_OCUPANTE.IDA1
                                  ),
                              }}
                            />
                          )}
                          {selectNormaUso === '3' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option3.p2"
                              defaultMessage="Valor orientativo de {achNorma} ACH para un caudal de {renovaciones} l/s por persona"
                              values={{
                                achNorma: achSegunNorma(
                                  VENTILACION_POR_OCUPANTE.IDA2
                                ),
                                renovaciones:
                                  renovacionesAireDefinitivoOcupanteNorma(
                                    VENTILACION_POR_OCUPANTE.IDA2
                                  ),
                              }}
                            />
                          )}
                          {selectNormaUso === '4' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option4.p2"
                              defaultMessage="Valor orientativo de {achNorma} ACH para un caudal de {renovaciones} l/s por persona"
                              values={{
                                achNorma: achSegunNorma(
                                  VENTILACION_POR_OCUPANTE.IDA3
                                ),
                                renovaciones:
                                  renovacionesAireDefinitivoOcupanteNorma(
                                    VENTILACION_POR_OCUPANTE.IDA3
                                  ),
                              }}
                            />
                          )}
                          {selectNormaUso === '5' && (
                            <FormattedMessage
                              id="ventilacion.aclaracion3.select.option5.p2"
                              defaultMessage="Valor orientativo de {achNorma} ACH para un caudal de {renovaciones} l/s por persona"
                              values={{
                                achNorma: achSegunNorma(
                                  VENTILACION_POR_OCUPANTE.RD
                                ),
                                renovaciones:
                                  renovacionesAireDefinitivoOcupanteNorma(
                                    VENTILACION_POR_OCUPANTE.RD
                                  ),
                              }}
                            />
                          )}
                        </p>
                      </div>
                    ) : null}
                  </Aclaraciones>
                  <AclaracionesBtn
                    transparent
                    onClick={handleShowValoresMedibles}
                  >
                    <FormattedMessage
                      id="ventilacion.aclaracion4"
                      defaultMessage="Valores usuales medidos"
                    />
                  </AclaracionesBtn>
                  <Aclaraciones>
                    {showValoresMedibles ? (
                      <TablasCont>
                        <Tabla>
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">
                                <FormattedMessage
                                  id="ventilacion.aclaracion4.table1.1.2"
                                  defaultMessage="Ventanas cerradas"
                                />
                              </th>
                              <th scope="col">
                                <FormattedMessage
                                  id="ventilacion.aclaracion4.table1.1.3"
                                  defaultMessage="Ventanas abiertas"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ACH_HABITUALES[0].map((elem) => (
                              <tr>
                                <th scope="row">
                                  {lang.locale === 'es'
                                    ? elem.NAME
                                    : elem.NAME_EN}
                                </th>
                                <td>
                                  {elem.CERRADA[0] !== ''
                                    ? `${elem.CERRADA[0]} ACH`
                                    : null}{' '}
                                  {elem.CERRADA[0] !== '' ? '-' : null}
                                  {elem.CERRADA[1] !== ''
                                    ? `${elem.CERRADA[1]} ACH`
                                    : null}
                                </td>
                                <td>
                                  {elem.ABIERTA[0] !== ''
                                    ? `${elem.ABIERTA[0]} ACH`
                                    : null}{' '}
                                  {elem.ABIERTA[0] !== '' ? '-' : null}
                                  {elem.ABIERTA[1] !== ''
                                    ? `${elem.ABIERTA[1]} ACH`
                                    : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Tabla>
                        <Tabla>
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">
                                <FormattedMessage
                                  id="ventilacion.aclaracion4.table2.1.2"
                                  defaultMessage="Ventanillas cerradas"
                                />
                              </th>
                              <th scope="col">
                                <FormattedMessage
                                  id="ventilacion.aclaracion4.table2.1.3"
                                  defaultMessage="Ventanillas abiertas"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ACH_HABITUALES[1].map((elem) => (
                              <tr>
                                <th scope="row">
                                  {lang.locale === 'es'
                                    ? elem.NAME
                                    : elem.NAME_EN}
                                </th>
                                <td>
                                  {elem.CERRADA[0] !== ''
                                    ? `${elem.CERRADA[0]} ACH`
                                    : null}{' '}
                                  {elem.CERRADA[0] !== '' ? '-' : null}
                                  {elem.CERRADA[1] !== ''
                                    ? `${elem.CERRADA[1]} ACH`
                                    : null}
                                </td>
                                <td>
                                  {elem.ABIERTA[0] !== ''
                                    ? `${elem.ABIERTA[0]} ACH`
                                    : null}{' '}
                                  {elem.ABIERTA[0] !== '' ? '-' : null}
                                  {elem.ABIERTA[1] !== ''
                                    ? `${elem.ABIERTA[1]} ACH`
                                    : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Tabla>
                        <Tabla>
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">
                                <FormattedMessage
                                  id="ventilacion.aclaracion4.table3.1.2"
                                  defaultMessage="Sin ventilación activa"
                                />
                              </th>
                              <th scope="col">
                                <FormattedMessage
                                  id="ventilacion.aclaracion4.table3.1.3"
                                  defaultMessage="Con ventilación activa"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ACH_HABITUALES[2].map((elem) => (
                              <tr>
                                <th scope="row">
                                  {lang.locale === 'es'
                                    ? elem.NAME
                                    : elem.NAME_EN}
                                </th>
                                <td>
                                  {elem.SIN[0] !== ''
                                    ? `${elem.SIN[0]} ACH`
                                    : null}{' '}
                                  {elem.SIN[0] !== '' ? '-' : null}
                                  {elem.SIN[1] !== ''
                                    ? `${elem.SIN[1]} ACH`
                                    : null}
                                </td>
                                <td>
                                  {elem.CON[0] !== ''
                                    ? `${elem.CON[0]} ACH`
                                    : null}{' '}
                                  {elem.CON[0] !== '' ? '-' : null}
                                  {elem.CON[1] !== ''
                                    ? `${elem.CON[1]} ACH`
                                    : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Tabla>
                        <FormattedMessage
                          id="ventilacion.aclaracion4.description"
                          defaultMessage="<Description>Estos valores habituales proporcionados son meramente orientativos.</Description><Description>Si considera que su recinto tiene una buena ventilación (p. ej. todas las ventanas abiertas), tome el mayor valor de los propuestos, y viceversa.</Description><Description>Recuerde que la ventilación de un espacio puede variar ampliamente según las dimensiones del recinto y las condiciones de temperatura y presión de viento, así como en función de las características del sistema de ventilación mecánica (si dispone de él)</Description>"
                          values={{
                            Description: (words) => (
                              <Description>{words}</Description>
                            ),
                          }}
                        />
                      </TablasCont>
                    ) : null}
                  </Aclaraciones>
                </InnerContainer>
              </>
            </Column>
            <Column hiddenMobile width={50} alignX={'center'} alignY={'center'}>
              <img src={ventilacion} alt="Ejemplo de ventilacion en un aula" />
            </Column>
          </Row>
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ventilacion.subtitle2"
              defaultMessage="Equipos de protección individual de los ocupantes"
            />
          </SectionSubtitle>
          <Row>
            <Column stackMobile width={50}>
              <InnerContainer>
                {inputObj
                  .filter((input) => input.id === 30)
                  .map((input) => (
                    <>
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                      <AclaracionIndividual>
                        <FormattedMessage
                          id="gen.eficienciateorica"
                          defaultMessage="Eficiencia teórica"
                        />
                        : {props.stateData.mascarillaExhEf}%
                      </AclaracionIndividual>
                    </>
                  ))}
                {props.stateData.mascarillaExh !== 'Sin mascarilla' &&
                props.stateData.mascarillaExh !==
                  'Pantallas faciales sin mascarilla'
                  ? inputObj
                      .filter((input) => input.id === 31)
                      .map((input) => (
                        <>
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                          <AclaracionIndividual>
                            <FormattedMessage
                              id="gen.eficienciacorregida"
                              defaultMessage="Eficiencia corregida según ajuste"
                            />
                            : {props.stateData.eficienciaExh}%
                          </AclaracionIndividual>
                        </>
                      ))
                  : null}
                {props.stateData.mascarillaExh !== 'Sin mascarilla'
                  ? inputObj
                      .filter((input) => input.id === 34)
                      .map((input) => (
                        <InputRange
                          key={input.id}
                          uid={input.id}
                          updateDatos={props.updateDatos}
                          name={input.name}
                          label={input.label}
                          xstep={input.xstep}
                          precision={input.precision}
                          xmin={input.xmin}
                          xmax={input.xmax}
                          byDefault={input.byDefault}
                          description={input.description}
                          stateData={props.stateData}
                        />
                      ))
                  : null}
                {inputObj
                  .filter((input) => input.id === 32)
                  .map((input) => (
                    <>
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                      <AclaracionIndividual>
                        <FormattedMessage
                          id="gen.eficienciateorica"
                          defaultMessage="Eficiencia teórica"
                        />
                        : {props.stateData.mascarillaInhEf}%
                      </AclaracionIndividual>
                    </>
                  ))}
                {props.stateData.mascarillaInh !== 'Sin mascarilla' &&
                props.stateData.mascarillaInh !==
                  'Pantallas faciales sin mascarilla'
                  ? inputObj
                      .filter((input) => input.id === 33)
                      .map((input) => (
                        <>
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                          <AclaracionIndividual>
                            <FormattedMessage
                              id="gen.eficienciacorregida"
                              defaultMessage="Eficiencia corregida según ajuste"
                            />
                            : {props.stateData.eficienciaInh}%
                          </AclaracionIndividual>
                        </>
                      ))
                  : null}
                {props.stateData.mascarillaInh !== 'Sin mascarilla'
                  ? inputObj
                      .filter((input) => input.id === 35)
                      .map((input) => (
                        <InputRange
                          key={input.id}
                          uid={input.id}
                          updateDatos={props.updateDatos}
                          name={input.name}
                          label={input.label}
                          xstep={input.xstep}
                          precision={input.precision}
                          xmin={input.xmin}
                          xmax={input.xmax}
                          byDefault={input.byDefault}
                          description={input.description}
                          stateData={props.stateData}
                        />
                      ))
                  : null}
              </InnerContainer>
            </Column>
            <Column width={50} hiddenMobile alignX={'center'} alignY={'center'}>
              <img
                src={mascarilla}
                alt="Ejemplificacion de aula con personas con mascarillas puestas"
              />
            </Column>
          </Row>
        </section>
        <ProgressBar progress={100} />
        <Row>
          <Column width={50}>
            <NavigationButtons
              to="/actividad"
              align={'start'}
              back={'grey'}
              textES={'Atrás'}
              textEN={'Back'}
            />
          </Column>
          <Column width={50}>
            <NavigationButtons
              to="/resultados"
              resultados
              align={'end'}
              textES={'Resultados'}
              textEN={'Results'}
            />
          </Column>
        </Row>
      </ResponsiveContainer>
    </FullSectionContainer>
  );
};

export default Ventilacion;

const AclaracionesBtn = styled(Button)`
  text-align: left;

  &:hover {
    color: var(--danger);
  }
`;

const Aclaraciones = styled.div`
  max-width: 320px;

  div {
    padding-bottom: 0.7rem;
    padding-top: 0.7rem;

    h5 {
      font-weight: normal;
      margin-bottom: 0.5rem;
      text-align: justify;
    }

    select {
      background: whitesmoke;
      border: none;
      padding: 0.2rem;
      color: var(--verydark);
      margin-bottom: 1rem;
    }

    p {
      padding-left: 0rem;
      font-size: 0.7rem;
      padding-bottom: 0.5rem;
      text-align: justify;
    }

    p:last-of-type {
      font-weight: bold;
      color: var(--danger);
    }

    a {
      &:link {
        color: var(--dark);
      }
      &:visited {
        color: var(--dark);
      }
      &:hover {
        color: var(--danger);
      }
      &:focus {
        color: var(--danger);
      }
    }
  }
`;

const TablasCont = styled.article`
  padding: 1rem 0;
`;

const Tabla = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--verydark);
  margin-bottom: 1rem;

  thead th:nth-child(1) {
    width: 28%;
  }

  thead th:nth-child(2) {
    width: 36%;
  }

  thead th:nth-child(3) {
    width: 36%;
  }

  thead tr {
    background: var(--verydark);
    color: white;
  }

  tbody th {
    font-size: 0.7rem;
    text-align: start;
    padding-left: 0.5rem;
  }

  td,
  th {
    padding: 5px 3px;
    text-align: center;
  }

  th {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
  }

  td {
    font-size: 0.7rem;
  }

  tbody tr:nth-child(odd) {
    background: whitesmoke;
  }
`;

const Description = styled.h5`
  font-size: 0.7rem;
  font-weight: bold;
  color: grey;
  text-align: justify;
  padding-bottom: 0.5rem;
`;
