import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceDot,
  ReferenceArea,
  ResponsiveContainer,
  LineChart,
} from 'recharts';

import { ticksX, ticksY, data } from '../../data/brotes';

const BrotesGraph = (props) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart syncId="anyId">
        <XAxis
          dataKey="x"
          scale="log"
          ticks={ticksX}
          tick={true}
          domain={[0.0001, 5]}
          style={{
            fontSize: '0.7rem',
          }}
        />
        <YAxis dataKey="y" ticks={ticksY} tick={false} domain={[0, 12.1]} />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceArea
          //Prision
          x1={0.00047}
          x2={0.02404}
          y1={0.95}
          y2={1.05}
          stroke="#3643ff"
          fill="#3643ff"
          fillOpacity="1"
        />
        <ReferenceArea
          //Aula
          x1={0.00089}
          x2={0.0225}
          y1={1.95}
          y2={2.05}
          stroke="#ff7300"
          fill="#ff7300"
          fillOpacity="1"
        />
        <ReferenceArea
          //Teatro
          x1={0.00102}
          x2={0.04612}
          y1={2.95}
          y2={3.05}
          stroke="#ffcc00ff"
          fill="#ffcc00ff"
          fillOpacity="1"
        />
        <ReferenceArea
          //Restaurante
          x1={0.0016}
          x2={0.00776}
          y1={3.95}
          y2={4.05}
          stroke="#2e9100"
          fill="#2e9100"
          fillOpacity="1"
        />
        <ReferenceArea
          //Hospital
          x1={0.00071}
          x2={0.52617}
          y1={4.95}
          y2={5.05}
          stroke="#003869"
          fill="#003869"
          fillOpacity="1"
        />
        <ReferenceArea
          //Biblioteca
          x1={0.0001}
          x2={0.00074}
          y1={5.95}
          y2={6.05}
          stroke="#3d008c"
          fill="#3d008c"
          fillOpacity="1"
        />
        <ReferenceArea
          //Culto
          x1={0.00036}
          x2={0.07665}
          y1={6.95}
          y2={7.05}
          stroke="#7b00a8"
          fill="#7b00a8"
          fillOpacity="1"
        />
        <ReferenceArea
          //Supermercado
          x1={0.00035}
          x2={0.0262}
          y1={7.95}
          y2={8.05}
          stroke="#95A5A6"
          fill="#95A5A6"
          fillOpacity="1"
        />
        <ReferenceArea
          //Aula EF
          x1={0.0001}
          x2={0.01419}
          y1={8.95}
          y2={9.05}
          stroke="#a8009a"
          fill="#a8009a"
          fillOpacity="1"
        />
        <ReferenceArea
          //Cena
          x1={0.00169}
          x2={0.02313}
          y1={9.95}
          y2={10.05}
          stroke="#9ca145"
          fill="#9ca145"
          fillOpacity="1"
        />
        <ReferenceArea
          //Guangzhou

          x1={0.00471}
          x2={0.05428}
          y1={10.95}
          y2={11.05}
          stroke="#00a8a3"
          fill="#00a8a3"
          fillOpacity="1"
        />
        <ReferenceArea
          //Coro
          x1={0.02427}
          x2={0.49676}
          y1={11.95}
          y2={12.05}
          stroke="red"
          fill="red"
          fillOpacity="1"
        />
        {data.map((elem) => (
          <ReferenceDot
            x={elem.bajo}
            y={elem.y}
            r={4}
            fill="#CACFD2"
            stroke="black"
            strokeWidth="1.5"
          />
        ))}
        {data.map((elem) => (
          <ReferenceDot
            x={elem.medio}
            y={elem.y}
            r={4}
            fill="#6cdeffff"
            stroke="black"
            strokeWidth="1.5"
          />
        ))}
        {data.map((elem) => (
          <ReferenceDot
            x={elem.alto}
            y={elem.y}
            r={4}
            fill="#ffcc00ff"
            stroke="black"
            strokeWidth="1.5"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default BrotesGraph;
