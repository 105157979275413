import React, { useState, useContext } from 'react';
import { langContext } from '../../context/langContext';
import styled, { css } from 'styled-components';

const InputClassic = (props) => {
  const [state, setState] = useState({ x: props.stateData[props.name] });

  const lang = useContext(langContext);

  const fixedToPrecision = (num, precision) => {
    return Number(num.toFixed(precision));
  };

  // Dont use fixedToPrecision on handleInputNumber's setState, since it updates state on change and input value is equal to state.
  function handleInputNumber(e) {
    if (e.target.value >= props.xmin && e.target.value <= props.xmax) {
      props.updateDatos(Number(e.target.value), props.name);
      setState(() => ({ x: e.target.value }));
    } else {
      props.updateDatos(null, props.name);
      setState(() => ({ x: null }));
    }
  }

  const handleBlur = (e) => {
    if (
      isNaN(e.target.value) ||
      e.target.value === null ||
      e.target.value === undefined ||
      e.target.value < props.xmin ||
      e.target.value > props.xmax
    ) {
      if (lang.locale === 'es')
        window.alert(
          `Introduzca un número comprendido entre ${props.xmin} y ${props.xmax}`
        );
      if (lang.locale === 'en')
        window.alert(`Enter a number between ${props.xmin} and ${props.xmax}`);
    } else {
      setState(() => ({
        x: fixedToPrecision(Number(e.target.value), props.precision),
      }));
    }
  };

  return (
    <InputClassicContainer description={props.description}>
      <Label htmlFor={`classicInput${props.uid}`} className="inputLabel">
        {props.label}
      </Label>
      <input
        id={`classicInput${props.uid}`}
        className={`inputNumber ${
          state.x === null || state.x === undefined
            ? 'inputDanger'
            : 'inputSuccess'
        }`}
        type="number"
        value={state.x}
        min={props.xmin}
        max={props.xmax}
        onChange={handleInputNumber}
        onBlur={handleBlur}
      />

      <Description className={`${props.description ? null : 'hidden'}`}>
        {props.description}
      </Description>
    </InputClassicContainer>
  );
};

const InputClassicContainer = styled.div`
  display: grid;
  justify-items: start;
  align-items: center;
  column-gap: 1rem;
  grid-template-rows: 30px;
  grid-template-columns: auto 56px;
  grid-template-areas:
    'iLabel iNumber '
    'iLabel iNumber';
  ${(props) =>
    props.description &&
    css`
      grid-template-rows: 30px auto;
      grid-template-columns: auto 56px;
      grid-template-areas:
        'iLabel iNumber'
        'iDesc iDesc';
    `}
  max-width: 320px;
  padding-bottom: 1.5rem;

  .inputNumber {
    grid-area: iNumber;
    text-align: center;
    align-self: center;
    width: 3.5rem;
    padding: 0.4rem;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    font-size: 0.8rem;
    color: var(--verydark);
    outline: none;
    border: 2px solid white;
    background: whitesmoke;
  }

  .inputDanger {
    border: 2px solid var(--danger);
  }
  .inputSuccess {
    border: 2px solid whitesmoke;
  }

  .inputSlider {
    width: 170px;
    height: 15px;
    align-self: center;
    margin: 0 10px;
  }

  .hidden {
    display: none;
  }
`;

const Label = styled.label`
  grid-area: iLabel;
  justify-self: start;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Description = styled.h5`
  grid-area: iDesc;
  justify-self: start;
  font-size: 0.7rem;
  font-weight: bold;
  color: grey;
  padding-top: 0.6rem;
  padding-left: 0.2rem;
  text-align: justify;
`;

export default InputClassic;
