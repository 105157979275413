import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  FullSectionContainer,
  ResponsiveContainer,
  SectionSubtitle,
  SectionTitle,
} from '../../globalStyles';
import BackButton from '../UI/BackHistoryBtn';
import { FormattedMessage } from 'react-intl';

const PoliticaCookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const avisoCookies2 = document.getElementById('avisoCookies');
    const fondoCookies2 = document.getElementById('fondoCookies');
    avisoCookies2.classList.remove('activo');
    fondoCookies2.classList.remove('activo');
  }, []);

  return (
    <PoliticaCookiesContainer white>
      <FormattedMessage
        id="cookies.pagetitle"
        defaultMessage="Política de Cookies"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Detalle de condiciones de la política de cookies de la aplicación web.s"
            />
          </Helmet>
        )}
      </FormattedMessage>

      <ResponsiveContainer>
        <SectionTitle primary>
          <FormattedMessage
            id="cookies.title"
            defaultMessage="Política de cookies"
          />
        </SectionTitle>

        <SectionSubtitle primary>
          <FormattedMessage
            id="cookies.subtitle1"
            defaultMessage="¿Qué son las cookies?"
          />
        </SectionSubtitle>
        <Parrafo>
          <FormattedMessage
            id="cookies.subtitle1.content"
            defaultMessage="Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación."
          />
        </Parrafo>

        <SectionSubtitle primary>
          <FormattedMessage
            id="cookies.subtitle2"
            defaultMessage="¿Qué tipos de cookies se utilizan en esta página web?"
          />
        </SectionSubtitle>
        <Parrafo>
          <TituloParrafo>
            <FormattedMessage
              id="cookies.subtitle2.content1.title"
              defaultMessage="Según su finalidad:"
            />
          </TituloParrafo>
          <ul>
            <li>
              <FormattedMessage
                id="cookies.subtitle2.content1.list1"
                defaultMessage="<strong>De preferencias: </strong>Son aquellas que permiten recordar información para que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios En nuestra página web corresponden con aquellas destinadas a guardar la configuración establecida por usted respecto a sus preferencias de cookies."
                values={{ strong: (words) => <strong>{words}</strong> }}
              />
            </li>
            <li>
              <FormattedMessage
                id="cookies.subtitle2.content1.list2"
                defaultMessage="<strong>De análisis: </strong>Son aquellas que, tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar los servicios que le ofrecemos."
                values={{ strong: (words) => <strong>{words}</strong> }}
              />
            </li>
          </ul>
          <TituloParrafo>
            <FormattedMessage
              id="cookies.subtitle2.content2.title"
              defaultMessage="Según la entidad que las gestiona:"
            />{' '}
          </TituloParrafo>
          <ul>
            <li>
              <FormattedMessage
                id="cookies.subtitle2.content2.list1"
                defaultMessage="<strong>Propias: </strong>Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario."
                values={{ strong: (words) => <strong>{words}</strong> }}
              />
            </li>
            <li>
              <FormattedMessage
                id="cookies.subtitle2.content2.list2"
                defaultMessage="<strong>De terceros: </strong> Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies. Solo cedemos los datos a Google de acuerdo a los requisitos de las funciones usadas de su herramienta Google Analytics y YouTube."
                values={{ strong: (words) => <strong>{words}</strong> }}
              />
            </li>
          </ul>
        </Parrafo>

        <SectionSubtitle primary>
          <FormattedMessage
            id="cookies.subtitle3"
            defaultMessage="Forma de aceptar, denegar y revocar el consentimiento sobre el uso de cookies"
          />
        </SectionSubtitle>
        <Parrafo>
          <FormattedMessage
            id="cookies.subtitle3.content"
            defaultMessage="En nuestra aplicación web, usted dispondrá de un Banner/Layer de cookies que le permitira de forma clara, aceptar o rechazar el consentimiento mediante botones habilitados a ese efecto, cuando entre en la página por primera vez y en tanto haya expirado el tiempo estipulado para guardar sus preferencias, en el dispositivo desde el que acceda. Una vez usted ya ha otorgado el consentimiento, podrá revocarlo desde las opciones de su navegador. Le indicamos donde encontrar más información para hacerlo en los principales exploradores:"
          />
          &nbsp;
          <a
            href="https://support.google.com/accounts/answer/61416?hl=es&co=GENIE.Platform%3DDesktop#:~:text=Haz%20clic%20en%20Cookies.,y%20consulten%20datos%20de%20cookies."
            target="_blank"
            rel="noreferrer noopener"
          >
            Google Chrome
          </a>
          ,&nbsp;
          <a
            href="https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros"
            target="_blank"
            rel="noreferrer noopener"
          >
            Mozilla Firefox
          </a>
          ,&nbsp;
          <a
            href="https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
            target="_blank"
            rel="noreferrer noopener"
          >
            Microsoft Internet Explorer
          </a>
          ,&nbsp;
          <a
            href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
            target="_blank"
            rel="noreferrer noopener"
          >
            Microsoft Edge
          </a>
          &nbsp;y&nbsp;
          <a
            href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
            target="_blank"
            rel="noreferrer noopener"
          >
            Safari
          </a>
          .
        </Parrafo>
        <SectionSubtitle primary>
          <FormattedMessage
            id="cookies.subtitle4"
            defaultMessage="Transferencias de datos a terceros países"
          />
        </SectionSubtitle>
        <Parrafo>
          <FormattedMessage
            id="cookies.subtitle4.content"
            defaultMessage="Los editores de la aplicación web no trasladan información a terceros países. Nuestros proveedores sin embargo, pueden trasladar sus datos a terceros países, para los fines anteriormente descritos. Las transferencias internacionales de datos deben de realizarse con un adecuado nivel de protección y con aportación de garantías suficientes de acuerdo con la legislación vigente. Rogamos tengan en consideración los riesgos derivados de la transferencia internacional de datos antes de otorgar su consentimiento para el uso de cookies. Puede acceder a información sobre la política de cookies, medios y garantías en uso de sus datos por parte de nuestros proveedores:"
          />
          &nbsp;
          <a
            href="https://policies.google.com/privacy?hl=es"
            target="_blank"
            rel="noreferrer noopener"
          >
            Google
          </a>
          .
        </Parrafo>

        <SectionSubtitle primary>
          <FormattedMessage
            id="cookies.subtitle5"
            defaultMessage="Otros datos importantes sobre cookies y política de privacidad."
          />
        </SectionSubtitle>
        <Parrafo>
          <ul>
            <li>
              <FormattedMessage
                id="cookies.subtitle5.list1"
                defaultMessage="<strong>Periodo de conservación: </strong>Los datos sobre sus preferencias sobre cookies de ser almacenados en el Almacenamiento Local, no tienen un periodo de expiración concreto, y pueden ser eliminados por el usuario en cualquier momento. Los datos almacenados mediante Cookies, tendrán como máximo una fecha de expiración de 26 meses posterior a la interacción del usuario que los cree o los renueve."
                values={{
                  strong: (words) => <strong>{words}</strong>,
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="cookies.subtitle5.list2"
                defaultMessage="<strong>Elaboración de perfiles de usuarios: </strong>La información no será destinada a crear perfiles de personas físicas ni a su identificación."
                values={{
                  strong: (words) => <strong>{words}</strong>,
                }}
              />
            </li>
          </ul>
        </Parrafo>
        <BackButton>
          <FormattedMessage id="gen.btn.atras" defaultMessage="Atrás" />
        </BackButton>
      </ResponsiveContainer>
    </PoliticaCookiesContainer>
  );
};

export default PoliticaCookies;

const PoliticaCookiesContainer = styled(FullSectionContainer)`
  ul {
    margin: 0 0 1rem 0;
  }

  li {
    margin: 0 0 0.3rem 1rem;
  }

  section {
    margin-bottom: 4rem;
  }

  a {
    &:link {
      color: var(--dark);
    }
    &:visited {
      color: var(--dark);
    }
    &:hover {
      color: var(--danger);
    }
    &:focus {
      color: var(--danger);
    }
  }
`;

const Parrafo = styled.p`
  margin-bottom: 2rem;
`;

const TituloParrafo = styled.p`
  margin-bottom: 1rem;
`;
