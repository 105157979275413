import styled, { css } from 'styled-components';

export const color = {
  danger: '#e63946',
  verylight: '#f1faee',
  light: '#a8dadc',
  dark: '#457b9d',
  verydark: '#1d3557',
};

export const size = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
  hideImage: '736px',
};

export const devide = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
};

export const FullSectionContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  ${(props) =>
    props.white &&
    css`
      background: white;
      color: var(--verydark);
    `}
  ${(props) =>
    props.verydark &&
    css`
      background: var(--gdverydark);
      color: white;
    `};
  ${(props) =>
    props.light &&
    css`
      background: var(--gdlight);
      color: var(--verydark);
    `}
`;

export const ResponsiveContainer = styled.div`
  padding: 8rem 1rem 4rem 1rem;
  max-width: ${size.lg};
  width: 100%;
  @media (max-width: 340px) {
    padding: 8rem 5px 4rem 0;
  }
`;

export const InnerContainer = styled.div`
  padding-bottom: 2rem;
`;

export const SectionTitle = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-size: calc(1.5rem + 1vw);
  padding: 2rem 0;
  position: relative;
  ${(props) =>
    props.primary &&
    css`
      color: var(--verydark);
    `}
  ${(props) =>
    props.secundary &&
    css`
      color: whitesmoke;
    `}
`;

export const SectionSubtitle = styled.h3`
  font-family: 'Montserrat', sans-serif;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;
  padding-bottom: 2rem;
  line-height: 1.6;
  ${(props) =>
    props.primary &&
    css`
      text-decoration-color: var(--danger);
      color: var(--dark);
    `}
  ${(props) =>
    props.secundary &&
    css`
      text-decoration-color: var(--danger);
      color: var(--dark);
    `};
`;

export const Button = styled.button`
  padding: 0.5rem;
  text-align: center;
  min-width: 220px;
  width: fit-content;
  margin: 1rem 0.5rem;
  font-family: 'Nunito', sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  ${(props) =>
    props.primary &&
    css`
      background: var(--verydark);
      color: white;
      border: 2px solid var(--verydark);

      &:hover {
        background: whitesmoke;
        color: var(--verydark);
        border: 2px solid var(--dark);
      }
      &:active {
        background: var(--dark);
        color: whitesmoke;
        border: 2px solid var(--verydark);
      }
      &:focus-visible {
        outline: 2px solid var(--danger);
        outline-offset: 2px;
      }
    `};
  ${(props) =>
    props.transparent &&
    css`
      background: transparent;
      color: var(--dark);
      border: none;
      tab-index: -1;
      margin: 0;
    `};
  ${(props) =>
    props.secondary &&
    css`
      background: whitesmoke;
      color: var(--verydark);
      border: 2px solid var(--verylight);

      &:hover {
        background: var(--light);
        color: var(--verydark);
        border: 2px solid var(--verylight);
      }
      &:active {
        background: var(--dark);
        color: whitesmoke;
        border: 2px solid var(--verylight);
      }
      &:focus-visible {
        outline: 2px solid var(--danger);
        outline-offset: 2px;
      }
    `};

  ${(props) =>
    props.secondaryVariant &&
    css`
      background: whitesmoke;
      color: var(--verydark);
      border: 2px solid var(--verylight);

      &:hover {
        background: var(--verydark);
        color: var(--verylight);
        border: 2px solid var(--verylight);
      }
      &:active {
        background: var(--dark);
        color: whitesmoke;
        border: 2px solid var(--verylight);
      }
      &:focus-visible {
        outline: 2px solid var(--danger);
        outline-offset: 2px;
      }
    `};

  ${(props) =>
    props.danger &&
    css`
      color: white;
      background: var(--danger);
      border: 2px solid var(--danger);

      &:hover {
        background: var(--verydark);
        color: var(--verylight);
        border: 2px solid var(--verylight);
      }
      &:active {
        background: var(--dark);
        color: whitesmoke;
        border: 2px solid var(--verylight);
      }
      &:focus-visible {
        outline: 2px solid var(--danger);
        outline-offset: 2px;
      }
    `};
`;

export const AclaracionIndividual = styled.h5`
  max-width: 320px;
  padding-bottom: 1rem;
  font-size: 0.7rem;
  color: var(--dark);
  text-align: justify;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignX === 'start' ? 'flex-start' : null)};
  align-items: ${(props) => (props.alignX === 'center' ? 'center' : null)};
  align-items: ${(props) => (props.alignX === 'end' ? 'flex-end' : null)};
  justify-content: ${(props) =>
    props.alignY === 'start' ? 'flex-start' : null};
  justify-content: ${(props) => (props.alignY === 'center' ? 'center' : null)};
  justify-content: ${(props) => (props.alignY === 'end' ? 'flex-end' : null)};
  width: ${(props) => (props.width ? `${props.width}%` : null)};

  ${(props) =>
    props.hiddenMobile &&
    css`
      @media (max-width: ${size.hideImage}) {
        display: none;
      }
    `}
  ${(props) =>
    props.stackMobile &&
    css`
      @media (max-width: ${size.hideImage}) {
        width: 100%;
      }
    `}
  img {
    max-width: 400px;
  }
`;

export const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;

  ${(props) =>
    props.stackMobile &&
    css`
      @media (max-width: ${size.hideImage}) {
        display: flex;
        flex-direction: column;
      }
    `}
`;

// Metodos decimales
export const truncToPrecision = (num, precision) => {
  return Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const truncToPrecisionZeros = (num, precision) => {
  return (
    Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision)
  ).toFixed(precision);
};

export const fixedToPrecisionNumber = (num, precision) => {
  if (num === undefined || num === null) return 0;
  return Number(num.toFixed(precision));
};

export const ceilToPrecisionString = (num, precision) => {
  return Math.ceil(num * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const redondear = (num, precision) => {
  return Math.ceil(num * Math.pow(10, precision)) / Math.pow(10, precision);
};
