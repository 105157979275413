import React, { useCallback, useEffect, useState, useContext } from 'react';
import { langContext } from '../../context/langContext';

const NivelRiesgo = (props) => {
  const lang = useContext(langContext);

  const [riesgo, setRiesgo] = useState(null);

  const updateState = useCallback((nivelRiesgo) => {
    setRiesgo(nivelRiesgo);
  }, []);

  useEffect(() => {
    if (props.resultado < props.limite1) {
      lang.locale === 'es' && updateState('bajo');
      lang.locale === 'en' && updateState('low');
    } else if (props.resultado < props.limite2) {
      lang.locale === 'es' && updateState('medio');
      lang.locale === 'en' && updateState('intermediate');
    } else {
      lang.locale === 'es' && updateState('alto');
      lang.locale === 'en' && updateState('high');
    }
  }, [props.resultado, props.limite1, props.limite2, updateState, lang.locale]);

  return <>{riesgo}</>;
};

export default NivelRiesgo;
