import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PageView = () => {
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem('cookies-aceptadas') === 'true') {
      ReactGA.initialize('G-N2LCSD6B6L');
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        title: `${location.pathname}`,
      });
    }
  }, [location.pathname]);

  return null;
};

export default PageView;
