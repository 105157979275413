import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { langContext } from '../../context/langContext';

const InputSelect = (props) => {
  const [state, setState] = useState({ x: props.stateData[props.name] });

  const handleChange = (e) => {
    props.updateDatos(e.target.value, e.target.name);
    setState({ x: e.target.value });
  };

  const lang = useContext(langContext);

  return (
    <InputSelectContainer description={props.description}>
      <Label htmlFor={`SelectInput${props.uid}`} className="inputLabel">
        {props.label}
      </Label>
      <Select
        id={`SelectInput${props.uid}`}
        name={props.name}
        onChange={handleChange}
        value={state.x}
      >
        {lang.locale === 'es'
          ? props.optionsES.map((option, idx) => (
              <option key={idx} value={props.values[idx]}>
                {option}
              </option>
            ))
          : props.optionsEN.map((option, idx) => (
              <option key={idx} value={props.values[idx]}>
                {option}
              </option>
            ))}
      </Select>
      <Description className={`${props.description ? null : 'hidden'}`}>
        {props.description}
      </Description>
    </InputSelectContainer>
  );
};

const InputSelectContainer = styled.div`
  display: grid;
  justify-items: start;
  align-items: center;
  grid-template-areas:
    'iLabel'
    'iSelect';
  padding-bottom: 1.5rem;
  ${(props) =>
    props.description &&
    css`
      grid-template-areas:
        'iLabel'
        'iSelect'
        'iDesc';
    `};
  max-width: 320px;

  .hidden {
    display: none;
  }
`;

const Label = styled.label`
  grid-area: iLabel;
  justify-self: start;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Select = styled.select`
  grid-area: iSelect;
  text-align: start;
  padding: 0.4rem;
  font-family: 'Nunito', sans-serif;
  font-size: 0.9rem;
  color: var(--verydark);
  border: 2px solid white;
  background: whitesmoke;
  width: 324px;
`;

const Description = styled.h5`
  grid-area: iDesc;
  justify-self: start;
  font-size: 0.7rem;
  font-weight: bold;
  color: grey;
  padding-top: 0.6rem;
  padding-left: 0.2rem;
  text-align: justify;
`;

export default InputSelect;
