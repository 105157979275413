import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

const ReactLineChart = (props) => {
  // Sacar el proximo numero que sea multiplo de 100 (ejeY)
  const getNext100 = (int) => {
    const resto = int % 100;
    const diff = 100 - resto;
    return int + diff;
  };

  // Crear el array de ticks de forma dinamica (ambos ejes)
  const ticksX = () => {
    const ticks = [];
    for (let i = 0; i < props.duracion + 61; i = i + 10) {
      ticks.push(i);
    }
    return ticks;
  };

  const ticksY = () => {
    const ticks = [];
    for (let i = 400; i < getNext100(props.domain) + 1; i = i + 100) {
      ticks.push(i);
    }
    return ticks;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="Minuto"
          ticks={ticksX()}
          style={{
            fontSize: '0.7rem',
          }}
        />
        <YAxis
          type="number"
          ticks={ticksY()}
          domain={[400, getNext100(props.domain)]}
          style={{
            fontSize: '0.7rem',
          }}
        />
        <Tooltip />
        <ReferenceLine
          x={props.duracion}
          stroke="#cc000096"
          strokeDasharray="3 5"
        />
        <Line
          type="monotone"
          dataKey="Nivel de CO2 en exterior"
          stroke="#1565C0"
          dot={false}
          activeDot={{ r: 5 }}
        />
        <Line
          type="monotone"
          dataKey="Nivel de CO2 en interior con filtrado/UV"
          stroke="#FF8A65"
          dot={false}
          activeDot={{ r: 5 }}
        />
        <Line
          type="monotone"
          dataKey="Límite de CO2 según Havard"
          stroke="#81C784"
          dot={false}
          activeDot={{ r: 5 }}
        />
        <Line
          type="monotone"
          dataKey="Límite de CO2 según normativa"
          stroke="#FFA726"
          dot={false}
          activeDot={{ r: 5 }}
        />
        <Line
          type="monotone"
          dataKey="Nivel de CO2 en interior"
          stroke="#C62828"
          dot={false}
          activeDot={{ r: 5 }}
        />

        <ReferenceDot
          x={props.duracion}
          y={props.nivelDuracion}
          r={4}
          stroke="#C62828"
          strokeWidth={3}
          fill="#C62828"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReactLineChart;
