import styled from 'styled-components';
import IconArrowUp from './Icons/ArrowUp';

export const ButtonToTop = (props) => {
  const topPage = () => document.body.scrollIntoView({ behavior: 'smooth' });

  return (
    <BtnToTop type="button" onClick={topPage} {...props}>
      <IconArrowUp fontSize={props.fontSize} />
    </BtnToTop>
  );
};

const BtnToTop = styled.button`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: whitesmoke;
  color: #000;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  z-index: 9999;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  border: solid 1px whitesmoke;

  &:hover {
    background: var(--danger);
    color: whitesmoke;
    border: 1px solid #9e9e9e;
  }

  &:active {
    background: whitesmoke;
    color: var(--very-dark);
    border: 1px solid #9e9e9e;
  }

  @media (max-width: 768px) {
    box-shadow: 0 0 5px rgba(86, 86, 86, 0.5);
    bottom: 15px;
    left: 15px;

    &:focus {
      background: whitesmoke;
      color: #000;
      border: solid 1px whitesmoke;
    }

    &:active {
      background: var(--danger);
      color: whitesmoke;
      border: 1px solid #9e9e9e;
    }
  }
`;
