import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './globalStyles.css';
import { LangProvider } from './context/langContext';

ReactDOM.render(
  <LangProvider>
    <App />
  </LangProvider>,
  document.getElementById('root')
);
