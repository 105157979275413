import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Column,
  FullSectionContainer,
  ResponsiveContainer,
  Row,
  SectionSubtitle,
  SectionTitle,
} from '../../globalStyles';
import BackButton from '../UI/BackHistoryBtn';

import { FormattedMessage } from 'react-intl';

const Informacion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <InfoPage white>
      <FormattedMessage
        id="informacion.pagetitle"
        defaultMessage="Información Adicional"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Información adicional sobre autores, metodología y de utilidad para el uso de la aplicación web"
            />
          </Helmet>
        )}
      </FormattedMessage>
      <ResponsiveContainer>
        <SectionTitle primary>
          <FormattedMessage
            id="informacion.title"
            defaultMessage="Otra información de interés"
          />
        </SectionTitle>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="informacion.subtitle1"
              defaultMessage="Información de interés"
            />
          </SectionSubtitle>
          <ul>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list1"
                defaultMessage="Esta web se centra en estimar la propagación de enfermedades aéreas únicamente mediante transmisión por aerosoles."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list2"
                defaultMessage="Este cálculo se basa en un modelo estándar de transmisión de
                enfermedades por aerosoles, el denominado modelo Wells-Riley. Wells W.F. 1995. Airborne contagion and air hygiene. Cambridge, MA: Harvard University
                Press."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list3"
                defaultMessage="No es un modelo epidemiológico, aunque pueda utilizarse como subcomponente de estos para estimar la transmisión por aerosoles en función de diferentes parámetros."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list4"
                defaultMessage="Este modelo NO INCLUYE transmisión por gotas o contacto/fómites, y asume que se respeta la distancia social de 2 m. De lo contrario, se producirá una transmisión más alta."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list5"
                defaultMessage="Este modelo considera que la atmósfera contenida en el recinto tiene un comportamiento homogéneo, con lo que no tiene en cuenta fenómenos como estratificación térmica o el conceptos como la flotabilidad, habituales en recintos de gran altura libre."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list6"
                defaultMessage="Varios de los parámetros usados en el modelo planteado son inciertos, habiéndose estimado sobre la base de conocimiento actual."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list7"
                defaultMessage="El formulario está diseñado para ser completado en el orden establecido."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list8"
                defaultMessage="El presente modelo está basado en los denominados eventos de superpropagación. Por ello, la capacidad infecciosa de muchas personas puede ser menor que la reflejada en este modelo propuesto, con lo que la cantidad de personas infectadas según el mismo puede resultar demasiado alta en esos supuestos."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list9"
                defaultMessage="Este modelo NO ES APLICABLE a la transmisión entre personas cuando estas se encuentren en recintos y condiciones distintas a las aquí analizadas."
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle1.list10"
                defaultMessage="Los resultados obtenidos han de ser interpretados estadísticamente."
              />
            </li>
          </ul>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="informacion.subtitle2"
              defaultMessage="Autores"
            />
          </SectionSubtitle>
          <ul>
            <li>
              <a
                href="http://Personal.us.es/mcampano"
                target="_blank"
                rel="noreferrer noopener"
              >
                Miguel Ángel Campano Laborda.
              </a>
            </li>
            <li>
              <a
                href="https://investigacion.us.es/sisius/sis_showpub.php?idpers=5470"
                target="_blank"
                rel="noreferrer noopener"
              >
                Samuel Domínguez Amarillo.
              </a>
            </li>
            <li>
              <a
                href="https://investigacion.us.es/sisius/sis_showpub.php?idpers=13492"
                target="_blank"
                rel="noreferrer noopener"
              >
                Ignacio Acosta García.
              </a>
            </li>
            <li>
              <a
                href="https://investigacion.us.es/sisius/sis_showpub.php?idpers=16100"
                target="_blank"
                rel="noreferrer noopener"
              >
                Jessica Fernández-Agüera Escudero.
              </a>
            </li>
            <li>
              <a
                href="https://investigacion.us.es/sisius/sis_showpub.php?idpers=7376"
                target="_blank"
                rel="noreferrer noopener"
              >
                Pedro Bustamante Rojas.
              </a>
            </li>
            <li>
              <a
                href="https://investigacion.us.es/sisius/sis_showpub.php?idpers=3064"
                target="_blank"
                rel="noreferrer noopener"
              >
                Juan José Sendra Salas.
              </a>
            </li>
            <li>
              <a
                href="https://www.colorado.edu/chemistry/jose-luis-jimenez"
                target="_blank"
                rel="noreferrer noopener"
              >
                José Luis Jiménez Palacios.
              </a>
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle2.list1"
                defaultMessage="<a>Juan Diego Velarde Rodríguez (Desarrollo web).</a>"
                values={{
                  a: (words) => (
                    <a
                      href="https://twitter.com/Jdvelrod"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {words}
                    </a>
                  ),
                }}
              />
            </li>
          </ul>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="informacion.subtitle3"
              defaultMessage="Metodología de cálculo"
            />
          </SectionSubtitle>
          <ul>
            <li>
              <FormattedMessage
                id="informacion.subtitle3.list1"
                defaultMessage="Basada en la hoja <link> COViD-19 Aerosol Transmission Estimator v3.5.7 (26 de julio de 2021).</link>"
                values={{
                  link: (words) => (
                    <a
                      href="https://docs.google.com/spreadsheets/d/16K1OQkLD4BjgBdO8ePj6ytf-RpPMlJ6aXFg3PrIQBbQ/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {words}
                    </a>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle3.list2"
                defaultMessage="Metodología de cálculo original desarrollada por el <link>Profesor José L. Jiménez y el Dr. Zhe Peng</link>, a partir del trabajo y ayuda de (entre otros): Linsey Marr, Shelly Miller, Giorgio Buonnano, Lidia Morawska, Don Milton, Julian Tang, Jarek Kurnitski, Xavier Querol, Matthew McQueen, Charles Stanier, Joel Eaves, Alfred Trukenmueller, Ty Newell, Greg Blonder, Andrew Maynard, Nathan Skinner, Clark Vangilder, Roger Olsen, Alex Mikszewski, Prasad Kasibhatla, Joe Bruce, Paul Dabisch, Yumi Roth, Andrew Persily, Susan Masten, Sebastien Tixier, Amber Kraver, Howard Chong, John Fay, Dustin Poppendieck, Jim Bagrowski, Gary Chaulklin, Richard Meehan, Jarrell Wenger, Alex Huffman, Bertrand Waucquez, Elizabeth Goldberg."
                values={{
                  link: (words) => (
                    <a
                      href="https://pubs.acs.org/doi/10.1021/acs.estlett.1c00183"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {words}
                    </a>
                  ),
                }}
              />
            </li>
          </ul>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="informacion.subtitle4"
              defaultMessage="Financiación"
            />
          </SectionSubtitle>
          <p>
            <FormattedMessage
              id="informacion.subtitle4.prelist"
              defaultMessage="Los resultados presentados han sido financiados por:"
            />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="informacion.subtitle4.list1"
                defaultMessage="El <link>Ministerio de Ciencia e Innovación del Gobierno de España</link> a través del proyecto de investigación CHRONOLIGHT: Iluminación biodinámica de amplio espectro para la cronorregulación biológica y neutralización de patógenos en centros hospitalarios (Ref PID2020-117563RB-I00)."
                values={{
                  link: (words) => (
                    <a
                      href="https://www.ciencia.gob.es/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {words}
                    </a>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle4.list2"
                defaultMessage="La Consejería de Economía, Conocimiento, Empresas y Universidades
                de la Junta de Andalucía a través del proyecto de investigación
                unCOVER: Evaluación de la capacidad de protección frente a la
                contaminación de los Centros de Educación infantil (Ref.
                US-1381053)."
                values={{
                  link: (words) => (
                    <a
                      href="https://www.ciencia.gob.es/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {words}
                    </a>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="informacion.subtitle4.list3"
                defaultMessage="La Empresa <link>Metro de Sevilla Sociedad Concesionaria de la Junta de Andalucía</link> a través del Contrato 68/83 MetrCO2: Proyecto de Caracterización de las Condiciones Ambientales existentes en las Salas de Pasajeros de los Trenes de Metro Sevilla en relación al riesgo de transmisión por aerosol frente al SARS-CoV-2 (Ref. 4190/1113)."
                values={{
                  link: (words) => (
                    <a
                      href="https://www.metro-sevilla.es/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {words}
                    </a>
                  ),
                }}
              />
            </li>
            <FormattedMessage
              id="informacion.subtitle4.postlist"
              defaultMessage="Los autores desean expresar su agradecimiento por todo el apoyo
            técnico y financiero brindado."
            />
          </ul>
        </section>
        <Row>
          <Column width={50}>
            <BackButton>
              <FormattedMessage id="gen.btn.atras" defaultMessage="Atrás" />
            </BackButton>
          </Column>
          <Column width={50}></Column>
        </Row>
      </ResponsiveContainer>
    </InfoPage>
  );
};

export default Informacion;

const InfoPage = styled(FullSectionContainer)`
  ul {
    text-align: justify;
    margin: 0 0 0.6rem 0;
  }

  li {
    text-align: justify;
    margin: 0 0 0.3rem 1rem;
  }

  p {
    text-align: justify;
    margin: 0 0 0.3rem 0;
  }

  section {
    margin-bottom: 4rem;
  }

  a {
    &:link {
      color: var(--dark);
    }
    &:visited {
      color: var(--dark);
    }
    &:hover {
      color: var(--danger);
    }
    &:focus {
      color: var(--danger);
    }
  }
`;
