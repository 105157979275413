import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FullSectionContainer,
  ResponsiveContainer,
  size,
} from '../../globalStyles.js';
import usLogo from '../../images/usLogo.svg';
import ventilandoLogo from '../../images/ventilandoLogo.svg';
import arqWellnessLogo from '../../images/arqWellnessLogo.png';
import aireamosLogo from '../../images/aireamosLogo.svg';
import chronoLightLogo from '../../images/chronoLightLogo.svg';
import coloradoBoulderLogo from '../../images/coloradoBoulderLogo.png';
import unCoverLogo from '../../images/uncoverLogo.svg';
import fundacionVisibleLogo from '../../images/fundacionVisibleLogo.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ModalIntro from '../UI/ModalIntro';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext.js';
import es from '../../images/es.svg';
import en from '../../images/en.svg';

const Intro = () => {
  const lang = useContext(langContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal((state) => !state);
  };

  return (
    <IntroFullSectionContainer>
      <Helmet>
        <title>Covid Risk</title>
        <meta
          name="description"
          content="Aplicación Web que calcula el riesgo de infección por COViD-19 a través del completado de un formulario web con datos relacionados con el evento sobre el que se quiere realizar el cálculo"
        />
      </Helmet>
      <IntroContainer>
        <BotoneraIdiomas>
          <button
            className="langBtn"
            onClick={() => {
              lang.setLang('spanish');
            }}
          >
            <img src={es} alt="Icono bandera España" />
          </button>
          <button
            className="langBtn"
            onClick={() => {
              lang.setLang('english');
            }}
          >
            <img src={en} alt="Icono bandera Reino Unido" />
          </button>
        </BotoneraIdiomas>
        <Title2>
          <div>
            <h1>
              Covid Risk<sup>airborne</sup>
            </h1>
          </div>
        </Title2>
        <Title>
          <FormattedMessage
            id="intro.hero"
            defaultMessage="ESTIMADOR DE TRANSMISIÓN AÉREA DE COViD-19 MEDIANTE AEROSOLES"
          />
        </Title>
        <BtnCont>
          <BtnInfo to="/informacion">
            <FormattedMessage
              id="intro.btn.info"
              defaultMessage="Información de interés"
            />
          </BtnInfo>
          <BtnEmpezar onClick={handleModal}>
            <FormattedMessage id="intro.btn.empezar" defaultMessage="Empezar" />{' '}
          </BtnEmpezar>
        </BtnCont>
        <LogosCont>
          <a
            href="https://www.us.es/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={usLogo}
              alt="Logotipo Universidad de Sevilla"
              width="90px"
            />
          </a>
          <a
            href="http://institucional.us.es/arqwellness/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={arqWellnessLogo}
              alt="Logotipo ArqWellness"
              width="90px"
            />
          </a>
          <img src={chronoLightLogo} alt="Logotipo ChronoLight" />
          <a
            href="https://institucional.us.es/uncover/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={unCoverLogo} alt="Logotipo UnCover" />
          </a>
          <a
            href="https://www.colorado.edu/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={coloradoBoulderLogo} alt="Logotipo Colorado Boulder" />
          </a>
          <a
            href="https://www.fundacionvisible.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={fundacionVisibleLogo}
              alt="Logotipo Fundacion Visible"
              width="100px"
            />
          </a>
          <a
            href="http://ventilando.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={ventilandoLogo} alt="Logotipo Ventilando" />
          </a>
          <a
            href="https://www.aireamos.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={aireamosLogo} alt="Logotipo Aireamos" />
          </a>
        </LogosCont>
      </IntroContainer>
      {showModal ? <ModalIntro /> : null}
    </IntroFullSectionContainer>
  );
};

export default Intro;

const IntroFullSectionContainer = styled(FullSectionContainer)`
  background: var(--verydark);
  background: var(--gdverydark);
`;

const IntroContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;

  @media (max-width: ${size.sm}) {
    padding-top: 3rem;
  }
`;

const Title = styled.h1`
  position: relative;
  text-align: center;
  font-size: 40px;
  color: var(--verydark);
  font-family: 'Montserrat', sans-serif;
  max-width: 880px;
  min-width: 222px;
  padding: 1rem;
  margin-bottom: 4rem;
  background: whitesmoke;
  border-radius: 10px;

  @media (max-width: ${size.sm}) {
    font-size: 30px;
  }
`;

const Title2 = styled.h1`
  font-family: 'Montserrat', sans-serif;
  margin: 4vw 0 2rem 0;

  div {
    h1 {
      color: white;
      padding: 0.5rem 1rem;
      min-width: 239.2px;
      font-size: calc(1.2rem + 3vw);

      @media (max-width: ${size.lg}) {
        font-size: 40px;
      }

      @media (max-width: ${size.sm}) {
        font-size: 30px;
      }

      sup {
        color: var(--light);
        font-size: calc(0.6rem + 2vw);
        padding: 0 0.3rem;

        @media (max-width: ${size.lg}) {
          font-size: 20px;
        }

        @media (max-width: ${size.sm}) {
          font-size: 16px;
        }
      }
    }
  }
`;

const LogosCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 0;

  img {
    display: block;
    margin: 0 1rem;
    width: 80px;
  }

  @media (max-width: 850px) {
    max-width: 475px;
  }
`;

const BtnInfo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
  border-radius: 5px;
  font-weight: bold;
  color: var(--verydark);
  width: 200px;
  background: whitesmoke;
  border: 2px solid whitesmoke;

  &:hover {
    background: var(--dark);
    border: 2px solid var(--dark);
    color: white;
  }
`;

const BtnCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  row-gap: 2rem;
  padding: 0 1rem;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`;

const BtnEmpezar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
  border-radius: 5px;
  font-weight: bold;
  color: white;

  background: var(--danger);
  border: 2px solid var(--danger);

  &:hover {
    background: var(--dark);
    border: 2px solid var(--dark);
    color: white;
  }
`;

const BotoneraIdiomas = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;

  .langBtn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.3rem;

    &:last-child {
      margin-right: 1rem;
    }

    &:hover {
      img {
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
      }
    }

    @media (max-width: 355px) {
      padding: 0rem;

      &:last-child {
        margin-right: 1px;
      }
    }
  }
`;
