import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import inputObj from '../../data/inputs';
import {
  AclaracionIndividual,
  Column,
  FullSectionContainer,
  InnerContainer,
  ResponsiveContainer,
  Row,
  SectionSubtitle,
  SectionTitle,
  size,
  truncToPrecision,
} from '../../globalStyles';
import InputRange from '../UI/InputRange';
import InputSelect from '../UI/InputSelect';
import NavigationButtons from '../UI/NavigationButtons';

import superficie from '../../images/superficie.svg';
import actividad from '../../images/actividadRojo.svg';
import mascarilla from '../../images/mascarilla.svg';
import ventilacion from '../../images/ventilacion.svg';
import ProgressBar from '../UI/ProgressBar';
import { FormattedMessage } from 'react-intl';
import FormNavigation from './FormNavigation';

const FormularioSimplificado = (props) => {
  useEffect(() => {
    props.updateDatos(true, 'esSuperficieCalc');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      props.stateData.nivelVentilacionSimplificado ===
      'Inadecuado - Ventanas cerradas'
    ) {
      props.updateDatos(
        truncToPrecision(props.stateData.ventilacionInadecuada, 1),
        'renovacionesAire'
      );
    }
    if (
      props.stateData.nivelVentilacionSimplificado ===
      'Medio - Ventanas entreabiertas'
    ) {
      props.updateDatos(
        truncToPrecision(props.stateData.ventilacionMedia, 1),
        'renovacionesAire'
      );
    }
    if (
      props.stateData.nivelVentilacionSimplificado ===
      'Adecuado - Ventanas abiertas'
    ) {
      props.updateDatos(
        truncToPrecision(props.stateData.ventilacionAdecuada, 1),
        'renovacionesAire'
      );
    }
  }, [
    props.stateData.nivelVentilacionSimplificado,
    props.stateData.ventilacionAdecuada,
    props.stateData.ventilacionMedia,
    props.stateData.ventilacionInadecuada,
  ]);
  return (
    <FullSectionContainer white>
      <FormattedMessage
        id="formsimplif.pagetitle"
        defaultMessage="Formulario Simplificado"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Formulario, en su versión simplificada para calcular el riesgo de infección por COViD-19"
            />
          </Helmet>
        )}
      </FormattedMessage>

      <ResponsiveContainer>
        <FormNavigation goBack={'/'} goNext={'/resultadosimplificado'} />
        <SectionTitle primary>
          <FormattedMessage
            id="formsimplif.title"
            defaultMessage="Parámetros de cálculo"
          />
        </SectionTitle>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="formsimplif.subtitle1"
              defaultMessage="Datos previos considerados"
            />
          </SectionSubtitle>
          <InnerContainer>
            <FormattedMessage
              id="formsimplif.content"
              defaultMessage="<h5>Para realizar este cálculo simplificado, se ha considerado que:<UnorderedList><li>La variante predominante de la COViD-19 es la denominada 'Omicron'.</li><li>Un {indicePobInmune} % de los ocupantes del recinto tienen inmunidad adquirida contra la infección, ya sea por vacunación o por haber pasado ya  la enfermedad, teniendo esta un {indiceInmunidadPersonal} % de efectividad.</li><li>Existe un ocupante infectado en el recinto.</li></UnorderedList></h5>"
              values={{
                h5: (words) => <h5>{words}</h5>,
                UnorderedList: (words) => (
                  <UnorderedList>{words}</UnorderedList>
                ),
                li: (words) => <li>{words}</li>,
                indicePobInmune: props.stateData.indicePobInmune,
                indiceInmunidadPersonal:
                  props.stateData.indiceInmunidadPersonal,
              }}
            />
          </InnerContainer>
        </section>

        <section>
          <Row>
            <Column stackMobile width={50} alignY={'center'}>
              <SectionSubtitle primary>
                <FormattedMessage
                  id="formsimplif.subtitle2"
                  defaultMessage="Dimensiones del recinto"
                />
              </SectionSubtitle>
              <InnerContainer>
                {inputObj
                  .filter(
                    (input) =>
                      input.section === 'Dimensiones del recinto' &&
                      input.tab.includes(1)
                  )
                  .map((input) =>
                    input.type === 'range' ? (
                      <InputRange
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        xstep={input.xstep}
                        precision={input.precision}
                        xmin={input.xmin}
                        xmax={input.xmax}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    ) : (
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    )
                  )}
                <Calculos>
                  <FormattedMessage
                    id="formsimplif.subtitle2.aclaracion"
                    defaultMessage="Volumen: {volumen} m³"
                    values={{ volumen: props.stateData.volumen }}
                  />
                </Calculos>
              </InnerContainer>
            </Column>
            <Column width={50} alignY={'center'} alignX={'center'} hiddenMobile>
              <img
                src={superficie}
                alt="Volumen calculado en aula mediante superficie"
                width="70%"
              />
            </Column>
          </Row>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="formsimplif.subtitle3"
              defaultMessage="Ocupación"
            />
          </SectionSubtitle>
          <InnerContainer>
            {inputObj
              .filter((input) => input.name === 'personas')
              .map((input) => (
                <InputRange
                  key={input.id}
                  uid={input.id}
                  updateDatos={props.updateDatos}
                  name={input.name}
                  label={input.label}
                  xstep={input.xstep}
                  precision={input.precision}
                  xmin={input.xmin}
                  xmax={input.xmax}
                  byDefault={input.byDefault}
                  description={input.description}
                  stateData={props.stateData}
                />
              ))}
          </InnerContainer>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="formsimplif.subtitle4"
              defaultMessage="Actividad realizada, emisión de aerosoles"
            />
          </SectionSubtitle>
          <InnerContainer>
            <ShowImageMobileRow>
              <Column stackMobile width={50}>
                {
                  <InnerContainer>
                    {inputObj
                      .filter(
                        (input) =>
                          input.section === 'Actividad' &&
                          input.tab.includes(0) &&
                          input.name !== 'nivelEmisionViralInfectados'
                      )
                      .map((input) =>
                        input.type === 'range' ? (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ) : (
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        )
                      )}
                  </InnerContainer>
                }
              </Column>
              <Column
                stackMobile
                width={50}
                alignX={'center'}
                alignY={'center'}
              >
                <img
                  src={actividad}
                  alt="personas en actividades que implican los diferentes niveles de actividad posibles"
                />
              </Column>
            </ShowImageMobileRow>
          </InnerContainer>
        </section>

        <section>
          <Row style={{ marginBottom: '2rem' }}>
            <Column stackMobile width={50}>
              <SectionSubtitle primary>
                <FormattedMessage
                  id="formsimplif.subtitle5"
                  defaultMessage="Ventilación: Aporte de aire exterior"
                />
              </SectionSubtitle>
              <InnerContainer>
                {inputObj
                  .filter((input) => input.name === 'esComoExterior')
                  .map((input) => (
                    <InputSelect
                      key={input.id}
                      uid={input.id}
                      updateDatos={props.updateDatos}
                      name={input.name}
                      label={input.label}
                      optionsES={input.optionsES}
                      optionsEN={input.optionsEN}
                      values={input.values}
                      byDefault={input.byDefault}
                      description={input.description}
                      stateData={props.stateData}
                    />
                  ))}
                {Number(props.stateData.esComoExterior) === 1 ? (
                  <AclaracionIndividual>
                    <FormattedMessage
                      id="formsimplif.subtitle5.aclaracion"
                      defaultMessage="Al indicar que se encuentra en un espacio interior asimilable a estar al aire libre, en la versión de cálculo simplificado, no es necesario que indique más parámetros sobre ventilación."
                    />
                  </AclaracionIndividual>
                ) : (
                  inputObj
                    .filter(
                      (input) => input.name === 'nivelVentilacionSimplificado'
                    )
                    .map((input) => (
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    ))
                )}
              </InnerContainer>
            </Column>
            <Column hiddenMobile width={50} alignX={'center'} alignY={'center'}>
              <img
                src={ventilacion}
                alt="Ejemplo de ventilacion en un aula"
                width="70%"
              />
            </Column>
          </Row>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="formsimplif.subtitle6"
              defaultMessage="Equipos de protección individual de los ocupantes"
            />
          </SectionSubtitle>
          <Row>
            <Column stackMobile width={50}>
              <InnerContainer>
                {inputObj
                  .filter((input) => input.id === 30)
                  .map((input) => (
                    <>
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label2}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description2}
                        stateData={props.stateData}
                      />
                      <AclaracionIndividual>
                        <FormattedMessage
                          id="gen.eficienciateorica"
                          defaultMessage="Eficiencia teórica"
                        />
                        : {props.stateData.mascarillaExhEf}%
                      </AclaracionIndividual>
                    </>
                  ))}
                {props.stateData.mascarillaExh !== 'Sin mascarilla' &&
                props.stateData.mascarillaExh !==
                  'Pantallas faciales sin mascarilla'
                  ? inputObj
                      .filter((input) => input.id === 31)
                      .map((input) => (
                        <>
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                          <AclaracionIndividual>
                            <FormattedMessage
                              id="gen.eficienciacorregida"
                              defaultMessage="Eficiencia corregida según ajuste"
                            />
                            : {props.stateData.eficienciaExh}%
                          </AclaracionIndividual>
                        </>
                      ))
                  : null}
                {inputObj
                  .filter((input) => input.id === 32)
                  .map((input) => (
                    <>
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                      <AclaracionIndividual>
                        <FormattedMessage
                          id="gen.eficienciateorica"
                          defaultMessage="Eficiencia teórica"
                        />
                        : {props.stateData.mascarillaInhEf}%
                      </AclaracionIndividual>
                    </>
                  ))}
                {props.stateData.mascarillaInh !== 'Sin mascarilla' &&
                props.stateData.mascarillaInh !==
                  'Pantallas faciales sin mascarilla'
                  ? inputObj
                      .filter((input) => input.id === 33)
                      .map((input) => (
                        <>
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                          <AclaracionIndividual>
                            <FormattedMessage
                              id="gen.eficienciacorregida"
                              defaultMessage="Eficiencia corregida según ajuste"
                            />
                            : {props.stateData.eficienciaInh}%
                          </AclaracionIndividual>
                        </>
                      ))
                  : null}
              </InnerContainer>
            </Column>
            <Column width={50} hiddenMobile alignX={'center'} alignY={'center'}>
              <img
                src={mascarilla}
                alt="Ejemplificacion de aula con personas con mascarillas puestas"
                width="70%"
              />
            </Column>
          </Row>
        </section>

        <ProgressBar progress={100} />
        <Row>
          <Column width={50}>
            <NavigationButtons
              to="/"
              align={'start'}
              back={'grey'}
              textES={'Atrás'}
              textEN={'Back'}
            />
          </Column>
          <Column width={50}>
            <NavigationButtons
              to="/resultadosimplificado"
              resultados
              align={'end'}
              textES={'Resultados'}
              textEN={'Results'}
            />
          </Column>
        </Row>
      </ResponsiveContainer>
    </FullSectionContainer>
  );
};

export default FormularioSimplificado;

const Calculos = styled.h5`
  color: var(--verydark);
  padding: 0.7rem 0;
`;

const UnorderedList = styled.ul`
  li {
    margin: 0.3rem 1rem;
  }
`;

const ShowImageMobileRow = styled(Row)`
  @media (max-width: ${size.hideImage}) {
    flex-direction: column;

    img {
      width: 100%;
      position: relative;
      bottom: 2.7rem;
      align-self: flex-start;
    }
  }
`;
