import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { langContext } from '../../context/langContext';

const Toggle = (props) => {
  const lang = useContext(langContext);

  return (
    <>
      {props.leftActive && (
        <ToggleButton>
          <ButtonsCont>
            <div>
              <FormattedMessage id="gen.aqui" defaultMessage="Está aquí" />
            </div>
            <svg width="10" height="10" fill="currentColor" viewBox="0 0 16 16">
              <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
            <SliderLabel active>
              {lang.locale === 'es' ? props.leftLabelES : props.leftLabelEN}
            </SliderLabel>
          </ButtonsCont>
          <ButtonsCont>
            <SliderLabel
              onClick={props.handleClick}
              className="inactive"
              inactive
            >
              {lang.locale === 'es' ? props.rightLabelES : props.rightLabelEN}
            </SliderLabel>
          </ButtonsCont>
        </ToggleButton>
      )}
      {props.rightActive && (
        <ToggleButton>
          <ButtonsCont>
            <SliderLabel inactive onClick={props.handleClick}>
              {lang.locale === 'es' ? props.leftLabelES : props.leftLabelEN}
            </SliderLabel>
          </ButtonsCont>
          <ButtonsCont>
            <div>
              <FormattedMessage id="gen.aqui" defaultMessage="Está aquí" />
            </div>
            <svg width="10" height="10" fill="currentColor" viewBox="0 0 16 16">
              <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
            <SliderLabel active>
              {lang.locale === 'es' ? props.rightLabelES : props.rightLabelEN}
            </SliderLabel>
          </ButtonsCont>
        </ToggleButton>
      )}
    </>
  );
};

export default Toggle;

const ToggleButton = styled.div`
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  min-width: 210px;
  max-width: 290px;
  background: white;
  margin: 1rem;
  padding: 0.7rem 0.5rem;
  margin-bottom: 0rem;
  border: none;
`;

const ButtonsCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  div {
    font-family: 'Nunito', sans-serif;
    font-size: 0.6rem;
  }
`;

const SliderLabel = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 90px;
  height: 50px;
  font-family: 'Nunito', sans-serif;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: bold;
  padding: 0 0.7rem;
  margin: 0 0.3rem;

  ${(props) =>
    props.inactive &&
    css`
      border: 2px solid var(--verydark);
      background: var(--verydark);
      color: white;

      &:hover {
        background: var(--dark);
      }

      &:hover:active {
        background: var(--light);
        color: var(--verydark);
      }
    `};

  ${(props) =>
    props.active &&
    css`
      color: var(--verydark);
      background: white;
      border: 2px solid var(--dark);
    `};
`;
