import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { langContext } from '../../context/langContext';
import { ReactComponent as LeftNavArrow } from '../../images/leftNavArrow.svg';
import { ReactComponent as RightNavArrow } from '../../images/rightNavArrow.svg';

const NavigationButtons = (props) => {
  const lang = useContext(langContext);

  return (
    <Container align={props.align}>
      {props.forward && (
        <NavigationButtonRight to={props.to}>
          {lang.locale === 'es' ? props.textES : props.textEN}
          <svg width="16" height="16" fill="currentColor">
            <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
          </svg>
        </NavigationButtonRight>
      )}
      {props.back === 'grey' && (
        <NavigationButtonLeft back={'grey'} to={props.to}>
          <svg width="16" height="16" fill="currentColor">
            <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
          {lang.locale === 'es' ? props.textES : props.textEN}
        </NavigationButtonLeft>
      )}
      {props.back === 'red' && (
        <NavigationButtonLeft back={'red'} to={props.to}>
          <svg width="16" height="16" fill="currentColor">
            <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
          </svg>
          {lang.locale === 'es' ? props.textES : props.textEN}
        </NavigationButtonLeft>
      )}
      {props.resultados && (
        <NavigationButtonRight resultados to={props.to}>
          {lang.locale === 'es' ? props.textES : props.textEN}
          <svg width="16" height="16" fill="currentColor">
            <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
          </svg>
        </NavigationButtonRight>
      )}

      {props.resultadosdarkhover && (
        <NavigationButtonRight resultados darkhover to={props.to}>
          {lang.locale === 'es' ? props.textES : props.textEN}
          <svg width="16" height="16" fill="currentColor">
            <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
          </svg>
        </NavigationButtonRight>
      )}

      {props.leftnavigationarrow && (
        <NavigationButtonTransparent
          to={props.to}
          grey={props.grey}
          red={props.red}
          blue={props.blue}
        >
          <LeftNavArrow />
        </NavigationButtonTransparent>
      )}
      {props.rightnavigationarrow && (
        <NavigationButtonTransparent
          to={props.to}
          grey={props.grey}
          red={props.red}
          blue={props.blue}
        >
          <RightNavArrow />
        </NavigationButtonTransparent>
      )}
    </Container>
  );
};

export default NavigationButtons;

const NavigationBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  width: 130px;
  background: var(--danger);
  border: 2px solid var(--danger);
  z-index: 1;

  &:hover {
    background: ${(props) =>
      props.darkhover ? 'var(--verydark)' : 'var(--dark)'};
    border: 2px solid
      ${(props) => (props.darkhover ? 'var(--verydark)' : 'var(--dark)')};
  }

  @media print {
    display: none;
  }
`;

const NavigationButtonRight = styled(NavigationBtn)`
  ${(props) =>
    props.resultados &&
    css`
      background: linear-gradient(90deg, #388e3c, #4caf50);
      border: 2px solid #43a047;
      width: 180px;
    `};

  ${(props) =>
    props.darkhover &&
    css`
      &:hover {
        background: var(--verydark);
        border: 2px solid var(--verydark);
      }
    `};

  svg {
    margin-left: 0.5rem;
  }
`;

const NavigationButtonLeft = styled(NavigationBtn)`
  ${(props) =>
    props.back === 'grey' &&
    css`
      background: LightSlateGray;
      color: whitesmoke;
      border: 2px solid LightSlateGray;
    `}
  ${(props) =>
    props.back === 'red' &&
    css`
      background: var(--danger);
      color: whitesmoke;
      border: 2px solid var(--danger);

      &:hover {
        background: var(--verydark);
        border: 2px solid var(--verydark);
      }
    `}

  svg {
    margin-right: 0.5rem;
  }
`;

const NavigationButtonTransparent = styled(Link)`
  color: var(--very-dark);

  &:hover {
    svg {
      color: var(--dark);
    }
  }

  ${(props) =>
    props.red === true &&
    css`
      svg {
        color: red;
      }
    `}

  ${(props) =>
    props.grey === true &&
    css`
      svg {
        color: grey;
      }
    `}

  ${(props) =>
    props.blue === true &&
    css`
      &:hover {
        svg {
          color: #d4d4d4;
        }
      }
    `}
`;

const Container = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.align === 'start' ? 'flex-start' : null};
  justify-content: ${(props) => props.align === 'center' && 'center'};
  justify-content: ${(props) => props.align === 'end' && 'flex-end'};
  align-items: center;
  margin: 1rem 0.5rem;
`;
