//DATA
import inputObj from './data/inputs.js';
import {
  EFICIENCIA_EXH,
  EFICIENCIA_INH,
  BMR_AVG,
  TASA_METABOLICA,
  COCIENTE_RESPIRATORIO,
  PERDIDA_DEPOSICION,
  TABLA_TRAMOS_EDAD,
  TABLA_NIVEL_ACTIVIDAD,
  TABLA_INHALACION,
  TASA_RESP_RELATIVA,
  TASA_EXH_QUANTA,
  CONCENTRACION_CO2_EXHALACION,
  VENTILACION_POR_OCUPANTE,
  MULTIPLICADOR_VARIANTE,
  LIMITES_HR,
} from './data/constantes.js';
//PAGES
import Header from './components/pages/Header.js';
import Intro from './components/pages/Intro.js';
import ParametrosAmbientales from './components/pages/ParametrosAmbientales.js';
import Actividad from './components/pages/Actividad.js';
import Ventilacion from './components/pages/Ventilacion.js';
import Resultados from './components/pages/Resultados.js';
import Footer from './components/pages/Footer.js';
import Informacion from './components/pages/Informacion.js';
import Ayuda from './components/pages/Ayuda';
import PoliticaCookies from './components/pages/PoliticaCookies';
import FormularioSimplificado from './components/pages/FormularioSimplificado.js';
//CORE
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import Simplificado from './components/pages/Simplificado.js';
import ResultadoSimplificado from './components/pages/ResultadoSimplificado.js';
import CondicionesUso from './components/pages/CondicionesUso';
import { redondear } from './globalStyles.js';
import PageView from './utils/GAPageViews.js';
//UI
import { ButtonToTop } from './components/UI/Buttons.js';

const getInitialState = () => {
  const initialState = {
    esSuperficieCalc: false,
    esPresionCalc: false,
    esAvanzado: false,
    esCalcIA: false,
    esActividadDocente: false,
    mascarillaExhEf: 0,
    mascarillaInhEf: 0,
    eficienciaExh: 0,
    eficienciaInh: 0,
  };
  inputObj.forEach((input) => (initialState[input.name] = input.byDefault));
  return initialState;
};

const App = () => {
  const [datos, setDatos] = useState(getInitialState());

  const updateDatos = (dato, inputName) => {
    setDatos((state) => ({
      ...state,
      [inputName]: dato,
    }));
  };

  const fixedToPrecision = (num, precision) => {
    return Number(num.toFixed(precision));
  };

  //Calculo intermedio: Superficie
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      superficie: fixedToPrecision(Number(datos.largo * datos.ancho), 1),
    }));
  }, [datos.largo, datos.ancho]);

  // Calculo intermedio: Volumen.
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      volumen: datos.esSuperficieCalc
        ? fixedToPrecision(Number(datos.superficie * datos.alto), 1)
        : fixedToPrecision(Number(datos.largo * datos.ancho * datos.alto), 1),
    }));
  }, [
    datos.largo,
    datos.ancho,
    datos.alto,
    datos.superficie,
    datos.esSuperficieCalc,
  ]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      casosIAPorcentaje: datos.casosIA100k / 100000,
    }));
  }, [datos.casosIA100k]);

  // Calculo intermedio: Eficiencia Mascarilla Exhalacion
  useEffect(() => {
    if (datos.mascarillaExh === 'Sin mascarilla') {
      setDatos((state) => ({
        ...state,
        mascarillaExhEf: EFICIENCIA_EXH.SIN,
      }));
      return;
    }
    if (datos.mascarillaExh === 'Pantallas faciales sin mascarilla') {
      setDatos((state) => ({
        ...state,
        mascarillaExhEf: EFICIENCIA_EXH.PANTALLA,
      }));
      return;
    }
    if (datos.mascarillaExh === 'Mascarillas quirúrgicas') {
      setDatos((state) => ({
        ...state,
        mascarillaExhEf: EFICIENCIA_EXH.QUIRURGICA,
      }));
      return;
    }
    if (datos.mascarillaExh === 'FFP2/KN95 con válvula de exhalación') {
      setDatos((state) => ({
        ...state,
        mascarillaExhEf: EFICIENCIA_EXH.FFP2VALV,
      }));
      return;
    }
    if (datos.mascarillaExh === 'FFP2/KN95') {
      setDatos((state) => ({
        ...state,
        mascarillaExhEf: EFICIENCIA_EXH.FFP2,
      }));
      return;
    }
  }, [datos.mascarillaExh]);

  // Calculo intermedio: Eficiencia Mascarilla Inhalacion
  useEffect(() => {
    if (datos.mascarillaInh === 'Sin mascarilla')
      setDatos((state) => ({
        ...state,
        mascarillaInhEf: EFICIENCIA_INH.SIN,
      }));
    if (datos.mascarillaInh === 'Pantallas faciales sin mascarilla')
      setDatos((state) => ({
        ...state,
        mascarillaInhEf: EFICIENCIA_INH.PANTALLA,
      }));
    if (datos.mascarillaInh === 'Mascarillas quirúrgicas')
      setDatos((state) => ({
        ...state,
        mascarillaInhEf: EFICIENCIA_INH.QUIRURGICA,
      }));
    if (datos.mascarillaInh === 'FFP2/KN95 con válvula de exhalación')
      setDatos((state) => ({
        ...state,
        mascarillaInhEf: EFICIENCIA_INH.FFP2VALV,
      }));
    if (datos.mascarillaInh === 'FFP2/KN95')
      setDatos((state) => ({
        ...state,
        mascarillaInhEf: EFICIENCIA_INH.FFP2,
      }));
  }, [datos.mascarillaInh]);

  // Calculo intermedio: Eficienca Exhalacion .
  useEffect(() => {
    if (
      datos.mascarillaExh !== 'Sin mascarilla' &&
      datos.mascarillaExh !== 'Pantallas faciales sin mascarilla'
    ) {
      setDatos((state) => ({
        ...state,

        eficienciaExh: state.mascarillaExhEf * datos.ajusteMascarillaExh,
      }));
    } else {
      setDatos((state) => ({
        ...state,

        eficienciaExh: datos.mascarillaExhEf,
      }));
    }
  }, [datos.mascarillaExhEf, datos.ajusteMascarillaExh, datos.mascarillaExh]);

  // Calculo intermedio: Eficienca Inhalacion .
  useEffect(() => {
    if (
      datos.mascarillaInh !== 'Sin mascarilla' &&
      datos.mascarillaInh !== 'Pantallas faciales sin mascarilla'
    ) {
      setDatos((state) => ({
        ...state,

        eficienciaInh: state.mascarillaInhEf * datos.ajusteMascarillaInh,
      }));
    } else {
      setDatos((state) => ({
        ...state,

        eficienciaInh: datos.mascarillaInhEf,
      }));
    }
  }, [datos.mascarillaInhEf, datos.ajusteMascarillaInh, datos.mascarillaInh]);

  // Calculo intermedio: Tasa metabolica basal (BMR) media de ambos sexos, segun edad.
  useEffect(() => {
    if (datos.edadMedia < 3) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M3 }));
      return;
    }
    if (datos.edadMedia < 6) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M6 }));
      return;
    }
    if (datos.edadMedia < 11) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M11 }));
      return;
    }
    if (datos.edadMedia < 16) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M16 }));
      return;
    }
    if (datos.edadMedia < 21) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M21 }));
      return;
    }
    if (datos.edadMedia < 31) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M31 }));
      return;
    }
    if (datos.edadMedia < 41) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M41 }));
      return;
    }
    if (datos.edadMedia < 51) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M51 }));
      return;
    }
    if (datos.edadMedia < 61) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M61 }));
      return;
    }
    if (datos.edadMedia < 71) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M71 }));
      return;
    }
    if (datos.edadMedia < 81) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M31 }));
      return;
    }
    if (datos.edadMedia < 121) {
      setDatos((state) => ({ ...state, bmr: BMR_AVG.M31 }));
      return;
    }
  }, [datos.edadMedia]);

  // Calculo intermedio: Actividad teniendo en cuenta si docencia está seleccionada
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      actividadInfecciosoDefinitiva: datos.esActividadDocente
        ? Number(datos.actividadInfecciosoDocencia) *
          Number(datos.nivelEmisionViralInfectados)
        : Number(datos.actividadInfeccioso) *
          Number(datos.nivelEmisionViralInfectados),
    }));
  }, [
    datos.actividadInfeccioso,
    datos.actividadInfecciosoDocencia,
    datos.esActividadDocente,
    datos.nivelEmisionViralInfectados,
  ]);

  // Calculo intermedio: Tasa Metabolica según actividad.
  useEffect(() => {
    if (datos.tipoActividad === 'Sedentario') {
      setDatos((state) => ({
        ...state,
        tasaMetabAct: TASA_METABOLICA.SEDENTARIO,
      }));
      return;
    }
    if (datos.tipoActividad === 'Intensidad ligera') {
      setDatos((state) => ({ ...state, tasaMetabAct: TASA_METABOLICA.LIGERA }));
      return;
    }
    if (datos.tipoActividad === 'Intensidad moderada') {
      setDatos((state) => ({
        ...state,
        tasaMetabAct: TASA_METABOLICA.MODERADA,
      }));
      return;
    }
    if (datos.tipoActividad === 'Intensidad alta') {
      setDatos((state) => ({ ...state, tasaMetabAct: TASA_METABOLICA.ALTA }));
      return;
    }
    if (datos.tipoActividad === 'Dormido') {
      setDatos((state) => ({
        ...state,
        tasaMetabAct: TASA_METABOLICA.DORMIDO,
      }));
      return;
    }
  }, [datos.tipoActividad]);

  //Calculo intermedio: Tasa emision CO2 una persona
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tasaEmisionUnaPersona:
        COCIENTE_RESPIRATORIO * datos.tasaMetabAct * datos.bmr * 0.000569,
    }));
  }, [datos.tasaMetabAct, datos.bmr]);

  //Calculo intermedio : Presion Definitiva tras eleccion usuario
  useEffect(() => {
    if (datos.esPresionCalc) {
      setDatos((state) => ({
        ...state,
        presionDefinitiva: Math.pow(
          1 - 0.0000225577 * datos.alturaNivelMar,
          5.2559
        ),
      }));
    } else {
      setDatos((state) => ({
        ...state,
        presionDefinitiva: datos.presionAtmosferica,
      }));
    }
  }, [datos.esPresionCalc, datos.presionAtmosferica, datos.alturaNivelMar]);

  // Calculo intermedio: Renovaciones Aire Definitivo segun eleccion de usuario.
  useEffect(() => {
    if (Number(datos.esComoExterior) === 1) {
      setDatos((state) => ({
        ...state,
        renovacionesAireDefinitivo: 500,
      }));
    } else {
      setDatos((state) => ({
        ...state,
        renovacionesAireDefinitivo:
          datos.renovacionesAire === 0 ? 0.01 : datos.renovacionesAire,
      }));
    }
  }, [
    datos.renovacionesAire,
    datos.esVentilacionCalcCO2,
    datos.esComoExterior,
  ]);

  // Calculo intermedio: Tasa emision CO2 general
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tasaEmisionTodos:
        datos.tasaEmisionUnaPersona *
        datos.personas *
        (1 / datos.presionDefinitiva) *
        ((273.15 + datos.temperatura) / 273.15),
    }));
  }, [
    datos.tasaEmisionUnaPersona,
    datos.personas,
    datos.presionDefinitiva,
    datos.temperatura,
  ]);

  // Calculo intermedio: PPM a mostrar en calculadora sección ventilacion (ppmPromedio)
  // prettier-ignore
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      promedioPPM: fixedToPrecision(
        (datos.tasaEmisionTodos*3.6/(datos.renovacionesAireDefinitivo*datos.volumen)*
        (1-(1/(datos.renovacionesAireDefinitivo*(datos.duracion/60)))*
        (1-Math.exp(-datos.renovacionesAireDefinitivo*(datos.duracion/60)))))*
        1000000+datos.co2Exterior
        ,0)
    }));
  }, [
    datos.tasaEmisionTodos,
    datos.renovacionesAireDefinitivo,
    datos.volumen,
    datos.co2Exterior,
    datos.duracion,
  ]);

  // Calculo intermedio: Humedad relativa ajustada a min 20 y max 70
  useEffect(() => {
    if (datos.humedadRelativa < 20) {
      setDatos((state) => ({
        ...state,
        humedadRelativaMinMax: 20,
      }));
      return;
    }
    if (datos.humedadRelativa > 70) {
      setDatos((state) => ({
        ...state,
        humedadRelativaMinMax: 70,
      }));
      return;
    }
    setDatos((state) => ({
      ...state,
      humedadRelativaMinMax: datos.humedadRelativa,
    }));
  }, [datos.humedadRelativa]);

  // Calculo final: Tasa de descomposición de infectividad
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      tasaDescomposicion: fixedToPrecision((7.56923714795655+1.41125518824508*(datos.temperatura-20.54)/10.66 + 0.02175703466389*(datos.humedadRelativaMinMax-45.235)/28.665+7.55272292970083*((datos.indiceUV*0.185)-50) / 50 + (datos.temperatura-20.54)/10.66*(datos.indiceUV*0.185-50)/50*1.3973422174602)*60, 2)
    }))
  }, [datos.temperatura, datos.humedadRelativaMinMax, datos.indiceUV])

  // Calculo final: Tasa de deposicion en superficies
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tasaDeposicion: PERDIDA_DEPOSICION[datos.atmosfera][datos.mobiliario],
    }));
  }, [datos.atmosfera, datos.mobiliario]);

  // Calculo final: Caudal de Aire recirculado por el sistema HVAC (ACH).
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      caudalSaneado:
        ((datos.caudalCADR / datos.volumen) *
          Math.min(
            100,
            datos.filtroMERV + datos.filtroHEPA + datos.germicidaUV
          )) /
        100,
    }));
  }, [
    datos.caudalCADR,
    datos.volumen,
    datos.filtroHEPA,
    datos.filtroMERV,
    datos.germicidaUV,
  ]);

  // Calculo intermedio: Personas susceptibles a enfermar. Tras tener en cuenta efectos de las vacunas.
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      personasSusceptiblesEnfermar:
        (datos.personas -
          (datos.esCalcIA
            ? datos.casosIAPorcentaje * datos.personas
            : datos.infectivas)) *
        (1 -
          (datos.indicePobInmune / 100) *
            (datos.indiceInmunidadPersonal / 100)),
    }));
  }, [
    datos.personas,
    datos.infectivas,
    datos.indicePobInmune,
    datos.indiceInmunidadPersonal,
    datos.casosIAPorcentaje,
    datos.esCalcIA,
  ]);

  // Calculo final: Caudal de ventilacion por ocupante
  // prettier-ignore
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      renovacionesAireDefinitivoOcupante:
        ((datos.renovacionesAireDefinitivo * datos.volumen) /
        3.6) 
        / datos.personas,
    }));
  }, [datos.renovacionesAireDefinitivo, datos.personas, datos.volumen]);

  // Calculo intermedio: Tasa perdida de primer orden
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tasaPerdidaPrimerOrden:
        datos.renovacionesAireDefinitivo +
        datos.tasaDescomposicion +
        datos.tasaDeposicion +
        datos.caudalSaneado,
    }));
  }, [
    datos.renovacionesAireDefinitivo,
    datos.tasaDescomposicion,
    datos.tasaDeposicion,
    datos.caudalSaneado,
  ]);

  // Funciones para extraer indices para buscar en TABLA INH

  const valorTablaInh = useCallback((edad, actividad) => {
    const indiceTablaInhPrimer = (edad) => {
      for (let i = 0; i < TABLA_TRAMOS_EDAD.length; i++) {
        if (edad < TABLA_TRAMOS_EDAD[i]) return i;
      }
    };

    const indiceTablaInhSegundo = (actividad) => {
      for (let i = 0; i < TABLA_NIVEL_ACTIVIDAD.length; i++) {
        if (actividad === TABLA_NIVEL_ACTIVIDAD[i]) return i;
      }
    };

    const y = indiceTablaInhPrimer(edad);
    const x = indiceTablaInhSegundo(actividad);
    return TABLA_INHALACION[y][x] * 60;
  }, []);

  // Funcion para calcular valor ppm en cualquier momento dado (Grafica)
  const ppmT = useCallback(
    (t) => {
      return (
        datos.paramA0 *
          Math.exp(
            (-datos.ventilacionMetrosCubSeg * (t * 60)) / datos.volumen
          ) +
        datos.paramQ
      );
    },
    [datos.paramA0, datos.ventilacionMetrosCubSeg, datos.volumen, datos.paramQ]
  );

  // Calculo intermedio: Tasa de respiracion
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tasaRespiracion: valorTablaInh(datos.edadMedia, datos.tipoActividad),
    }));
  }, [datos.edadMedia, datos.tipoActividad, valorTablaInh]);

  // Calculo final: Tasa de emision Neta
  // prettier-ignore
  useEffect(() => {
    if (datos.esCalcIA === true) {
      setDatos((state) => ({
        ...state,
        tasaEmisionNeta:
          datos.actividadInfecciosoDefinitiva *
          (1 -
            (datos.eficienciaExh / 100) * (datos.porcentajeExhConMasc / 100)) *
          (datos.personas * datos.casosIAPorcentaje) *
          datos.multiplicadorVariante,
      }));
    }
    if (datos.esCalcIA === false) {
      setDatos((state) => ({
        ...state,
        tasaEmisionNeta: datos.actividadInfecciosoDefinitiva*(1-(datos.eficienciaExh/100)*(datos.porcentajeExhConMasc/100))*datos.infectivas*datos.multiplicadorVariante
      }));
    }
  }, [
    datos.actividadInfecciosoDefinitiva,
    datos.eficienciaExh,
    datos.porcentajeExhConMasc,
    datos.infectivas,
    datos.multiplicadorVariante,
    datos.esCalcIA,
    datos.personas,
    datos.casosIAPorcentaje,
  ]);

  // Calculo final: Concentracion promedio
  // prettier-ignore
  useEffect(() => {
  setDatos((state) => ({
    ...state,
    concentracionPromedio:
    datos.tasaEmisionNeta/datos.tasaPerdidaPrimerOrden/datos.volumen*(1-(1/datos.tasaPerdidaPrimerOrden/(datos.duracion/60))*(1-Math.exp(-datos.tasaPerdidaPrimerOrden*(datos.duracion/60))))
  }));
}, [
  datos.duracion, datos.tasaEmisionNeta, datos.tasaPerdidaPrimerOrden, datos.volumen
]);

  // Calculo final: Quanta inhalada por persona
  //prettier-ignore
  useEffect(() => {
    setDatos(state => ({
      ...state,
      quantaPersona: datos.concentracionPromedio*datos.tasaRespiracion*(datos.duracion/60)*(1-(datos.eficienciaInh/100)*(datos.porcentajeInhConMasc/100))
    }))
    
  }, [datos.concentracionPromedio, datos.tasaRespiracion, datos.duracion, datos.eficienciaInh, datos.porcentajeInhConMasc])

  // Calculo final: Probabilidad de infeccion por individuo
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      probInfeccionPersona: 1 - Math.exp(-datos.quantaPersona),
    }));
  }, [datos.quantaPersona]);

  // Calculo final: Casos de covid resultantes
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      casosResultantes:
        datos.probInfeccionPersona * datos.personasSusceptiblesEnfermar,
    }));
  }, [datos.probInfeccionPersona, datos.personasSusceptiblesEnfermar]);

  // Calculo final: Factor de la tasa de respiracion relativa
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      factorTasaRespRel: datos.tasaRespiracion / TASA_RESP_RELATIVA,
    }));
  }, [datos.tasaRespiracion]);

  // Calculo final: Factor de exhalacion quanta relativo
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      factorExhQuantaRel:
        datos.actividadInfecciosoDefinitiva /
        (TASA_EXH_QUANTA * Number(datos.nivelEmisionViralInfectados)),
    }));
  }, [datos.actividadInfecciosoDefinitiva, datos.nivelEmisionViralInfectados]);

  // Calculo final: Parametro de riesgo de infeccion
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      riesgoInfeccion: datos.factorTasaRespRel*datos.factorExhQuantaRel*(1-(datos.eficienciaExh/100)*(datos.porcentajeExhConMasc/100))*(1-(datos.eficienciaInh/100)*(datos.porcentajeInhConMasc/100))*(datos.duracion/60)*datos.personasSusceptiblesEnfermar/(datos.tasaPerdidaPrimerOrden*datos.volumen)*(1-(1-Math.exp(-datos.tasaPerdidaPrimerOrden*(datos.duracion/60)))/(datos.tasaPerdidaPrimerOrden*(datos.duracion/60)))
    }))
  }, [datos.eficienciaExh, datos.porcentajeExhConMasc, datos.eficienciaInh, datos.porcentajeInhConMasc, datos.duracion, datos.personasSusceptiblesEnfermar, datos.tasaPerdidaPrimerOrden, datos.volumen, datos.factorExhQuantaRel, datos.factorTasaRespRel])

  // Calculo final: Parametro de riesgo de infeccion relativo
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      riesgoInfeccionRelativo:  datos.factorTasaRespRel*datos.factorExhQuantaRel*(1-(datos.eficienciaExh/100)*(datos.porcentajeExhConMasc/100))*(1-(datos.eficienciaInh/100)*(datos.porcentajeInhConMasc/100))*(datos.duracion/60)/(datos.tasaPerdidaPrimerOrden*datos.volumen)*(1-(1-Math.exp(-datos.tasaPerdidaPrimerOrden*(datos.duracion/60)))/(datos.tasaPerdidaPrimerOrden*(datos.duracion/60)))
    }))
  }, [datos.eficienciaExh, datos.porcentajeExhConMasc, datos.eficienciaInh, datos.porcentajeInhConMasc, datos.duracion, datos.tasaPerdidaPrimerOrden, datos.volumen, datos.factorExhQuantaRel, datos.factorTasaRespRel])

  // Calculo final: Concentracion media de CO2
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2ConcentracionMedia: (datos.co2ProporcionMediaMezcla-datos.co2Exterior)*40.9/1000000*44*298/(273.15+datos.temperatura)*datos.presionDefinitiva/1,
    }))
  }, [datos.co2ProporcionMediaMezcla, datos.co2Exterior, datos.temperatura, datos.presionDefinitiva])

  // Calculo final: Proporcion media de mezcla de CO2
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2ProporcionMediaMezcla: (datos.tasaEmisionTodos*3.6/(datos.renovacionesAireDefinitivo*datos.volumen)*(1-(1/(datos.renovacionesAireDefinitivo*(datos.duracion/60)))*(1-Math.exp(-datos.renovacionesAireDefinitivo*(datos.duracion/60)))))*1000000+datos.co2Exterior
    }))
  }, [datos.tasaEmisionTodos, datos.renovacionesAireDefinitivo, datos.co2Exterior, datos.duracion, datos.volumen])

  // Calculo final: CO2 exhalado re-inhalado por persona (g)
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2ReinhaladoPersonaG: datos.co2ConcentracionMedia*datos.tasaRespiracion*(datos.duracion/60)
    }))
  }, [datos.co2ConcentracionMedia, datos.tasaRespiracion, datos.duracion])

  // Calculo final: CO2 exhalado re-inhalado por persona (ppm h)
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2ReinhaladoPersonaPPM: (datos.co2ProporcionMediaMezcla-datos.co2Exterior)*(datos.duracion/60)
    }))
  }, [datos.co2ProporcionMediaMezcla, datos.co2Exterior, datos.duracion])

  // Calculo final: CO2 exhalado re-inhalado por persona (%co2 h)
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2ReinhaladoPersonaCO2: datos.co2ReinhaladoPersonaPPM/1000000
    }))
  }, [datos.co2ReinhaladoPersonaPPM])

  // Calculo final: Probabilidad de infeccion por CO2 exhalado
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2ProbInfeccion: datos.probInfeccionPersona/datos.co2ReinhaladoPersonaCO2
    }))
  }, [datos.probInfeccionPersona, datos.co2ReinhaladoPersonaCO2])

  // Calculo final: Fraccion de aire reinhalado
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      aireReinhalado: (datos.co2ProporcionMediaMezcla-datos.co2Exterior)/CONCENTRACION_CO2_EXHALACION
    }))
  }, [datos.co2ProporcionMediaMezcla, datos.co2Exterior])

  // Calculo final: CO2 que inhalar en una hora para un 1% de prob de infeccion
  // prettier-ignore
  useEffect(() => {
    setDatos( state => ({
      ...state,
      co2Hora1PorCiento: (datos.co2ProporcionMediaMezcla-datos.co2Exterior)*(datos.duracion/60)*0.01/datos.probInfeccionPersona+datos.co2Exterior 
    }))
  }, [datos.co2ProporcionMediaMezcla, datos.co2Exterior, datos.duracion, datos.probInfeccionPersona])

  // Calculo Densidades ocupacion
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      densidadSup: datos.superficie / datos.personas,
    }));
  }, [datos.superficie, datos.personas]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      densidadVol: datos.volumen / datos.personas,
    }));
  }, [datos.volumen, datos.personas]);

  //Calculos Ecuaciones co2 por instante

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      ventilacionMetrosCubSeg:
        (datos.volumen * datos.renovacionesAireDefinitivo) / 3600,
    }));
  }, [datos.volumen, datos.renovacionesAireDefinitivo]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      prodCO2MetrosCubSeg:
        (datos.tasaEmisionUnaPersona *
          (1 / datos.presionDefinitiva) *
          ((273.15 + datos.temperatura) / 273.15) *
          datos.personas) /
        1000,
    }));
  }, [
    datos.tasaEmisionUnaPersona,
    datos.presionDefinitiva,
    datos.temperatura,
    datos.personas,
  ]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      prodCO2KgsSeg: datos.prodCO2MetrosCubSeg * 1.799,
    }));
  }, [datos.prodCO2MetrosCubSeg]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramQ:
        (datos.prodCO2KgsSeg +
          (1.2 * datos.ventilacionMetrosCubSeg * datos.co2Exterior) / 1000000) /
        (1.2 * datos.ventilacionMetrosCubSeg),
    }));
  }, [datos.prodCO2KgsSeg, datos.ventilacionMetrosCubSeg, datos.co2Exterior]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramC0: datos.co2Exterior / 1000000,
    }));
  }, [datos.co2Exterior]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramA0: datos.paramC0 - datos.paramQ,
    }));
  }, [datos.paramC0, datos.paramQ]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramCDuracion: ppmT(datos.duracion),
    }));
  }, [datos.duracion, ppmT]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      co2IntDuracion: 1000000 * datos.paramCDuracion,
    }));
  }, [datos.paramCDuracion]);

  // Calculos para grafica: Necesarios para ppmTHVAC
  useEffect(() => {
    setDatos((state) => ({
      ...state,
      renovacionesAireDefinitivoHVAC:
        datos.renovacionesAireDefinitivo + datos.caudalSaneado,
    }));
  }, [datos.renovacionesAireDefinitivo, datos.caudalSaneado]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      ventilacionMetrosCubSegHVAC:
        (datos.volumen * datos.renovacionesAireDefinitivoHVAC) / 3600,
    }));
  }, [datos.volumen, datos.renovacionesAireDefinitivoHVAC]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramQHVAC:
        (datos.prodCO2KgsSeg +
          (1.2 * datos.ventilacionMetrosCubSegHVAC * datos.co2Exterior) /
            1000000) /
        (1.2 * datos.ventilacionMetrosCubSegHVAC),
    }));
  }, [
    datos.prodCO2KgsSeg,
    datos.ventilacionMetrosCubSegHVAC,
    datos.co2Exterior,
  ]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramC0HVAC: datos.co2Exterior / 1000000,
    }));
  }, [datos.co2Exterior]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      paramA0HVAC: datos.paramC0HVAC - datos.paramQHVAC,
    }));
  }, [datos.paramC0HVAC, datos.paramQHVAC]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      tasaAtaque: datos.casosResultantes / datos.personasSusceptiblesEnfermar,
    }));
  }, [datos.personasSusceptiblesEnfermar, datos.casosResultantes]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      ventilacionAdecuada: Math.max(
        (VENTILACION_POR_OCUPANTE.HARVARD * datos.personas * 3.6) /
          datos.volumen,
        0.3
      ),
      ventilacionMedia: Math.max(
        (VENTILACION_POR_OCUPANTE.MEDIA * datos.personas * 3.6) / datos.volumen,
        0.3
      ),
      ventilacionInadecuada: Math.max(
        (VENTILACION_POR_OCUPANTE.INADECUADA * datos.personas * 3.6) /
          datos.volumen,
        0.3
      ),
    }));
  }, [datos.volumen, datos.personas]);

  useEffect(() => {
    const getMultiplicadorVariante = (variante) => {
      switch (variante) {
        case 'Original':
          return MULTIPLICADOR_VARIANTE.ORIGINAL;
        case 'Alpha':
          return MULTIPLICADOR_VARIANTE.ALPHA;
        case 'Beta':
          return MULTIPLICADOR_VARIANTE.BETA;
        case 'Gamma':
          return MULTIPLICADOR_VARIANTE.GAMMA;
        case 'Delta':
          return MULTIPLICADOR_VARIANTE.DELTA;
        case 'Omicron BA.1':
          return MULTIPLICADOR_VARIANTE.OMICROM_BA1;
        case 'Omicron BA.2':
          return MULTIPLICADOR_VARIANTE.OMICROM_BA2;
        default:
          console.log('There was an error retrieving variant multiplier.');
      }
    };

    setDatos((state) => ({
      ...state,
      multiplicadorVariante: getMultiplicadorVariante(datos.variante),
    }));
  }, [datos.variante]);

  useEffect(() => {
    const getLimiteHrInf = () => {
      switch (datos.variante) {
        case 'Original':
          return LIMITES_HR.ORIGINAL.INFERIOR;
        case 'Alpha':
          return LIMITES_HR.ALPHA.INFERIOR;
        case 'Beta':
          return LIMITES_HR.BETA.INFERIOR;
        case 'Gamma':
          return LIMITES_HR.GAMMA.INFERIOR;
        case 'Delta':
          return LIMITES_HR.DELTA.INFERIOR;
        case 'Omicron BA.1':
          return LIMITES_HR.OMICROM_BA1.INFERIOR;
        case 'Omicron BA.2':
          return LIMITES_HR.OMICROM_BA2.INFERIOR;
        default:
          console.log('There was an error retrieving Hr limits.');
      }
    };

    const getLimiteHrSup = () => {
      switch (datos.variante) {
        case 'Original':
          return LIMITES_HR.ORIGINAL.SUPERIOR;
        case 'Alpha':
          return LIMITES_HR.ALPHA.SUPERIOR;
        case 'Beta':
          return LIMITES_HR.BETA.SUPERIOR;
        case 'Gamma':
          return LIMITES_HR.GAMMA.SUPERIOR;
        case 'Delta':
          return LIMITES_HR.DELTA.SUPERIOR;
        case 'Omicron BA.1':
          return LIMITES_HR.OMICROM_BA1.SUPERIOR;
        case 'Omicron BA.2':
          return LIMITES_HR.OMICROM_BA2.SUPERIOR;
        default:
          console.log('There was an error retrieving Hr limits.');
      }
    };

    setDatos((state) => ({
      ...state,
      limiteHrInf: getLimiteHrInf(datos.variante),
      limiteHrSup: getLimiteHrSup(datos.variante),
    }));
  }, [datos.variante]);

  useEffect(() => {
    setDatos((state) => ({
      ...state,
      limiteHInf: 50 * datos.limiteHrInf,
      limiteHSup: 50 * datos.limiteHrSup,
    }));
  }, [datos.limiteHrInf, datos.limiteHrSup]);

  useEffect(() => {
    const getTADpoint = (tasaAtaquePuntual) => {
      const tasaAtaquePuntCorr = tasaAtaquePuntual / 100;
      if (tasaAtaquePuntCorr === 0) return 0;
      if (tasaAtaquePuntCorr >= 1) return 100;
      return redondear(
        (1 -
          Math.exp(
            -(datos.multiplicadorVariante * -Math.log(1 - tasaAtaquePuntCorr))
          )) *
          100,
        1
      );
    };

    setDatos((state) => ({
      ...state,
      dataGraphTA: [
        {
          TA: 0,
          HR: 0.0001,
          TAD: getTADpoint(0),
        },
        {
          TA: 0.18,
          HR: 0.0004,
          TAD: getTADpoint(0.18),
        },
        {
          TA: 0.48,
          HR: 0.0009,
          TAD: getTADpoint(0.48),
        },
        {
          TA: 0.72,
          HR: 0.0013,
          TAD: getTADpoint(0.72),
        },
        {
          TA: 1.1,
          HR: 0.0019,
          TAD: getTADpoint(1.1),
        },
        {
          TA: 1.5,
          HR: 0.0029,
          TAD: getTADpoint(1.5),
        },
        {
          TA: 2.4,
          HR: 0.0044,
          TAD: getTADpoint(2.4),
        },
        {
          TA: 3.5,
          HR: 0.0067,
          TAD: getTADpoint(3.5),
        },
        {
          TA: 5.4,
          HR: 0.0102,
          TAD: getTADpoint(5.4),
        },
        {
          TA: 6,
          HR: 0.0115,
          TAD: getTADpoint(6),
        },
        {
          TA: 6.5,
          HR: 0.0125,
          TAD: getTADpoint(6.5),
        },
        {
          TA: 8.8,
          HR: 0.0171,
          TAD: getTADpoint(8.8),
        },
        {
          TA: 10,
          HR: 0.0196,
          TAD: getTADpoint(10),
        },
        {
          TA: 11.8,
          HR: 0.0235,
          TAD: getTADpoint(11.8),
        },
        {
          TA: 15.8,
          HR: 0.0321,
          TAD: getTADpoint(15.8),
        },
        {
          TA: 19.2,
          HR: 0.0396,
          TAD: getTADpoint(19.2),
        },
        {
          TA: 26,
          HR: 0.0562,
          TAD: getTADpoint(26),
        },
        {
          TA: 33,
          HR: 0.0746,
          TAD: getTADpoint(33),
        },
        {
          TA: 40.8,
          HR: 0.0983,
          TAD: getTADpoint(40.8),
        },
        {
          TA: 45,
          HR: 0.111,
          TAD: getTADpoint(45),
        },
        {
          TA: 51.3,
          HR: 0.1345,
          TAD: getTADpoint(51.3),
        },
        {
          TA: 59.9,
          HR: 0.1707,
          TAD: getTADpoint(59.9),
        },
        {
          TA: 66.2,
          HR: 0.2026,
          TAD: getTADpoint(66.2),
        },
        {
          TA: 74.5,
          HR: 0.2558,
          TAD: getTADpoint(74.5),
        },
        {
          TA: 82.7,
          HR: 0.3292,
          TAD: getTADpoint(82.7),
        },
        {
          TA: 88.8,
          HR: 0.4097,
          TAD: getTADpoint(88.8),
        },
        {
          TA: 94.4,
          HR: 0.5374,
          TAD: getTADpoint(94.4),
        },
        {
          TA: 98.7,
          HR: 0.817,
          TAD: getTADpoint(98.7),
        },
        {
          TA: 100,
          HR: 1.2419,
          TAD: getTADpoint(100),
        },
        {
          TA: 100,
          HR: 5,
          TAD: getTADpoint(100),
        },
      ],
    }));
  }, [datos.multiplicadorVariante]);
  return (
    <Router>
      <PageView />
      <Switch>
        <Route path="/actividad">
          <Home>
            <Header />
            <Actividad stateData={datos} updateDatos={updateDatos} />
            <Footer />
          </Home>
        </Route>
        <Route path="/ayuda">
          <Home>
            <Header />
            <Ayuda />
            <Footer />
          </Home>
        </Route>
        <Route exact path="/condicionesuso">
          <Home>
            <Header />
            <CondicionesUso />
            <Footer />
          </Home>
        </Route>
        <Route path="/formulariosimplificado">
          <Home>
            <Header />
            <FormularioSimplificado
              stateData={datos}
              updateDatos={updateDatos}
            />
            <Footer />
          </Home>
        </Route>
        <Route path="/informacion">
          <Home>
            <Header />
            <Informacion />
            <Footer />
          </Home>
        </Route>
        <Route path="/parametrosambientales">
          <Home>
            <Header />
            <ParametrosAmbientales
              stateData={datos}
              updateDatos={updateDatos}
            />
            <Footer />
          </Home>
        </Route>
        <Route path="/politicacookies">
          <Home>
            <Header />
            <PoliticaCookies />
            <Footer />
          </Home>
        </Route>
        <Route path="/resultadosimplificado">
          <Home>
            <Header />
            <ResultadoSimplificado
              stateData={datos}
              updateDatos={updateDatos}
            />
            <Footer />
          </Home>
        </Route>
        <Route path="/resultados">
          <Home>
            <Header />
            <Resultados stateData={datos} updateDatos={updateDatos} />
            <Footer />
            <ButtonToTop fontSize="1.8rem" />
          </Home>
        </Route>
        <Route path="/simplificado">
          <Home>
            <Header />
            <Simplificado stateData={datos} />
            <Footer />
          </Home>
        </Route>
        <Route path="/ventilacion">
          <Home>
            <Header />
            <Ventilacion stateData={datos} updateDatos={updateDatos} />
            <Footer />
          </Home>
        </Route>
        <Route exact path="/">
          <Home>
            <Intro />
          </Home>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;

const Home = styled.section`
  height: 100vh;
  background: var(--gdverydark);
`;
