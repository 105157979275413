import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Column,
  FullSectionContainer,
  ResponsiveContainer,
  Row,
  SectionSubtitle,
  SectionTitle,
} from '../../globalStyles';
import BackButton from '../UI/BackHistoryBtn';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const CondicionesUso = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <InfoPage white>
      <FormattedMessage
        id="conduso.pagetitle"
        defaultMessage="Condiciones de uso"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Condiciones de uso de la aplicación web para la estimación del riesgo de transmisión aerea de COViD-19"
            />
          </Helmet>
        )}
      </FormattedMessage>
      <Helmet>
        <title>Condiciones de uso</title>
        <meta name="description" content="" />
      </Helmet>
      <ResponsiveContainer>
        <SectionTitle primary>
          <FormattedMessage
            id="conduso.title"
            defaultMessage="Condiciones de uso"
          />
        </SectionTitle>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="conduso.subtitle1"
              defaultMessage="ACEPTACIÓN DE LAS CONDICIONES DE USO"
            />
          </SectionSubtitle>
          <FormattedMessage
            id="conduso.subtitle1.content"
            defaultMessage="<p>Los usuarios deberán leer las presentes Condiciones Generales de Uso de esta herramienta.</p><p>El uso de la misma implica el conocimiento y plena aceptación de las advertencias legales y condiciones vigentes en cada momento en que el usuario acceda al mismo y que a continuación se especifican.</p>"
            values={{
              p: (words) => <p>{words}</p>,
            }}
          />
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="conduso.subtitle2"
              defaultMessage="CONDICIONES DE ACCESO Y UTILIZACIÓN"
            />
          </SectionSubtitle>
          <FormattedMessage
            id="conduso.subtitle2.content"
            defaultMessage="<strong>Gratuidad del acceso</strong><p>La utilización de esta herramienta de la UNIVERSIDAD DE SEVILLA posee carácter gratuito. No obstante, la contratación de algunos de los servicios que ofrece este sitio web puede estar, actualmente o en el futuro, condicionada al pago de un precio, en cuyo caso se indicará convenientemente.</p><strong>Registro del usuario</strong><p>Con carácter general, el acceso y utilización de este sitio web no exige la previa inscripción o registro de los usuarios.</p>"
            values={{
              p: (words) => <p>{words}</p>,
              strong: (words) => <strong>{words}</strong>,
            }}
          />
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="conduso.subtitle3"
              defaultMessage="PROPIEDAD INDUSTRIAL E INTELECTUAL"
            />
          </SectionSubtitle>
          <FormattedMessage
            id="conduso.subtitle3.content1"
            defaultMessage="prueba"
            values={{
              p: (words) => <p>{words}</p>,
              strong: (words) => <strong>{words}</strong>,
            }}
          />
          <FormattedMessage
            id="conduso.subtitle3.content2"
            defaultMessage="<p>Asimismo, los centros educativos no universitarios, museos, bibliotecas, fonotecas, filmotecas, hemerotecas o archivos, de titularidad pública o integradas en instituciones de carácter cultural o científico, quedan exentos de requerir la autorización de los autores y la UNIVERSIDAD DE SEVILLA por la reproducción y uso del material contenido y generado, cuando aquella se realice sin finalidad lucrativa y exclusivamente para fines educativos y de investigación.</p><p>Cualquier otra utilización requerirá la autorización expresa y por escrito de los autores y de la UNIVERSIDAD DE SEVILLA.</p><p>La herramienta contiene una <strong>metodología de cálculo cuyos derechos de autor pertenecen a terceros</strong>, apareciendo convenientemente citados.</p><strong>Reserva de acciones</strong><p>El usuario de esta herramienta se compromete a respetar los derechos enunciados y evitar cualquier actuación que pudiera perjudicarlos. En consonancia con lo hasta ahora reseñado, la <strong>utilización no autorizada de los derechos de propiedad industrial e intelectual</strong> titularidad de los autores y de la UNIVERSIDAD DE SEVILLA y relacionados o contenidos en esta herramienta, así como cualquier vulneración de la pacífica posesión y titularidad de los antedichos derechos <strong>será perseguida</strong> con los medios que la legislación, tanto española como internacional, prevén. En este sentido, LA UNIVERSIDAD DE SEVILLA deja hecha expresa reserva del ejercicio de cuantas acciones, tanto civiles como penales, amparen sus legítimos derechos de propiedad intelectual e industrial.</p>"
            values={{
              p: (words) => <p>{words}</p>,
              strong: (words) => <strong>{words}</strong>,
            }}
          />
          <FormattedMessage
            id="conduso.subtitle3.content3"
            defaultMessage="<strong>Enlaces externos</strong><p>En esta herramienta de la Universidad DE SEVILLA, el usuario podrá encontrar diversos enlaces que le conducirán a páginas web independientes de ésta. Su única finalidad es la de facilitar el acceso a otras fuentes de información en Internet utilizadas como fuente metodológica y su inserción en este sitio web está inspirada en el respeto de los derechos de propiedad intelectual que, en su caso, puedan corresponder a sus autores.</p><p>Asimismo, el establecimiento de enlaces a esta herramienta de la Universidad DE SEVILLA deberá respetar los derechos de propiedadintelectual y/o industrial titularidad de la UNIVERSIDAD DE SEVILLA reseñados en estas Condiciones Generales.</p>"
            values={{
              p: (words) => <p>{words}</p>,
              strong: (words) => <strong>{words}</strong>,
            }}
          />
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="conduso.subtitle4"
              defaultMessage="EXENCIÓN DE RESPONSABILIDAD"
            />
          </SectionSubtitle>
          <FormattedMessage
            id="conduso.subtitle4.content1"
            defaultMessage="<strong>Por los contenidos</strong>"
            values={{
              strong: (words) => <strong>{words}</strong>,
            }}
          />
          <ul>
            <FormattedMessage
              id="conduso.subtitle4.content1.list1"
              defaultMessage="<li><u>Informaciones</u>: las informaciones, tanto propias como de terceros, que aparecen en esta herramienta se proporcionan 'como están', <strong>sin garantías de ningún tipo en cuanto a su exactitud y actualización</strong>. En su consecuencia, los autores y la UNIVERSIDAD DE SEVILLA no se hacen responsables de los posibles perjuicios que la utilización de las mismas pudieran causar. Asimismo, los autores y la UNIVERSIDAD DE SEVILLA<strong> no se hacen responsables de la licitud del contenido suministrado por los proveedores, usuarios y otros terceros</strong> a través de cualquier manera de utilización de esta herramienta. En cualquier caso, los autores y la UNIVERSIDAD DE SEVILLA se comprometen a actuar con diligencia para evitar la existencia en su herramienta de contenidos ilícitos y, en caso de que tomen conocimiento efectivo de estos contenidos, eliminarlos o impedir el acceso a los mismos.</li>"
              values={{
                p: (words) => <p>{words}</p>,
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
                strong: (words) => <strong>{words}</strong>,
              }}
            />
            <FormattedMessage
              id="conduso.subtitle4.content1.list2"
              defaultMessage="<li><u>Errores</u>: en relación con lo mencionado en el párrafo anterior, los documentos publicados podrían contener inconsistencias técnicas o errores tipográficos involuntarios, de los cuales los autores y la UNIVERSIDAD DE SEVILLA no se hacen responsable, pero se compromete a subsanarlos, con la mayor brevedad posible, desde el momento en que tenga conocimiento de los mismos.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
              }}
            />
            <FormattedMessage
              id="conduso.subtitle4.content1.list3"
              defaultMessage="<li><u>Hiperenlaces</u>: los enlaces, con o sin contenido publicitario, existentes en esta herramienta permiten al usuario dejar la herramienta para acceder a otros sitios web. Los sitios enlazados no están relacionados, salvo indicación en contrario, con la UNIVERSIDAD DE SEVILLA, quien no se hace responsable de los contenidos a los que se acceda en virtud de los mencionados enlaces, ni de las modificaciones que se lleven a cabo en los mismos, ni del uso que de aquellos se realice, ni de la disponibilidad técnica de los mismos. En cualquier caso, los autores y la UNIVERSIDAD DE SEVILLA se comprometen a hacer lo posible por evitar la existencia en esta herramienta de enlaces a sitios de contenido ilegal, que promuevan actividades ilícitas y, en general, susceptibles de atentar contra los principios de libertad y de dignidad humana o vulneren los valores y derechos reconocidos por la Constitución española y por la Declaración Universal de los Derechos Humanos. Asimismo, en caso de que tome conocimiento de la existencia de los antedichos enlaces a sitios de contenido ilegal, los autores y la UNIVERSIDAD DE SEVILLA se comprometen a actuar con diligencia para suprimirlos de forma inmediata.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
              }}
            />
            <FormattedMessage
              id="conduso.subtitle4.content1.list4"
              defaultMessage="<li><u>Modificación de contenidos</u>: la información, la presentación y los servicios que ofrece esta herramienta pueden ser sometidos a cambios periódicos o puntuales, susceptibles de ser efectuados libremente por los autores y la UNIVERSIDAD DE SEVILLA sin que estén obligados a comunicarlo a los usuarios.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
              }}
            />
          </ul>
          <FormattedMessage
            id="conduso.subtitle4.content2"
            defaultMessage="<strong>Por el funcionamiento del sitio</strong>"
            values={{
              strong: (words) => <strong>{words}</strong>,
            }}
          />

          <ul>
            <FormattedMessage
              id="conduso.subtitle4.content2.list1"
              defaultMessage="<li><u>Privacidad</u>: los autores y la UNIVERSIDAD DE SEVILLA no garantizan privacidad absoluta en la utilización de esta herramienta, ya que no debe descartarse la posibilidad de que terceros no autorizados puedan tener conocimiento de la misma y de las circunstancias en que se realiza.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
              }}
            />

            <FormattedMessage
              id="conduso.subtitle4.content2.list2"
              defaultMessage="<li><u>Virus</u>: los autores y la UNIVERSIDAD DE SEVILLA no aceptan ninguna responsabilidad por los posibles daños causados por virus informáticos, cuya ausencia no queda garantizada.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
              }}
            />

            <FormattedMessage
              id="conduso.subtitle4.content2.list3"
              defaultMessage="<li><u>Disfunciones técnicas</u>: los autores y la UNIVERSIDAD DE SEVILLA quedan eximidos de cualquier responsabilidad derivada del mal funcionamiento de la herramienta o de cualquiera de sus servicios que tenga su origen en una circunstancia accidental, fuerza mayor, trabajos necesarios de mantenimiento o cualquier otra causa no imputable a la misma.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
              }}
            />

            <FormattedMessage
              id="conduso.subtitle4.content2.list4"
              defaultMessage="<li><u>Cookies</u>: Los autores y la UNIVERSIDAD DE SEVILLA ponen en conocimiento de los usuarios que podría tener instalados 'cookies' en sus sistemas. Esto se describe en el siguiente apartado <Link>(acceda aquí)</Link>.</li>"
              values={{
                u: (words) => <u>{words}</u>,
                li: (words) => <li>{words}</li>,
                Link: (words) => <Link to="/politicacookies">{words}</Link>,
              }}
            />
          </ul>
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="conduso.subtitle5"
              defaultMessage="TERMINACIÓN"
            />
          </SectionSubtitle>
          <FormattedMessage
            id="conduso.subtitle5.content"
            defaultMessage="<p>Si bien, en principio, la duración de esta herramienta es indeterminada, los autores y la UNIVERSIDAD DE SEVILLA se reservan el derecho a suspender o dar por terminada la prestación de algunos o todos sus servicios, sin que esta decisión deba ser comunicada con antelación a los usuarios del mismo.</p>"
            values={{
              p: (words) => <p>{words}</p>,
            }}
          />
        </section>

        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="conduso.subtitle6"
              defaultMessage="LEY Y JURISDICCIÓN APLICABLE"
            />
          </SectionSubtitle>
          <FormattedMessage
            id="conduso.subtitle6.content"
            defaultMessage="<p>Las presentes Condiciones Generales de Uso se rigen por las Leyes españolas. Cualquier controversia en relación con la herramienta desarrollada por los autores y la Universidad DE SEVILLA se sustanciará ante la jurisdicción española, sometiéndose las partes a los Juzgados y Tribunales de la ciudad de Sevilla, y sus superiores jerárquicos, con expresa renuncia a sus fueros si lo tuvieren y fueran diferentes de los reseñados.</p>"
            values={{
              p: (words) => <p>{words}</p>,
            }}
          />
        </section>

        <Row>
          <Column width={50}>
            <BackButton>
              <FormattedMessage id="gen.btn.atras" defaultMessage="Atrás" />
            </BackButton>
          </Column>
          <Column width={50}></Column>
        </Row>
      </ResponsiveContainer>
    </InfoPage>
  );
};

export default CondicionesUso;

const InfoPage = styled(FullSectionContainer)`
  ul {
    text-align: justify;
    margin: 0 0 0.6rem 0;
  }

  li {
    text-align: justify;
    margin: 0 0 0.3rem 1rem;
  }

  p {
    text-align: justify;
    margin: 0 0 0.7rem 0;
  }

  section {
    margin-bottom: 4rem;
  }

  a {
    &:link {
      color: var(--dark);
    }
    &:visited {
      color: var(--dark);
    }
    &:hover {
      color: var(--danger);
    }
    &:focus {
      color: var(--danger);
    }
  }

  p + strong {
    margin-top: 1.5rem;
    display: block;
    margin-bottom: 0.3rem;
  }

  ul + strong {
    margin-top: 1.5rem;
  }

  strong + ul {
    margin-top: 0.7rem;
  }

  h3 + strong {
    display: block;
    margin-bottom: 0.3rem;
  }
`;
