import React from 'react';
import styled from 'styled-components';
import { Column, Row } from '../../globalStyles';

const CardSuma = (props) => {
  const truncToPrecisionZeros = (num, precision) => {
    return (
      Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision)
    ).toFixed(precision);
  };

  return (
    <Container>
      <Row stackMobile style={{ columnGap: '3rem', rowGap: '2rem' }}>
        <Column stackMobile width={45} alignY={'center'} alignX={'center'}>
          <LabelCont>
            <Label>{props.etiqueta}</Label>
          </LabelCont>
          <Description>{props.description}</Description>
        </Column>
        <Column stackMobile width={55} alignY={'center'} alignX={'end'}>
          <List style={{ margin: '1.5rem 0 2.5rem 0' }}>
            <ListTitle>{props.listTitle}</ListTitle>
            {props.list.map((elem) => (
              <>
                <ListLabel>
                  <p>{elem[0]}</p>
                </ListLabel>

                <ListDimension>
                  {truncToPrecisionZeros(elem[1], elem[3])}
                </ListDimension>

                <ListUnit>{elem[2]}</ListUnit>
              </>
            ))}
          </List>
          <List resultados>
            <ListTitle>{props.resultadosTitle}</ListTitle>
            {props.resultados.map((elem) => (
              <>
                <ListLabel>
                  <p>{elem[0]}</p>
                </ListLabel>

                <ListDimension>
                  {truncToPrecisionZeros(elem[1], elem[3])}
                </ListDimension>

                <ListUnit>
                  <p>{elem[2]}</p>
                </ListUnit>
              </>
            ))}
          </List>
          {props.comment &&
            props.comment.map((elem) => <Comments>{elem}</Comments>)}
        </Column>
      </Row>
    </Container>
  );
};

export default CardSuma;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97.5%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 2rem 1rem;
`;

const LabelCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark);
  border-radius: 5px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 107px;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const Label = styled.h5`
  font-size: 1.1rem;
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-align: center;
`;

const Description = styled.p`
  color: var(--verydark);
  line-height: 1.2;
  text-align: justify;
  max-width: 390px;
  font-size: 1rem;
  font-weight: bold;
  p {
    margin-bottom: 1rem;
  }

  ul {
    li {
      padding-left: 1rem;
      list-style: none;
    }
  }
`;

const List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 2rem auto;
  grid-template-areas: 'label dim unit';
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  border: 1px solid var(--verydark);
  margin-bottom: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  color: #5e5e5e;
  color: ${(props) => props.resultados && 'var(--verydark)'};
`;

const ListTitle = styled.h4`
  position: absolute;
  top: -24px;
  left: 5px;
  font-size: 0.7rem;
  font-family: 'Montserrat', sans-serif;
  color: var(--verydark);
  text-transform: uppercase;
`;

const ListLabel = styled.h5`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.2;
  padding: 0 0.5rem 0 0;
  grid-area: 'label';
`;

const ListDimension = styled.p`
  grid-area: 'dim';
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Comments = styled.em`
  font-size: 0.7rem;
`;

const ListUnit = styled.p`
  grid-area: 'unit';
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
