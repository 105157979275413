import {
  AJUSTE_MASCARILLA,
  EFICIENCIA_FILTRADO,
  RATIO_QUANTA,
  CARGA_VIRAL_EMITIDA,
} from './constantes.js';
import { FormattedMessage } from 'react-intl';

const inputObj = [
  {
    id: 27,
    section: 'SARS-CoV-2',
    type: 'select',
    name: 'variante',
    label: <FormattedMessage id="input27.label" defaultMessage="Variante:" />,
    optionsES: [
      'Original',
      'Alpha',
      'Beta',
      'Gamma',
      'Delta',
      'Omicron BA.1',
      'Omicron BA.2',
    ],
    optionsEN: [
      'Original',
      'Alpha',
      'Beta',
      'Gamma',
      'Delta',
      'Omicron BA.1',
      'Omicron BA.2',
    ],
    values: [
      'Original',
      'Alpha',
      'Beta',
      'Gamma',
      'Delta',
      'Omicron BA.1',
      'Omicron BA.2',
    ],
    byDefault: 'Omicron BA.2',
    description: '',
  },
  {
    id: 1,
    section: 'Dimensiones del recinto',
    tab: [0],
    type: 'range',
    name: 'largo',
    label: (
      <FormattedMessage id="input1.label" defaultMessage="Largo (m), b:" />
    ),
    xstep: 0.1,
    precision: 1,
    xmin: 1,
    xmax: 40,
    byDefault: 8,
    description: '',
  },
  {
    id: 2,
    section: 'Dimensiones del recinto',
    tab: [0],
    type: 'range',
    name: 'ancho',
    label: (
      <FormattedMessage id="input2.label" defaultMessage="Ancho (m), a:" />
    ),
    xstep: 0.1,
    precision: 1,
    xmin: 1,
    xmax: 40,
    byDefault: 6,
    description: '',
  },
  {
    id: 3,
    section: 'Dimensiones del recinto',
    tab: [1],
    type: 'range',
    name: 'superficie',
    label: (
      <FormattedMessage
        id="input3.label"
        defaultMessage="Superficie (m<sup>2</sup>), s:']"
        values={{ sup: (words) => <sup>{words}</sup> }}
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 1,
    xmax: 1600,
    byDefault: 48,
    description: '',
  },
  {
    id: 4,
    section: 'Dimensiones del recinto',
    tab: [0, 1],
    type: 'range',
    name: 'alto',
    label: <FormattedMessage id="input4.label" defaultMessage="Alto (m), h:" />,
    xstep: 0.1,
    precision: 1,
    xmin: 1,
    xmax: 10,
    byDefault: 3,
    description: '',
  },
  {
    id: 5,
    section: 'Condiciones ambientales',
    tab: [0, 1],
    type: 'range',
    name: 'temperatura',
    label: (
      <FormattedMessage
        id="input5.label"
        defaultMessage="Temperatura del aire interior(ºC):"
      />
    ),
    xstep: 0.1,
    precision: 1,
    xmin: 0,
    xmax: 40,
    byDefault: 21,
    description: '',
  },
  {
    id: 6,
    section: 'Condiciones ambientales',
    tab: [0, 1],
    type: 'range',
    name: 'humedadRelativa',
    label: (
      <FormattedMessage
        id="input6.label"
        defaultMessage="Humedad relativa del aire interior (%):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 100,
    byDefault: 40,
    description: '',
  },
  {
    id: 7,
    section: 'Condiciones ambientales',
    tab: [0, 1],
    type: 'range',
    name: 'co2Exterior',
    label: (
      <FormattedMessage
        id="input7.label"
        defaultMessage="Nivel de CO<sub>2</sub> exterior (ppm):"
        values={{ sub: (words) => <sub>{words}</sub> }}
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 400,
    xmax: 600,
    byDefault: 420,
    description: '',
  },
  {
    id: 8,
    section: 'Condiciones ambientales',
    tab: [0],
    type: 'range',
    name: 'presionAtmosferica',
    label: (
      <FormattedMessage
        id="input8.label"
        defaultMessage="Presion atmosférica (atm):"
      />
    ),
    xstep: 0.01,
    precision: 2,
    xmin: 0.9,
    xmax: 1.3,
    byDefault: 1,
    description: '',
  },
  {
    id: 9,
    section: 'Condiciones ambientales',
    tab: [1],
    type: 'range',
    name: 'alturaNivelMar',
    label: (
      <FormattedMessage
        id="input9.label"
        defaultMessage="Altura sobre nivel del mar (m):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: -500,
    xmax: 5000,
    byDefault: 0,
    description: '',
  },
  {
    id: 10,
    section: 'Condiciones ambientales',
    tab: [0, 1],
    type: 'select',
    name: 'esComoExterior',
    label: (
      <FormattedMessage
        id="input10.label"
        defaultMessage="¿Espacio asimilable al aire libre?:"
      />
    ),
    optionsES: ['No', 'Sí'],
    optionsEN: ['No', 'Yes'],
    values: [0, 1],
    byDefault: 0,
    description: (
      <FormattedMessage
        id="input10.description"
        defaultMessage="* Se considera como tal, aquel espacio completamente abierto por tres de sus laterales."
      />
    ),
  },

  {
    id: 12,
    section: 'Reduccion de infectividad',
    type: 'range',
    name: 'indiceUV',
    label: (
      <FormattedMessage
        id="input12.label"
        defaultMessage="Indice de exposición UV:"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 10,
    byDefault: 0,
    description: (
      <FormattedMessage
        id="input12.description"
        defaultMessage="* Valor derivado de la incidencia a luz ultravioleta (UV) por exposición directa al Sol. 0 para recintos interiores (por defecto), de 1 a 10 en exteriores (de atardecer muy nublado a mediodía completamente soleado)."
      />
    ),
  },
  {
    id: 13,
    section: 'Reduccion de infectividad',
    type: 'select',
    name: 'atmosfera',
    label: (
      <FormattedMessage
        id="input13.label"
        defaultMessage="Atmósfera interior:"
      />
    ),
    optionsES: [
      'Aire en reposo / AC apagado',
      'Movimiento de aire no perceptible',
      'Movimiento de aire algo perceptible',
      'Movimiento de aire claramente perceptible',
    ],
    optionsEN: [
      'Still air / HVAC off',
      'Imperceptible air circulation',
      'Somewhat perceptible air circulation',
      'Clearly perceptible air circulation',
    ],
    values: [0, 1, 2, 3],
    byDefault: 0,
    description: '',
  },
  {
    id: 14,
    section: 'Reduccion de infectividad',
    type: 'select',
    name: 'mobiliario',
    label: (
      <FormattedMessage
        id="input14.label"
        defaultMessage="Mobiliario en el centro del espacio:"
      />
    ),
    optionsES: [
      'Diáfano / Mobiliario en perímetro',
      'Espacio central con mobiliario',
    ],
    optionsEN: [
      'None / Furnishings around the perimeter ',
      'Furnishings in the middle',
    ],
    values: [0, 1],
    byDefault: 0,
    description: '',
  },
  {
    id: 15,
    section: 'Retirada de aerosoles',
    type: 'range',
    name: 'caudalCADR',
    label: (
      <FormattedMessage
        id="input15.label"
        defaultMessage="Caudal recirculado / CADR (m<sup>3</sup>/h):"
        values={{ sup: (words) => <sup>{words}</sup> }}
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 25000,
    byDefault: 0,
    description: (
      <FormattedMessage
        id="input15.description"
        defaultMessage="* Se toma la eficiencia del filtro para retener partículas de entre 1.0 y 3.0 μm. Según normas UNE-EN ISO 16890-1,2,3,4:2017."
      />
    ),
  },
  {
    id: 16,
    section: 'Retirada de aerosoles',
    type: 'select',
    name: 'filtroMERV',
    label: (
      <FormattedMessage
        id="input16.label"
        defaultMessage="Filtro MERV en el equipo:"
      />
    ),
    optionsES: [
      'Ninguno',
      'MERV 08',
      'MERV 09',
      'MERV 10',
      'MERV 11',
      'MERV 12',
      'MERV 13',
      'MERV 14',
      'MERV 15',
      'MERV 16',
    ],
    optionsEN: [
      'None',
      'MERV 08',
      'MERV 09',
      'MERV 10',
      'MERV 11',
      'MERV 12',
      'MERV 13',
      'MERV 14',
      'MERV 15',
      'MERV 16',
    ],
    values: [
      0,
      EFICIENCIA_FILTRADO.MERV08,
      EFICIENCIA_FILTRADO.MERV09,
      EFICIENCIA_FILTRADO.MERV10,
      EFICIENCIA_FILTRADO.MERV11,
      EFICIENCIA_FILTRADO.MERV12,
      EFICIENCIA_FILTRADO.MERV13,
      EFICIENCIA_FILTRADO.MERV14,
      EFICIENCIA_FILTRADO.MERV15,
      EFICIENCIA_FILTRADO.MERV16,
    ],
    byDefault: 0,
    description: (
      <FormattedMessage
        id="input16.description"
        defaultMessage="* Se toma la eficiencia del filtro para retener partículas de entre 1.0 y 3.0 μm. Según normas UNE-EN ISO 16890-1,2,3,4:2017."
      />
    ),
  },
  {
    id: 17,
    section: 'Retirada de aerosoles',
    type: 'select',
    name: 'filtroHEPA',
    label: (
      <FormattedMessage
        id="input17.label"
        defaultMessage="Filtro HEPA en el equipo:"
      />
    ),
    optionsES: ['Ninguno', 'HEPA 13', 'HEPA 14'],
    optionsEN: ['None', 'HEPA 13', 'HEPA 14'],
    values: [0, EFICIENCIA_FILTRADO.HEPA13, EFICIENCIA_FILTRADO.HEPA14],
    byDefault: 0,
    description: (
      <FormattedMessage
        id="input17.description"
        defaultMessage="* Se toma la eficiencia del filtro para retener partículas de entre 1.0 y 3.0 μm. Según norma UNE-EN 1822-1:2020."
      />
    ),
  },
  {
    id: 18,
    section: 'Retirada de aerosoles',
    type: 'range',
    name: 'germicidaUV',
    label: (
      <FormattedMessage
        id="input18.label"
        defaultMessage="Germicida UV en el equipo (%):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 100,
    byDefault: 0,
    description: (
      <FormattedMessage
        id="input18.description"
        defaultMessage="* De existir sistema de esterilización por irradiación ultravioleta (UV) en el equipo/conductos, especifique porcentaje de eficacia germicida según fabricante."
      />
    ),
  },

  {
    id: 19,
    section: 'Ocupacion',
    tab: [0, 1],
    type: 'range',
    name: 'personas',
    label: (
      <FormattedMessage
        id="input19.label"
        defaultMessage="Personas presentes:"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 2,
    xmax: 1000,
    byDefault: 2,
    description: '',
  },
  {
    id: 23,
    section: 'Ocupacion',
    tab: [0, 1],
    type: 'range',
    name: 'edadMedia',
    label: (
      <FormattedMessage
        id="input23.label"
        defaultMessage="Edad media ocupantes (años):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 2,
    xmax: 120,
    byDefault: 41,
    description: (
      <FormattedMessage
        id="input23.description"
        defaultMessage="* Si se desconoce, se recomienda utilizar un valor entre 41 y 50 años."
      />
    ),
  },
  {
    id: 20,
    section: 'Ocupacion',
    tab: [10],
    type: 'range',
    name: 'infectivas',
    label: (
      <FormattedMessage
        id="input20.label"
        defaultMessage="Personas infectivas:"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 1,
    xmax: 1000,
    byDefault: 1,
    description: (
      <FormattedMessage
        id="input20.description"
        defaultMessage="* Si introduce más personas infectivas que personas presentes, se considerará que todas las personas presentes son infectivas menos una."
      />
    ),
  },

  {
    id: 37,
    section: 'Ocupacion',
    tab: [10],
    type: 'classic',
    name: 'casosIA100k',
    label: (
      <FormattedMessage
        id="input37.label"
        defaultMessage="Incidencia acumulada (casos / 100.000 habitantes):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 1,
    xmax: 99999,
    byDefault: 50,
    description: (
      <FormattedMessage
        id="input37.description"
        defaultMessage="* Introduzca la incidencia acumulada, preferiblemente a 7 días, en la zona geográfica que quiera considerar."
      />
    ),
  },
  {
    id: 21,
    section: 'Ocupacion',
    tab: [0, 1],
    type: 'range',
    name: 'indicePobInmune',
    label: (
      <FormattedMessage
        id="input21.label"
        defaultMessage="Población inmune en el recinto (%):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 100,
    byDefault: 85,
    description: (
      <FormattedMessage
        id="input21.description"
        defaultMessage="* Mediante vacunación o por pasar enfermedad. Valor actualizable según localidad y época. Pulse más abajo sobre '¿Qué valores de inmunidad debo introducir?' para más información."
      />
    ),
  },
  {
    id: 22,
    section: 'Ocupacion',
    tab: [0, 1],
    type: 'range',
    name: 'indiceInmunidadPersonal',
    label: (
      <FormattedMessage
        id="input22.label"
        defaultMessage="Inmunidad adquirida (%):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 100,
    byDefault: 40,
    description: (
      <FormattedMessage
        id="input22.description"
        defaultMessage="* Porcentaje promedio de inmunidad que tienen las personas frente a la enfermedad proporcionado por las vacunas (varía según enfermedad y variante). Pulse más abajo sobre '¿Qué valores de inmunidad debo introducir? para más información."
      />
    ),
  },

  {
    id: 24,
    section: 'Actividad',
    tab: [0, 1],
    type: 'select',
    name: 'tipoActividad',
    label: (
      <FormattedMessage
        id="input24.label"
        defaultMessage="Nivel de actividad de las personas vulnerables:"
      />
    ),
    optionsES: [
      'Dormido',
      'Sedentario',
      'Intensidad ligera',
      'Intensidad moderada',
      'Intensidad alta',
    ],
    optionsEN: [
      'Sleeping',
      'Sedentary',
      'Light activity',
      'Moderate activity',
      'High Activity',
    ],
    values: [
      'Dormido',
      'Sedentario',
      'Intensidad ligera',
      'Intensidad moderada',
      'Intensidad alta',
    ],
    byDefault: 'Sedentario',
    description: '',
  },
  {
    id: 25,
    section: 'Actividad',
    tab: [0],
    type: 'select',
    name: 'actividadInfeccioso',
    label: (
      <FormattedMessage
        id="input25.label"
        defaultMessage="Nivel de actividad del sujeto infeccioso:"
      />
    ),
    optionsES: [
      'Descansando - Respiracion oral',
      'Descansando - Hablando',
      'Descansando - Hablando fuerte',
      'En pie - Respiracion oral',
      'En pie - Hablando',
      'En pie - Hablando fuerte',
      'Ejercicio Ligero - Respiracion oral',
      'Ejercicio Ligero - Hablando',
      'Ejercicio Ligero - Hablando fuerte',
      'Ejercicio Intenso - Respiracion oral',
      'Ejercicio Intenso - Hablando',
      'Ejercicio Intenso - Hablando fuerte',
    ],
    optionsEN: [
      'At rest - Oral respiration',
      'At rest - Speaking',
      'At rest - Shouting',
      'Standing - Oral respiration',
      'Standing - Speaking',
      'Standing - Shouting',
      'Light exercise - Oral respiration',
      'Light exercise - Speaking',
      'Light exercise - Shouting',
      'Intense exercise - Oral respiration',
      'Intense exercise - Speaking',
      'Intense exercise - Shouting',
    ],
    values: [
      RATIO_QUANTA.DESCANSANDO.RESPIRA,
      RATIO_QUANTA.DESCANSANDO.HABLA,
      RATIO_QUANTA.DESCANSANDO.HABLAFUERTE,
      RATIO_QUANTA.PIE.RESPIRA,
      RATIO_QUANTA.PIE.HABLA,
      RATIO_QUANTA.PIE.HABLAFUERTE,
      RATIO_QUANTA.EJLIGERO.RESPIRA,
      RATIO_QUANTA.EJLIGERO.HABLA,
      RATIO_QUANTA.EJLIGERO.HABLAFUERTE,
      RATIO_QUANTA.EJINTENSO.RESPIRA,
      RATIO_QUANTA.EJINTENSO.HABLA,
      RATIO_QUANTA.EJINTENSO.HABLAFUERTE,
    ],
    byDefault: RATIO_QUANTA.DESCANSANDO.RESPIRA,
    description: '',
  },
  {
    id: 26,
    section: 'Actividad',
    tab: [1],
    type: 'select',
    name: 'actividadInfecciosoDocencia',
    label: (
      <FormattedMessage
        id="input26.label"
        defaultMessage="Nivel de actividad del sujeto infeccioso:"
      />
    ),
    optionsES: [
      'Docente en clase en pie - Respiracion oral',
      'Docente en clase en pie - Hablando',
      'Docente en clase en pie - Hablando fuerte',
      'Alumno en clase sentado - Respiracion oral',
      'Alumno en clase sentado - Hablando',
      'Alumno en clase sentado - Hablando fuerte',
    ],
    optionsEN: [
      'Teacher standing - Oral respiration',
      'Teacher standing - Speaking',
      'Teacher standing - Shouting',
      'Student sitting - Oral respiration',
      'Student sitting - Speaking',
      'Student sitting - Shouting',
    ],
    values: [
      RATIO_QUANTA.DOCENTEPIE.RESPIRA,
      RATIO_QUANTA.DOCENTEPIE.HABLA,
      RATIO_QUANTA.DOCENTEPIE.HABLAFUERTE,
      RATIO_QUANTA.ALUMNOSENTADO.RESPIRA,
      RATIO_QUANTA.ALUMNOSENTADO.HABLA,
      RATIO_QUANTA.ALUMNOSENTADO.HABLAFUERTE,
    ],
    byDefault: RATIO_QUANTA.ALUMNOSENTADO.RESPIRA,
    description: '',
  },
  {
    id: 38,
    section: 'Actividad',
    tab: [0, 1],
    type: 'select',
    name: 'nivelEmisionViralInfectados',
    label: (
      <FormattedMessage
        id="input38.label"
        defaultMessage="Nivel de la emisión viral de los infectados"
      />
    ),
    optionsES: ['Baja', 'Alta', 'Muy alta'],
    optionsEN: ['Low', 'High', 'Very high'],
    values: [
      CARGA_VIRAL_EMITIDA.P50,
      CARGA_VIRAL_EMITIDA.P75,
      CARGA_VIRAL_EMITIDA.P85,
    ],
    byDefault: CARGA_VIRAL_EMITIDA.P85,
    description: (
      <FormattedMessage
        id="input38.description"
        defaultMessage="* <a>Percentil de exhalación vírica del 50/70/85%</a> (se recomienda tomar un nivel de emisión de carga vírica 'muy alta' en el cálculo)."
        values={{
          a: (words) => (
            <a
              href="https://pubs.acs.org/doi/suppl/10.1021/acs.est.1c06531/suppl_file/es1c06531_si_001.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className="anchor"
            >
              {words}
            </a>
          ),
        }}
      />
    ),
  },
  {
    id: 11,
    section: 'Actividad',
    tab: [0, 1],
    type: 'range',
    name: 'duracion',
    label: (
      <FormattedMessage
        id="input11.label"
        defaultMessage="Duración del evento (min):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 1,
    xmax: 480,
    byDefault: 60,
    description: (
      <FormattedMessage
        id="input11.description"
        defaultMessage="* Se ha de tener en cuenta que los eventos de mayor duración pueden tener descansos intermedios, especialmente si el nivel de actividad es elevado. Así, pueden producirse aperturas de puertas por la entrada y salida de ocupantes durante estos descansos, contribuyendo de forma complementaria a diluir parcialmente la atmósfera interior."
      />
    ),
  },
  {
    id: 28,
    section: 'Ventilacion',
    tab: [0],
    type: 'range',
    name: 'renovacionesAire',
    label: (
      <FormattedMessage
        id="input28.label"
        defaultMessage="Caudal de aire exterior (ACH):"
      />
    ),
    xstep: 0.1,
    precision: 1,
    xmin: 0,
    xmax: 60,
    byDefault: 5,
    description: '',
  },
  {
    id: 30,
    section: 'Proteccion',
    type: 'select',
    name: 'mascarillaExh',
    label: (
      <FormattedMessage
        id="input30.label"
        defaultMessage="Tipo de mascarilla de sujetos infecciosos (Exhalación):"
      />
    ),
    label2: (
      <FormattedMessage
        id="input30.label2"
        defaultMessage="Tipo de mascarilla del sujeto infeccioso (Exhalación):"
      />
    ),
    optionsES: [
      'Sin mascarilla',
      'Pantallas faciales sin mascarilla',
      'Mascarillas quirúrgicas',
      'FFP2/KN95 con válvula de exhalación',
      'FFP2/KN95',
    ],
    optionsEN: [
      'No face mask',
      'Face shields worn without mask',
      'Surgical masks',
      'N95/FFP2 masks with an exhalation valve',
      'N95/FFP2 masks',
    ],
    values: [
      'Sin mascarilla',
      'Pantallas faciales sin mascarilla',
      'Mascarillas quirúrgicas',
      'FFP2/KN95 con válvula de exhalación',
      'FFP2/KN95',
    ],
    byDefault: 'Sin mascarilla',
    description: (
      <FormattedMessage
        id="input30.description"
        defaultMessage="* Se debe escoger un tipo de mascarilla para todos los sujetos susceptibles de poder infectar por exhalación de carga vírica."
      />
    ),
    description2: (
      <FormattedMessage
        id="input30.description2"
        defaultMessage="* Se debe escoger un tipo de mascarilla para el sujeto susceptible de poder infectar por exhalación de carga vírica."
      />
    ),
  },
  {
    id: 31,
    section: 'Proteccion',
    type: 'select',
    name: 'ajusteMascarillaExh',
    label: (
      <FormattedMessage
        id="input31.label"
        defaultMessage="Ajuste de mascarilla:"
      />
    ),
    optionsES: ['Poco ajustado', 'Bien ajustado'],
    optionsEN: ['Non fitted', 'Fitted'],
    values: [AJUSTE_MASCARILLA.MALO, AJUSTE_MASCARILLA.BUENO],
    byDefault: 0.5,
    description: (
      <FormattedMessage
        id="input31.description"
        defaultMessage="* Las mascarillas quirúrgicas/higiénicas solo pueden estar bien ajustadas mediante accesorio especial."
      />
    ),
  },
  {
    id: 32,
    section: 'Proteccion',
    type: 'select',
    name: 'mascarillaInh',
    label: (
      <FormattedMessage
        id="input32.label"
        defaultMessage="Tipo de mascarilla de sujetos vulnerables (Inhalación):"
      />
    ),
    optionsES: [
      'Sin mascarilla',
      'Pantallas faciales sin mascarilla',
      'Mascarillas quirúrgicas',
      'FFP2/KN95 con válvula de exhalación',
      'FFP2/KN95',
    ],
    optionsEN: [
      'No face mask',
      'Face shields worn without mask',
      'Surgical masks',
      'N95/FFP2 masks with an exhalation valve',
      'N95/FFP2 masks',
    ],
    values: [
      'Sin mascarilla',
      'Pantallas faciales sin mascarilla',
      'Mascarillas quirúrgicas',
      'FFP2/KN95 con válvula de exhalación',
      'FFP2/KN95',
    ],
    byDefault: 'Sin mascarilla',
    description: (
      <FormattedMessage
        id="input32.description"
        defaultMessage="* Se debe escoger un tipo de mascarilla para todos los sujetos susceptibles de poder infectarse por inhalación de carga vírica."
      />
    ),
  },
  {
    id: 33,
    section: 'Proteccion',
    type: 'select',
    name: 'ajusteMascarillaInh',
    label: (
      <FormattedMessage
        id="input33.label"
        defaultMessage="Ajuste de mascarilla:"
      />
    ),
    optionsES: ['Poco ajustado', 'Bien ajustado'],
    optionsEN: ['Non fitted', 'Fitted'],
    values: [AJUSTE_MASCARILLA.MALO, AJUSTE_MASCARILLA.BUENO],
    byDefault: 0.5,
    description: (
      <FormattedMessage
        id="input33.description"
        defaultMessage="* Las mascarillas quirúrgicas/higiénicas solo pueden estar bien ajustadas mediante accesorio especial."
      />
    ),
  },
  {
    id: 34,
    section: 'Proteccion',
    type: 'range',
    name: 'porcentajeExhConMasc',
    label: (
      <FormattedMessage
        id="input34.label"
        defaultMessage="Personas infecciosas con mascarilla (%):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 100,
    byDefault: 100,
    description: '',
  },
  {
    id: 35,
    section: 'Proteccion',
    type: 'range',
    name: 'porcentajeInhConMasc',
    label: (
      <FormattedMessage
        id="input35.label"
        defaultMessage="Personas susceptibles de infectarse con mascarilla (%):"
      />
    ),
    xstep: 1,
    precision: 0,
    xmin: 0,
    xmax: 100,
    byDefault: 100,
    description: '',
  },
  {
    id: 36,
    section: 'Simplificado',
    type: 'select',
    name: 'nivelVentilacionSimplificado',
    label: (
      <FormattedMessage
        id="input36.label"
        defaultMessage="Nivel de ventilación:"
      />
    ),
    optionsES: [
      'Inadecuado - Ventanas cerradas',
      'Medio - Ventanas entreabiertas',
      'Adecuado - Ventanas abiertas',
    ],
    optionsEN: [
      'Insufficient - Windows closed',
      'Intermediate - Windows half-open',
      'Sufficient - Windows open',
    ],
    values: [
      'Inadecuado - Ventanas cerradas',
      'Medio - Ventanas entreabiertas',
      'Adecuado - Ventanas abiertas',
    ],
    byDefault: 'Inadecuado - Ventanas cerradas',
    description: (
      <FormattedMessage
        id="input36.description"
        values={{
          br: <br />,
          p: (words) => <p>{words}</p>,
        }}
      />
    ),
  },
];

export default inputObj;
