import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";

const SemaforoDirecta = (props) => {
  const [luzEncendida, setLuzEncendida] = useState(null);

  const updateState = useCallback(
    (color) => {
      setLuzEncendida(color);
      props.updateColorBorde(color);
    },
    [props]
  );

  useEffect(() => {
    if (props.resultado > props.limite1) {
      updateState("verde");
    } else if (props.resultado > props.limite2) {
      updateState("naranja");
    } else {
      updateState("roja");
    }
  }, [props.resultado, props.limite1, props.limite2, updateState]);

  return (
    <Estructura>
      {luzEncendida === "verde" && (
        <>
          <Luces>
            <LuzRoja disabled>
              <svg width="16" height="16" fill="currentColor">
                <path d="M6.002 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm.195-12.01a1.81 1.81 0 1 1 3.602 0l-.701 7.015a1.105 1.105 0 0 1-2.2 0l-.7-7.015z" />
              </svg>
            </LuzRoja>
            <LuzNaranja disabled>
              <svg
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </LuzNaranja>
            <LuzVerde>
              <svg width="16" height="16" fill="currentColor">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
              </svg>
            </LuzVerde>
          </Luces>
          <Limites>
            <LimiteInferior>
              <h5>{`≥${props.limite1}`}</h5>
            </LimiteInferior>
            <LimiteSuperior>
              <h5>{`≥${props.limite2}`}</h5>
            </LimiteSuperior>
          </Limites>
        </>
      )}
      {luzEncendida === "naranja" && (
        <>
          <Luces>
            <LuzRoja disabled>
              <svg width="16" height="16" fill="currentColor">
                <path d="M6.002 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm.195-12.01a1.81 1.81 0 1 1 3.602 0l-.701 7.015a1.105 1.105 0 0 1-2.2 0l-.7-7.015z" />
              </svg>
            </LuzRoja>

            <LuzNaranja>
              <svg
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </LuzNaranja>

            <LuzVerde disabled>
              <svg width="16" height="16" fill="currentColor">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
              </svg>
            </LuzVerde>
          </Luces>
          <Limites>
            <LimiteInferior>
              <h5>{`≥${props.limite1}`}</h5>
            </LimiteInferior>
            <LimiteSuperior>
              <h5>{`≥${props.limite2}`}</h5>
            </LimiteSuperior>
          </Limites>
        </>
      )}
      {luzEncendida === "roja" && (
        <>
          <Luces>
            <LuzRoja>
              <svg width="16" height="16" fill="currentColor">
                <path d="M6.002 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm.195-12.01a1.81 1.81 0 1 1 3.602 0l-.701 7.015a1.105 1.105 0 0 1-2.2 0l-.7-7.015z" />
              </svg>
            </LuzRoja>

            <LuzNaranja disabled>
              <svg
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </LuzNaranja>

            <LuzVerde disabled>
              <svg width="16" height="16" fill="currentColor">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
              </svg>
            </LuzVerde>
          </Luces>
          <Limites>
            <LimiteInferior>
              <h5>{`≥${props.limite1}`}</h5>
            </LimiteInferior>
            <LimiteSuperior>
              <h5>{`≥${props.limite2}`}</h5>
            </LimiteSuperior>
          </Limites>
        </>
      )}
      {(luzEncendida === null || luzEncendida === undefined) && (
        <>
          <Luces>
            <LuzRoja disabled>
              <svg width="16" height="16" fill="currentColor">
                <path d="M6.002 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm.195-12.01a1.81 1.81 0 1 1 3.602 0l-.701 7.015a1.105 1.105 0 0 1-2.2 0l-.7-7.015z" />
              </svg>
            </LuzRoja>

            <LuzNaranja disabled>
              <svg
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </LuzNaranja>

            <LuzVerde disabled>
              <svg width="16" height="16" fill="currentColor">
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
              </svg>
            </LuzVerde>
          </Luces>
          <Limites>
            <LimiteInferior>
              <h5>{`≥${props.limite1}`}</h5>
            </LimiteInferior>
            <LimiteSuperior>
              <h5>{`≥${props.limite2}`}</h5>
            </LimiteSuperior>
          </Limites>
        </>
      )}
    </Estructura>
  );
};

export default SemaforoDirecta;

const Estructura = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const Luces = styled.div`
  display: grid;
  grid-template-rows: 2rem;
  grid-template-columns: 2rem 12px 2rem 12px 2rem;
  grid-template-areas: "leftAr . centerAr . rightAr";
`;

const Circulo = styled.div`
  border-radius: 50%;
  border: 1px solid whitesmoke;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
`;

const LuzVerde = styled(Circulo)`
  grid-area: rightAr;
  background: rgb(37, 177, 40);
  background: radial-gradient(
    circle,
    rgb(38, 186, 41) 0%,
    rgb(37, 177, 40) 60%,
    rgb(33, 156, 35) 100%
  );
  background: ${(props) => props.disabled && "gray"};
`;

const LuzNaranja = styled(Circulo)`
  grid-area: centerAr;

  background: rgb(255, 165, 20);
  background: radial-gradient(
    circle,
    rgb(240, 148, 0) 0%,
    rgb(255, 165, 20) 60%,
    rgb(189, 116, 0) 100%
  );
  background: ${(props) => props.disabled && "gray"};
`;

const LuzRoja = styled(Circulo)`
  grid-area: leftAr;

  background: rgb(237, 64, 64);
  background: radial-gradient(
    circle,
    rgb(237, 64, 64) 0%,
    rgb(225, 25, 25) 60%,
    rgb(175, 18, 18) 100%
  );
  background: ${(props) => props.disabled && "gray"};
`;

const Limites = styled.div`
  display: flex;
  padding: 0 1rem;
`;

const LimiteInferior = styled.div`
  width: calc((120px - 2rem) / 2);
  text-align: center;
`;
const LimiteSuperior = styled.div`
  width: calc((120px - 2rem) / 2);
  text-align: center;
`;
