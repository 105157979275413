import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Column,
  FullSectionContainer,
  ResponsiveContainer,
  Row,
  SectionSubtitle,
  SectionTitle,
} from '../../globalStyles';
import BackButton from '../UI/BackHistoryBtn';

import flujoDeTrabajoES from '../../images/flujoTrabajo.svg';
import flujoDeTrabajoEN from '../../images/flujoTrabajoEN.svg';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';

const Ayuda = () => {
  const lang = useContext(langContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AyudaPage white>
      <FormattedMessage id="ayuda.pagetitle" defaultMessage="Guia de uso">
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Guia sobre el uso y ayuda de la aplicación para el cálculo de riesgo de infección por COViD-19."
            />
          </Helmet>
        )}
      </FormattedMessage>

      <ResponsiveContainer>
        <SectionTitle primary>
          <FormattedMessage id="ayuda.title" defaultMessage="Guia de uso" />
        </SectionTitle>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ayuda.subtitle1"
              defaultMessage="Videotutorial"
            />
          </SectionSubtitle>

          <VideoResponsive>
            <iframe
              src="https://www.youtube-nocookie.com/embed/xqODUqUMXvw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </VideoResponsive>
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ayuda.subtitle2"
              defaultMessage="Flujo de trabajo"
            />
          </SectionSubtitle>
          {lang.locale === 'es' ? (
            <ImageCont>
              <img
                width="100%"
                src={flujoDeTrabajoES}
                alt="Esquema sobre el flujo de trabajo"
              />
            </ImageCont>
          ) : (
            <ImageCont>
              <img width="100%" src={flujoDeTrabajoEN} alt="Workflow" />
            </ImageCont>
          )}
        </section>
        <Row>
          <Column width={50}>
            <BackButton>
              <FormattedMessage id="gen.btn.atras" defaultMessage="Atrás" />
            </BackButton>
          </Column>
          <Column width={50}></Column>
        </Row>
      </ResponsiveContainer>
    </AyudaPage>
  );
};

export default Ayuda;

const AyudaPage = styled(FullSectionContainer)`
  section {
    margin-bottom: 4rem;
  }
`;

const ImageCont = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 90%;
  }
`;

const VideoResponsive = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  margin-bottom: 2rem;
  position: relative;

  & iframe,
  .video-responsive object,
  .video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
