import React, { useContext, useState } from 'react';
import Slider from 'react-input-slider';
import { langContext } from '../../context/langContext';
import styled, { css } from 'styled-components';

const InputRange = (props) => {
  const [state, setState] = useState({ x: props.stateData[props.name] });

  const lang = useContext(langContext);

  const fixedToPrecision = (num, precision) => {
    return Number(num.toFixed(precision));
  };

  const handleInputSlider = ({ x }) => {
    props.updateDatos(fixedToPrecision(x, props.precision), props.name);
    setState((state) => ({
      ...state,
      x: fixedToPrecision(x, props.precision),
    }));
  };

  const plus1Step = () => {
    if (
      fixedToPrecision(Number(state.x) + props.xstep, props.precision) >=
      props.xmax
    ) {
      props.updateDatos(props.xmax, props.name);
      setState({ x: props.xmax });
    } else {
      props.updateDatos(
        fixedToPrecision(Number(state.x) + props.xstep, props.precision),
        props.name
      );
      setState((state) => ({
        x: fixedToPrecision(Number(state.x) + props.xstep, props.precision),
      }));
    }
  };

  const minus1Step = () => {
    if (
      fixedToPrecision(Number(state.x) - props.xstep, props.precision) <=
      props.xmin
    ) {
      props.updateDatos(props.xmin, props.name);
      setState({
        x: props.xmin,
      });
    } else {
      props.updateDatos(
        fixedToPrecision(Number(state.x) - props.xstep, props.precision),
        props.name
      );
      setState((state) => ({
        x: fixedToPrecision(Number(state.x) - props.xstep, props.precision),
      }));
    }
  };

  // Dont use fixedToPrecision on handleInputNumber's setState, since it updates state on change and input value is equal to state.
  function handleInputNumber(e) {
    if (e.target.value >= props.xmin && e.target.value <= props.xmax) {
      props.updateDatos(Number(e.target.value), props.name);
      setState(() => ({ x: e.target.value }));
    } else {
      props.updateDatos(null, props.name);
      setState(() => ({ x: null }));
    }
  }

  const handleBlur = (e) => {
    if (
      isNaN(e.target.value) ||
      e.target.value === null ||
      e.target.value === undefined ||
      e.target.value < props.xmin ||
      e.target.value > props.xmax
    ) {
      if (lang.locale === 'es')
        window.alert(
          `Introduzca un número comprendido entre ${props.xmin} y ${props.xmax}`
        );
      if (lang.locale === 'en')
        window.alert(`Enter a number between ${props.xmin} and ${props.xmax}`);
    } else {
      setState(() => ({
        x: fixedToPrecision(Number(e.target.value), props.precision),
      }));
    }
  };

  return (
    <InputRangeContainer description={props.description}>
      <Label htmlFor={`RangeInput${props.uid}`} className="inputLabel">
        {props.label}
      </Label>
      <div className="inputSliderContainer">
        <button className="btnSlider" onClick={minus1Step} tabIndex="-1">
          -
        </button>
        <Slider
          styles={{
            track: {
              backgroundColor: 'var(--light)',
            },
            active: {
              backgroundColor: 'var(--dark)',
            },
            thumb: {
              background: 'whitesmoke',
              width: 20,
              height: 20,
            },
          }}
          className="inputSlider"
          axis="x"
          xmin={props.xmin}
          xmax={props.xmax}
          xstep={props.xstep}
          x={state.x}
          onChange={handleInputSlider}
        />
        <button className="btnSlider" onClick={plus1Step} tabIndex="-1">
          +
        </button>
      </div>

      <input
        id={`RangeInput${props.uid}`}
        className={`inputNumber ${
          state.x === null || state.x === undefined
            ? 'inputDanger'
            : 'inputSuccess'
        }`}
        type="number"
        value={state.x}
        min={props.xmin}
        max={props.xmax}
        onChange={handleInputNumber}
        onBlur={handleBlur}
      />

      <Description className={`${props.description ? null : 'hidden'}`}>
        {props.description}
      </Description>
    </InputRangeContainer>
  );
};

const InputRangeContainer = styled.div`
  display: grid;
  justify-items: start;
  align-items: center;
  column-gap: 1rem;
  grid-template-rows: 30px 30px;
  grid-template-columns: auto 56px;
  grid-template-areas:
    'iLabel iNumber '
    'iSlider iNumber';
  ${(props) =>
    props.description &&
    css`
      grid-template-rows: 30px 30px auto;
      grid-template-columns: auto 56px;
      grid-template-areas:
        'iLabel iNumber '
        'iSlider iNumber'
        'iDesc iDesc';
    `}
  max-width: 320px;
  padding-bottom: 1.5rem;

  .inputSliderContainer {
    display: flex;
    grid-area: iSlider;

    .btnSlider {
      width: 30px;
      height: 30px;
      background: none;
      border: 2px solid var(--verydark);
      border: none;
      border-radius: 50%;
      font-family: 'Nunito', sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .inputNumber {
    grid-area: iNumber;
    text-align: center;
    align-self: flex-end;
    width: 3.5rem;
    padding: 0.4rem;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    font-size: 0.8rem;
    color: var(--verydark);
    outline: none;
    border: 2px solid white;
    background: whitesmoke;
  }

  .inputDanger {
    border: 2px solid var(--danger);
  }
  .inputSuccess {
    border: 2px solid whitesmoke;
  }

  .inputSlider {
    width: 170px;
    height: 15px;
    align-self: center;
    margin: 0 10px;
  }

  .hidden {
    display: none;
  }
`;

const Label = styled.label`
  grid-area: iLabel;
  justify-self: start;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Description = styled.h5`
  grid-area: iDesc;
  justify-self: start;
  font-size: 0.7rem;
  font-weight: bold;
  color: grey;
  padding-top: 0.6rem;
  padding-left: 0.2rem;
  text-align: justify;
`;

export default InputRange;
