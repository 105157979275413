import { useHistory } from "react-router-dom";
import styled from "styled-components";

const BackButton = ({ children }) => {
  let history = useHistory();

  return (
    <Btn type="button" onClick={() => history.goBack()}>
      <svg width="16" height="16" fill="currentColor">
        <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
      </svg>
      {children}
    </Btn>
  );
};

export default BackButton;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
  border-radius: 10px;
  font-weight: bold;
  background: LightSlateGray;
  color: whitesmoke;
  border: 2px solid LightSlateGray;
  width: 130px;

  &:hover {
    background: var(--dark);
    border: 2px solid var(--dark);
  }

  svg {
    margin-right: 0.5rem;
  }
`;
