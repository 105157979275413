import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { langContext } from '../../context/langContext';

const truncToPrecision = (num, precision) => {
  return Math.trunc(num * Math.pow(10, precision)) / Math.pow(10, precision);
};

const InputDisabled = (props) => {
  const lang = useContext(langContext);
  return (
    <InputRangeContainer description={props.description}>
      <div className="inputNumber">
        {truncToPrecision(props.dimension, props.precision)}
      </div>
      <Label className="inputLabel">
        {lang.locale === 'es' ? props.label : props.labelEN}
      </Label>
      <Description className={`${props.description ? null : 'hidden'}`}>
        {props.description}
      </Description>
    </InputRangeContainer>
  );
};

const InputRangeContainer = styled.div`
  display: grid;
  justify-items: start;
  align-items: center;
  column-gap: 1rem;
  grid-template-rows: 30px;
  grid-template-columns: 56px auto;
  grid-template-areas:
    'iNumber iLabel'
    ${(props) =>
      props.description &&
      css`
        grid-template-rows: 30px 30px;
        grid-template-columns: 56px auto;
        grid-template-areas:
          'iNumber iLabel'
          'iDesc iDesc';
      `};
  max-width: 320px;
  padding-bottom: 1.5rem;

  .inputNumber {
    grid-area: iNumber;
    text-align: center;
    align-self: flex-end;
    width: 3.5rem;
    padding: 0.4rem;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    font-size: 0.8rem;
    color: gray;
    outline: none;
    border: 2px solid whitesmoke;
    background: whitesmoke;
  }

  .hidden {
    display: none;
  }
`;

const Label = styled.label`
  grid-area: iLabel;
  justify-self: start;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Description = styled.h5`
  grid-area: iDesc;
  justify-self: start;
  font-size: 0.7rem;
  font-weight: bold;
  color: grey;
  padding-top: 0.2rem;
  padding-left: 0.2rem;
`;

export default InputDisabled;
