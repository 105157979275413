import React from 'react';
import styled from 'styled-components';
import {
  ResponsiveContainer,
  Row,
  Column,
  FullSectionContainer,
} from '../../globalStyles';
import { Link } from 'react-router-dom';
import usLogo from '../../images/usLogo.svg';
import ventilandoLogo from '../../images/ventilandoLogo.svg';
import arqWellnessLogo from '../../images/arqWellnessLogo.png';
import aireamosLogo from '../../images/aireamosLogo.svg';
import chronoLightLogo from '../../images/chronoLightLogo.svg';
import coloradoBoulderLogo from '../../images/coloradoBoulderLogo.png';
import unCoverLogo from '../../images/uncoverLogo.svg';
import fundacionVisibleLogo from '../../images/fundacionVisibleLogo.png';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
  return (
    <>
      <FooterCont style={{ padding: '1rem' }}>
        <ResponsiveContainer style={{ padding: '0.5rem' }}>
          <FooterRow stackMobile>
            <Column stackMobile width={70}>
              <h5 style={{ fontSize: '0.7rem' }}>
                <FormattedMessage
                  id="footer.entidades"
                  defaultMessage="ENTIDADES COLABORADORAS"
                />
              </h5>
              <Wrapper>
                <a
                  href="https://www.us.es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={usLogo} alt="Logotipo Universidad de Sevilla" />
                </a>
                <a
                  href="http://institucional.us.es/arqwellness/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={arqWellnessLogo} alt="Logotipo ArqWellness" />
                </a>
                <img src={chronoLightLogo} alt="Logotipo ChronoLight" />
                <img src={unCoverLogo} alt="Logotipo UnCover" />
                <a
                  href="https://www.colorado.edu/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={coloradoBoulderLogo}
                    alt="Logotipo Colorado Boulder"
                  />
                </a>
                <a
                  href="https://www.fundacionvisible.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={fundacionVisibleLogo}
                    alt="Logotipo Fundacion Visible"
                  />
                </a>
                <a
                  href="http://ventilando.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={ventilandoLogo} alt="Logotipo Ventilando" />
                </a>
                <a
                  href="https://www.aireamos.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={aireamosLogo} alt="Logotipo Aireamos" />
                </a>
              </Wrapper>
            </Column>
            <LinksCont>
              <Column className="linksColumn">
                <ul>
                  <li>
                    <Link to="/informacion">
                      <FormattedMessage
                        id="footer.info"
                        defaultMessage="Información de interés"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/ayuda">
                      <FormattedMessage
                        id="footer.ayuda"
                        defaultMessage="Guía de uso"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/politicacookies">
                      <FormattedMessage
                        id="footer.cookies"
                        defaultMessage="Política de cookies"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/condicionesuso">
                      <FormattedMessage
                        id="footer.condiciones"
                        defaultMessage="Condiciones de uso"
                      />
                    </Link>
                  </li>
                </ul>
              </Column>
            </LinksCont>
          </FooterRow>
        </ResponsiveContainer>
      </FooterCont>

      <Bar>
        <Author>
          © 2021{' '}
          <a
            href="http://institucional.us.es/arqwellness/"
            target="_blank"
            rel="noreferrer noopener"
          >
            ArqWellness
          </a>{' '}
          -{' '}
          <a
            href="https://www.us.es/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Universidad de Sevilla
          </a>
        </Author>
      </Bar>
    </>
  );
};

export default Footer;

const FooterCont = styled(FullSectionContainer)`
  position: relative;
  background: var(--gdverydark);
  padding: 2rem;
  color: #b0bec5;
  z-index: 2;

  @media print {
    display: none;
  }

  a {
    &:link {
      color: whitesmoke;
    }
    &:visited {
      color: whitesmoke;
    }
    &:hover {
      color: var(--danger);
    }
    &:focus {
      color: var(--danger);
    }
  }

  .linksColumn {
    color: whitesmoke;

    span {
      font-size: 0.7em;
      text-transform: uppercase;
      font-weight: bold;
      color: white;

      padding: 0 0 0.5rem 0;
      display: block;
      color: #b0bec5;
    }
    ul {
      list-style: none;

      li {
        font-size: 0.8rem;
        padding: 0.3rem 0;

        .routerLink {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  img {
    width: 70px;
  }
`;

const FooterRow = styled(Row)`
  column-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 792px) {
    gap: 2rem;
  }
`;

const Bar = styled.div`
  background: var(--gdverydark);
  color: black;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  a {
    &:link {
      color: #b0bec5;
    }
    &:visited {
      color: #b0bec5;
    }
    &:hover {
      color: var(--danger);
    }
    &:focus {
      color: var(--danger);
    }
  }

  @media print {
    display: none;
  }
`;

const Author = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
`;

const LinksCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
