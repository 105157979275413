import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  FullSectionContainer,
  SectionTitle,
  SectionSubtitle,
  InnerContainer,
  Row,
  Column,
  Button,
  ResponsiveContainer,
  size,
} from '../../globalStyles';
import inputObj from '../../data/inputs';
import InputRange from '../UI/InputRange';
import InputSelect from '../UI/InputSelect';
import Toggle from '../UI/Toggle';
import NavigationButtons from '../UI/NavigationButtons';
import FormNavigation from './FormNavigation';
import ProgressBar from '../UI/ProgressBar';
import ocupacion from '../../images/ocupacion.svg';
import actividad from '../../images/actividadRojo.svg';
import InputClassic from '../UI/InputClassic';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Actividad = (props) => {
  const [esCalcIA, setEsCalcIA] = useState(props.stateData.esCalcIA);

  const handleAvanzado = () => {
    setEsCalcIA((state) => !state);
    props.updateDatos(!esCalcIA, 'esCalcIA');
  };

  const [esActividadDocente, setEsActividadDocente] = useState(
    props.stateData.esActividadDocente
  );

  const handleActividadDocente = () => {
    setEsActividadDocente((state) => !state);
    props.updateDatos(!esActividadDocente, 'esActividadDocente');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showVacunasInfo, setShowVacunasInfo] = useState(false);
  const handleShowVacunasInfo = () => {
    setShowVacunasInfo((state) => !state);
  };

  return (
    <FullSectionContainer white>
      <FormattedMessage
        id="actividad.pagetitle"
        defaultMessage="Formulario Simplificado"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Formulario sobre la actividad realizada y la ocupación en el recinto y otros parámetros relacionados que influyen en el riesgo de infección por COViD-19"
            />
          </Helmet>
        )}
      </FormattedMessage>

      <ActividadSection>
        <FormNavigation
          goBack={'/parametrosambientales'}
          goNext={'/ventilacion'}
        />
        <section>
          <SectionTitle primary>
            <FormattedMessage
              id="actividad.title"
              defaultMessage="Parámetros de ocupación y actividad realizada"
            />
          </SectionTitle>
          <SectionSubtitle primary>
            <FormattedMessage
              id="actividad.subtitle1"
              defaultMessage="Ocupación"
            />
          </SectionSubtitle>
          <h5>
            <FormattedMessage
              id="actividad.subtitle1.content"
              defaultMessage="Puede indicar el número de ocupantes infectados, o si lo prefiere, la incidencia acumulada en casos por cada 100.000 habitantes (IA). Seleccione a continuación:"
            />
          </h5>
          <Row>
            <Column stackMobile width={50}>
              {esCalcIA ? (
                <>
                  <InnerContainer>
                    <Toggle
                      handleClick={handleAvanzado}
                      leftLabelES={'Número de Infectados'}
                      leftLabelEN={'Number of people infected'}
                      rightLabelES={'Incidencia Acumulada'}
                      rightLabelEN={'Incidence Rate'}
                      rightActive
                    />
                  </InnerContainer>
                  <section>
                    <InnerContainer>
                      {inputObj
                        .filter(
                          (input) =>
                            input.name === 'casosIA100k' &&
                            input.tab.includes(10)
                        )
                        .map(
                          (input) =>
                            input.type === 'classic' && (
                              <InputClassic
                                key={input.id}
                                uid={input.id}
                                updateDatos={props.updateDatos}
                                name={input.name}
                                label={input.label}
                                xstep={input.xstep}
                                precision={input.precision}
                                xmin={input.xmin}
                                xmax={input.xmax}
                                byDefault={input.byDefault}
                                description={input.description}
                                stateData={props.stateData}
                              />
                            )
                        )}
                      {inputObj
                        .filter(
                          (input) =>
                            input.section === 'Ocupacion' &&
                            input.tab.includes(1)
                        )
                        .map((input) =>
                          input.type === 'range' ? (
                            <InputRange
                              key={input.id}
                              uid={input.id}
                              updateDatos={props.updateDatos}
                              name={input.name}
                              label={input.label}
                              xstep={input.xstep}
                              precision={input.precision}
                              xmin={input.xmin}
                              xmax={input.xmax}
                              byDefault={input.byDefault}
                              description={input.description}
                              stateData={props.stateData}
                            />
                          ) : (
                            <InputSelect
                              key={input.id}
                              uid={input.id}
                              updateDatos={props.updateDatos}
                              name={input.name}
                              label={input.label}
                              optionsES={input.optionsES}
                              optionsEN={input.optionsEN}
                              values={input.values}
                              byDefault={input.byDefault}
                              description={input.description}
                              stateData={props.stateData}
                            />
                          )
                        )}
                      <AclaracionesBtn
                        transparent
                        onClick={handleShowVacunasInfo}
                      >
                        <FormattedMessage
                          id="actividad.subtitle1.aclaracion"
                          defaultMessage="¿Qué valores de inmunidad debo introducir?"
                        />
                      </AclaracionesBtn>
                      <Aclaraciones>
                        {showVacunasInfo ? (
                          <>
                            <SeccionAclaraciones>
                              <TituloAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle1"
                                  defaultMessage="Población inmune en el recinto (%):"
                                />
                              </TituloAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle1.content.p1"
                                  defaultMessage="Puede obtener los valores correspondientes al momento más reciente en la nación española, en la <a>página web de la estrategia de vacunación del Gobierno de España</a>. En ella encontrará una infografía que indica el porcentaje de vacunados con doble pauta."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="https://www.vacunacovid.gob.es/"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle1.content.p2"
                                  defaultMessage="Así como también puede encontrar su distribución geográfica en la <a>página web del Centro Nacional de Epidemiología</a>."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="https://cnecovid.isciii.es/covid19/#distribuci%C3%B3n-geogr%C3%A1fica"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                            </SeccionAclaraciones>
                            <SeccionAclaraciones>
                              <TituloAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2"
                                  defaultMessage="Inmunidad adquirida (%):"
                                />
                              </TituloAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2.content.p1"
                                  defaultMessage="Estime un valor conociendo para su zona geográfica la <em>eficacia de las vacunas contra la infección</em>, y la <em>distribución de vacunas</em>, o por simplicidad, deje el valor por defecto, que es un promedio para el caso de España."
                                  values={{
                                    em: (words) => <em>{words}</em>,
                                  }}
                                />
                              </ParrafoAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2.content.p2"
                                  defaultMessage="Puede encontrar los valores estimados de eficacia de las vacunas en el <a>sumario realizado por el Institute for Health Metrics and Evaluation</a>. Consulte el valor de protección que tienen las personas contra la infección por cada vacuna en la tabla 2."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="http://www.healthdata.org/covid/covid-19-vaccine-efficacy-summary"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2.content.p3"
                                  defaultMessage="La distribución de vacunas en España se puede consultar en la <a>página web de la estrategia de vacunación del Gobierno de España</a>. Debe dirigirse al 'Informe diario de vacunación (PDF)', que encontrará debajo de la infografía sobre el estado de la vacunación."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov/vacunaCovid19.htm"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                            </SeccionAclaraciones>
                          </>
                        ) : null}
                      </Aclaraciones>
                    </InnerContainer>
                  </section>
                </>
              ) : (
                <>
                  <InnerContainer>
                    <Toggle
                      handleClick={handleAvanzado}
                      leftLabelES={'Número de Infectados'}
                      leftLabelEN={'Number of people infected'}
                      rightLabelES={'Incidencia Acumulada'}
                      rightLabelEN={'Incidence Rate'}
                      leftActive
                    />
                  </InnerContainer>
                  <InnerContainer>
                    <section>
                      {inputObj
                        .filter(
                          (input) =>
                            input.name === 'infectivas' &&
                            input.tab.includes(10)
                        )
                        .map(
                          (input) =>
                            input.type === 'range' && (
                              <InputRange
                                key={input.id}
                                uid={input.id}
                                updateDatos={props.updateDatos}
                                name={input.name}
                                label={input.label}
                                xstep={input.xstep}
                                precision={input.precision}
                                xmin={input.xmin}
                                xmax={input.xmax}
                                byDefault={input.byDefault}
                                description={input.description}
                                stateData={props.stateData}
                              />
                            )
                        )}
                      {inputObj
                        .filter(
                          (input) =>
                            input.section === 'Ocupacion' &&
                            input.tab.includes(0)
                        )
                        .map((input) =>
                          input.type === 'range' ? (
                            <InputRange
                              key={input.id}
                              uid={input.id}
                              updateDatos={props.updateDatos}
                              name={input.name}
                              label={input.label}
                              xstep={input.xstep}
                              precision={input.precision}
                              xmin={input.xmin}
                              xmax={input.xmax}
                              byDefault={input.byDefault}
                              description={input.description}
                              stateData={props.stateData}
                            />
                          ) : (
                            <InputSelect
                              key={input.id}
                              uid={input.id}
                              updateDatos={props.updateDatos}
                              name={input.name}
                              label={input.label}
                              optionsES={input.optionsES}
                              optionsEN={input.optionsEN}
                              values={input.values}
                              byDefault={input.byDefault}
                              description={input.description}
                              stateData={props.stateData}
                            />
                          )
                        )}
                      <AclaracionesBtn
                        transparent
                        onClick={handleShowVacunasInfo}
                      >
                        <FormattedMessage
                          id="actividad.subtitle1.aclaracion"
                          defaultMessage="¿Qué valores de inmunidad debo introducir?"
                        />
                      </AclaracionesBtn>
                      <Aclaraciones>
                        {showVacunasInfo ? (
                          <>
                            <SeccionAclaraciones>
                              <TituloAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle1"
                                  defaultMessage="Población inmune en el recinto (%):"
                                />
                              </TituloAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle1.content.p1"
                                  defaultMessage="Puede obtener los valores correspondientes al momento más reciente en la nación española, en la <a>página web de la estrategia de vacunación del Gobierno de España</a>. En ella encontrará una infografía que indica el porcentaje de vacunados con doble pauta."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="https://www.vacunacovid.gob.es/"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle1.content.p2"
                                  defaultMessage="Así como también puede encontrar su distribución geográfica en la <a>página web del Centro Nacional de Epidemiología</a>."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="https://cnecovid.isciii.es/covid19/#distribuci%C3%B3n-geogr%C3%A1fica"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                            </SeccionAclaraciones>
                            <SeccionAclaraciones>
                              <TituloAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2"
                                  defaultMessage="Inmunidad adquirida (%):"
                                />
                              </TituloAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2.content.p1"
                                  defaultMessage="Estime un valor conociendo para su zona geográfica la <em>eficacia de las vacunas contra la infección</em>, y la <em>distribución de vacunas</em>, o por simplicidad, deje el valor por defecto, que es un promedio para el caso de España."
                                  values={{
                                    em: (words) => <em>{words}</em>,
                                  }}
                                />
                              </ParrafoAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2.content.p2"
                                  defaultMessage="Puede encontrar los valores estimados de eficacia de las vacunas en el <a>sumario realizado por el Institute for Health Metrics and Evaluation</a>. Consulte el valor de protección que tienen las personas contra la infección por cada vacuna en la tabla 2."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="http://www.healthdata.org/covid/covid-19-vaccine-efficacy-summary"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                              <ParrafoAclaraciones>
                                <FormattedMessage
                                  id="actividad.subtitle1.aclaracion.subtitle2.content.p3"
                                  defaultMessage="La distribución de vacunas en España se puede consultar en la <a>página web de la estrategia de vacunación del Gobierno de España</a>. Debe dirigirse al 'Informe diario de vacunación (PDF)', que encontrará debajo de la infografía sobre el estado de la vacunación."
                                  values={{
                                    a: (words) => (
                                      <a
                                        href="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov/vacunaCovid19.htm"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="anchor"
                                      >
                                        {words}
                                      </a>
                                    ),
                                  }}
                                />
                              </ParrafoAclaraciones>
                            </SeccionAclaraciones>
                          </>
                        ) : null}
                      </Aclaraciones>
                    </section>
                  </InnerContainer>
                </>
              )}
            </Column>
            <Column width={50} hiddenMobile alignX={'center'} alignY={'center'}>
              <img src={ocupacion} alt="Ocupación ejemplificada en un aula" />
            </Column>
          </Row>
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="actividad.subtitle2"
              defaultMessage="Actividad realizada, emisión de aerosoles"
            />
          </SectionSubtitle>
          <h5>
            <FormattedMessage
              id="actividad.subtitle2.content"
              defaultMessage="Si la activad desempeñada es la docencia, pulse el botón correspondiente:"
            />
          </h5>
          <ShowImageMobileRow>
            <Column stackMobile width={50}>
              {esActividadDocente ? (
                <>
                  <InnerContainer>
                    <Toggle
                      handleClick={handleActividadDocente}
                      leftLabelES={'Cualquiera'}
                      leftLabelEN={'Any activity'}
                      rightLabelES={'Docencia'}
                      rightLabelEN={'Teaching'}
                      rightActive
                    />
                  </InnerContainer>
                  <InnerContainer>
                    {inputObj
                      .filter(
                        (input) =>
                          input.section === 'Actividad' && input.tab.includes(1)
                      )
                      .map((input) =>
                        input.type === 'range' ? (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ) : (
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        )
                      )}
                  </InnerContainer>
                </>
              ) : (
                <>
                  <InnerContainer>
                    <Toggle
                      handleClick={handleActividadDocente}
                      leftLabelES={'Cualquiera'}
                      leftLabelEN={'Any activity'}
                      rightLabelES={'Docencia'}
                      rightLabelEN={'Teaching'}
                      leftActive
                    />
                  </InnerContainer>
                  <InnerContainer>
                    {inputObj
                      .filter(
                        (input) =>
                          input.section === 'Actividad' && input.tab.includes(0)
                      )
                      .map((input) =>
                        input.type === 'range' ? (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ) : (
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        )
                      )}
                  </InnerContainer>
                </>
              )}
            </Column>
            <Column stackMobile width={50} alignX={'center'} alignY={'center'}>
              <img
                src={actividad}
                alt="personas en actividades que implican los diferentes niveles de actividad posibles"
              />
            </Column>
          </ShowImageMobileRow>
        </section>
        <ProgressBar progress={75} />
        <Row>
          <Column width={50}>
            <NavigationButtons
              to="/parametrosambientales"
              back={'grey'}
              align={'start'}
              textES={'Atrás'}
              textEN={'Back'}
            />
          </Column>
          <Column width={50}>
            <NavigationButtons
              to="/ventilacion"
              forward
              align={'end'}
              textES={'Continuar'}
              textEN={'Next'}
            />
          </Column>
        </Row>
      </ActividadSection>
    </FullSectionContainer>
  );
};

export default Actividad;

const ActividadSection = styled(ResponsiveContainer)`
  .anchor {
    &:link {
      color: var(--dark);
    }
    &:visited {
      color: var(--dark);
    }
    &:hover {
      color: var(--danger);
    }
    &:focus {
      color: var(--danger);
    }
  }

  a {
    .anchor {
      &:link {
        color: var(--dark);
      }
      &:visited {
        color: var(--dark);
      }
      &:hover {
        color: var(--danger);
      }
      &:focus {
        color: var(--danger);
      }
    }
  }
`;

const AclaracionesBtn = styled(Button)`
  text-align: left;

  &:hover {
    color: var(--danger);
  }
`;

const Aclaraciones = styled.div`
  max-width: 320px;

  div {
    select {
      background: whitesmoke;
      border: none;
      padding: 0.2rem;
      color: var(--verydark);
      margin-bottom: 1rem;
    }

    p {
      padding-left: 0rem;
      font-size: 0.7rem;
      padding-bottom: 0.5rem;
      text-align: justify;
    }
  }
`;

const ShowImageMobileRow = styled(Row)`
  @media (max-width: ${size.hideImage}) {
    flex-direction: column;

    img {
      width: 100%;
      position: relative;
      bottom: 2.7rem;
      align-self: flex-start;
    }
  }
`;

const SeccionAclaraciones = styled.div`
  padding: 1rem 0;
`;

const ParrafoAclaraciones = styled.h5`
  font-weight: normal;
  margin-bottom: 0.5rem !important;

  text-align: justify;
`;

const TituloAclaraciones = styled.h5`
  margin-bottom: 1rem;
  text-align: justify;
`;
