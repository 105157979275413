export const VENTILACION_POR_OCUPANTE = {
  IDA1: 20,
  HARVARD: 14,
  IDA2: 12.5,
  IDA3: 8,
  INADECUADA: 0.6,
  MEDIA: 6.7,
  RD: 8.3,
};

export const AJUSTE_MASCARILLA = {
  BUENO: 1,
  MALO: 0.5,
};

export const EFICIENCIA_EXH = {
  FFP2: 90,
  FFP2VALV: 0,
  QUIRURGICA: 65,
  PANTALLA: 23,
  SIN: 0,
};

export const EFICIENCIA_INH = {
  FFP2: 90,
  FFP2VALV: 90,
  QUIRURGICA: 50,
  PANTALLA: 23,
  SIN: 0,
};

export const BMR_AVG = {
  M2: 2.97,
  M3: 2.97,
  M6: 3.75,
  M11: 4.94,
  M16: 6.53,
  M21: 6.95,
  M31: 7.37,
  M41: 6.96,
  M51: 7.08,
  M61: 7.06,
  M71: 6.26,
  M81: 6.01,
  M120: 5.69,
};

export const TASA_METABOLICA = {
  DORMIDO: 0.95,
  SEDENTARIO: 1.2,
  LIGERA: 2,
  MODERADA: 2.8,
  ALTA: 3.6,
};

export const COCIENTE_RESPIRATORIO = 0.85;

// Para 1.54 en tabla.
export const PERDIDA_DEPOSICION = [
  [0.32, 0.42],
  [0.27, 0.54],
  [0.39, 0.67],
  [0.51, 0.77],
];

export const EFICIENCIA_FILTRADO = {
  MERV08: 20,
  MERV09: 35,
  MERV10: 50,
  MERV11: 65,
  MERV12: 80,
  MERV13: 85,
  MERV14: 90,
  MERV15: 90,
  MERV16: 95,
  HEPA13: 100,
  HEPA14: 100,
};

// First level array index = EdadMedia
// Second level array index = Nivel actividad
export const TABLA_INHALACION = [
  [0.0046, 0.0048, 0.012, 0.021, 0.039],
  [0.0043, 0.0045, 0.011, 0.021, 0.037],
  [0.0045, 0.0048, 0.011, 0.022, 0.042],
  [0.005, 0.0054, 0.013, 0.025, 0.049],
  [0.0049, 0.0053, 0.012, 0.026, 0.05],
  [0.0043, 0.0042, 0.012, 0.026, 0.049],
  [0.0046, 0.0043, 0.012, 0.027, 0.052],
  [0.005, 0.0048, 0.013, 0.028, 0.053],
  [0.0052, 0.005, 0.012, 0.029, 0.053],
  [0.0052, 0.0049, 0.012, 0.026, 0.047],
  [0.0053, 0.005, 0.012, 0.025, 0.047],
  [0.0052, 0.0049, 0.012, 0.025, 0.048],
];

export const TABLA_TRAMOS_EDAD = [
  3, 6, 11, 16, 21, 31, 41, 51, 61, 71, 81, 121,
];

export const TABLA_NIVEL_ACTIVIDAD = [
  'Dormido',
  'Sedentario',
  'Intensidad ligera',
  'Intensidad moderada',
  'Intensidad alta',
];

export const RATIO_QUANTA = {
  DESCANSANDO: {
    RESPIRA: 1,
    HABLA: 4.7,
    HABLAFUERTE: 30.3,
  },
  PIE: {
    RESPIRA: 1.2,
    HABLA: 5.7,
    HABLAFUERTE: 32.6,
  },
  EJLIGERO: {
    RESPIRA: 2.8,
    HABLA: 13.2,
    HABLAFUERTE: 85,
  },
  EJINTENSO: {
    RESPIRA: 6.8,
    HABLA: 31.6,
    HABLAFUERTE: 204,
  },
  DOCENTEPIE: {
    RESPIRA: 2.2,
    HABLA: 10.5,
    HABLAFUERTE: 67,
  },
  ALUMNOSENTADO: {
    RESPIRA: 2,
    HABLA: 8,
    HABLAFUERTE: 48.5,
  },
};

export const CARGA_VIRAL_EMITIDA = {
  P50: 3.34,
  P75: 10.21,
  P85: 18.6,
};

// Respecto a poblacion sedentaria < 51 años de media
export const TASA_RESP_RELATIVA = TABLA_INHALACION[7][1] * 60;

// Sujeto infeccioso Descansando - Respiracion oral (solo tasa básica)
export const TASA_EXH_QUANTA = RATIO_QUANTA.DESCANSANDO.RESPIRA;

export const CONCENTRACION_CO2_EXHALACION = 38000;

export const EFICIENCIA_RENOVACION = 0.9975;

export const LIMITE_PPM = 1000;

export const ACH_HABITUALES = [
  [
    {
      NAME: 'Vivienda',
      NAME_EN: 'Dwelling',
      CERRADA: [0.4, 1],
      ABIERTA: [3, 15],
    },
    {
      NAME: 'Aula no universitaria',
      NAME_EN: 'Non-university classroom',
      CERRADA: [0.1, 0.6],
      ABIERTA: [3, 15],
    },
    {
      NAME: 'Aula universitaria',
      NAME_EN: 'University classroom',
      CERRADA: [0.1, 0.6],
      ABIERTA: [3, 15],
    },
    {
      NAME: 'Oficina',
      NAME_EN: 'Office',
      CERRADA: [0.2, 0.6],
      ABIERTA: [3, 15],
    },
  ],
  [
    {
      NAME: 'Autobús de línea',
      NAME_EN: 'Bus',
      CERRADA: [0.2, 1.5],
      ABIERTA: [20, 30],
    },
    {
      NAME: 'Coche',
      NAME_EN: 'Car',
      CERRADA: [0.2, 1.5],
      ABIERTA: [50, 300],
    },
  ],
  [
    {
      NAME: 'Coche',
      NAME_EN: 'Car',
      SIN: [0.2, 1.5],
      CON: [50, 75],
    },
    {
      NAME: 'Metro',
      NAME_EN: 'Underground ',
      SIN: ['', ''],
      CON: [13, 20],
    },
    {
      NAME: 'Autobús',
      NAME_EN: 'Coach',
      SIN: ['', ''],
      CON: [20, 30],
    },
    {
      NAME: 'Tren Alta velocidad',
      NAME_EN: 'High speed train ',
      SIN: [0.1, 0.2],
      CON: [7, 10],
    },
    {
      NAME: 'Avión',
      NAME_EN: 'Airplane',
      SIN: [0.1, 0.2],
      CON: [9, 14],
    },
  ],
];

export const LIMITES_HR = {
  ORIGINAL: { INFERIOR: 0.001, SUPERIOR: 0.01 },
  ALPHA: { INFERIOR: 0.0007, SUPERIOR: 0.0064 },
  BETA: { INFERIOR: 0.0008, SUPERIOR: 0.008 },
  GAMMA: { INFERIOR: 0.0007, SUPERIOR: 0.0064 },
  DELTA: { INFERIOR: 0.0005, SUPERIOR: 0.0048 },
  OMICROM_BA1: { INFERIOR: 0.0005, SUPERIOR: 0.0038 },
  OMICROM_BA2: { INFERIOR: 0.0004, SUPERIOR: 0.0029 },
};

export const MULTIPLICADOR_VARIANTE = {
  ORIGINAL: 1,
  ALPHA: 1.5,
  BETA: 1.2,
  GAMMA: 1.5,
  DELTA: 2,
  OMICROM_BA1: 2.5,
  OMICROM_BA2: 3.3,
};
