import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ProgressBar = (props) => {
  return (
    <ProgressBarCont>
      <h5>
        <FormattedMessage
          id="gen.progress"
          defaultMessage="Este es su progreso en el formulario al completar esta pantalla:"
        />
      </h5>
      <Container>
        <Bar progress={props.progress}>
          {props.progress ? props.progress : 0}%
        </Bar>
      </Container>
    </ProgressBarCont>
  );
};

export default ProgressBar;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
  width: 90%;
  border-radius: 10px;
  border: 2px solid #d1d1d1;
  height: 40px;
  background: #e3eff3;
  padding: 0.15rem;
  margin-bottom: 2rem;
`;

const Bar = styled.div`
  font-family: 'Nunito', sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.progress ? `${props.progress}%` : null)};
  border-radius: 7px;

  min-height: 100%;
  background: linear-gradient(90deg, var(--dark), #0898c4);
  padding: 0.1rem;
  box-shadow: 1px 1px 4px grey;
`;

const ProgressBarCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    font-size: 0.9rem;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 1rem;
  }
`;
