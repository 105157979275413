import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import {
  FullSectionContainer,
  ResponsiveContainer,
  SectionTitle,
  SectionSubtitle,
  InnerContainer,
  Column,
  Row,
  AclaracionIndividual,
} from '../../globalStyles';
import inputObj from '../../data/inputs';
import InputRange from '../UI/InputRange';
import InputSelect from '../UI/InputSelect';
import Toggle from '../UI/Toggle';
import NavigationButtons from '../UI/NavigationButtons';
import ProgressBar from '../UI/ProgressBar';
import dimensiones from '../../images/dimensiones.svg';
import superficie from '../../images/superficie.svg';
import variantes from '../../images/variantes.svg';
import hepaFilter from '../../images/hepaFilter.svg';
import sistemaHVAC from '../../images/sistemaHVAC.svg';
import sistemaHVACEN from '../../images/sistemaHVACEN.svg';

import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';
import FormNavigation from './FormNavigation';

const ParametrosAmbientales = (props) => {
  const lang = useContext(langContext);

  const [esSuperficieCalc, setEsSuperficieCalc] = useState(
    props.stateData.esSuperficieCalc
  );

  const handleSuperficieCalc = () => {
    setEsSuperficieCalc((state) => !state);
    props.updateDatos(!esSuperficieCalc, 'esSuperficieCalc');
  };

  const [esPresionCalc, setEsPresionCalc] = useState(
    props.stateData.esPresionCalc
  );

  const handlePresionCalc = () => {
    setEsPresionCalc((state) => !state);
    props.updateDatos(!esPresionCalc, 'esPresionCalc');
  };

  const [esAvanzado, setEsAvanzado] = useState(props.stateData.esAvanzado);

  const handleAvanzado = () => {
    setEsAvanzado((state) => !state);
    props.updateDatos(!esAvanzado, 'esAvanzado');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FullSectionContainer white>
      <FormattedMessage
        id="ambient.pagetitle"
        defaultMessage="Parámetros Ambientales"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Formulario sobre cuestiones ambientales que influyen en el riesgo de infección por COViD-19"
            />
          </Helmet>
        )}
      </FormattedMessage>

      <ResponsiveContainer>
        <FormNavigation goBack={'/'} goNext={'/actividad'} />
        <SectionTitle primary>
          <FormattedMessage
            id="ambient.title"
            defaultMessage="Parámetros Ambientales"
          />
        </SectionTitle>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ambient.subtitle1"
              defaultMessage="SARS-CoV-2"
            />
          </SectionSubtitle>

          <h5 style={{ marginBottom: '1rem' }}>
            <FormattedMessage
              id="ambient.subtitle1.content"
              defaultMessage="Seleccione la variante del SARS-CoV-2 con la que desea realizar el análisis. Le recomendamos que introduzca como 'variante a considerar' aquella que sea la predominante actualmente en su región. También puede hacer la simulación considerando cualquiera de las variantes principales existentes."
            />
          </h5>
          <Row style={{ marginBottom: '1rem' }}>
            <Column stackMobile width={50} alignY={'center'}>
              {inputObj
                .filter((input) => input.section === 'SARS-CoV-2')
                .map((input) =>
                  input.type === 'range' ? (
                    <InputRange
                      key={input.id}
                      uid={input.id}
                      updateDatos={props.updateDatos}
                      name={input.name}
                      label={input.label}
                      xstep={input.xstep}
                      precision={input.precision}
                      xmin={input.xmin}
                      xmax={input.xmax}
                      byDefault={input.byDefault}
                      description={input.description}
                      stateData={props.stateData}
                    />
                  ) : (
                    <InputSelect
                      key={input.id}
                      uid={input.id}
                      updateDatos={props.updateDatos}
                      name={input.name}
                      label={input.label}
                      optionsES={input.optionsES}
                      optionsEN={input.optionsEN}
                      values={input.values}
                      byDefault={input.byDefault}
                      description={input.description}
                      stateData={props.stateData}
                    />
                  )
                )}
            </Column>
            <Column width={50} alignY={'center'} alignX={'center'} hiddenMobile>
              <img
                src={variantes}
                alt="Representación de letras correspondientes a cada variante de COViD-19"
                width="100%"
              />
            </Column>
          </Row>
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ambient.subtitle2"
              defaultMessage="Dimensiones del recinto"
            />
          </SectionSubtitle>
          <h5>
            <FormattedMessage
              id="ambient.subtitle2.content"
              defaultMessage="Puede hacer el cálculo mediante la introducción de las medidas de ancho y largo medio o bien especificando directamente la superficie. Para ello, pulse uno de los botones siguientes:"
            />
          </h5>
          <Row>
            <Column stackMobile width={50}>
              <InnerContainer>
                <InnerContainer>
                  {esSuperficieCalc ? (
                    <Toggle
                      handleClick={handleSuperficieCalc}
                      leftLabelES={'Medidas medias'}
                      leftLabelEN={'Mean measurement'}
                      rightLabelES={'Superficie'}
                      rightLabelEN={'Area'}
                      rightActive
                    />
                  ) : (
                    <Toggle
                      handleClick={handleSuperficieCalc}
                      leftLabelES={'Medidas medias'}
                      leftLabelEN={'Mean measurement'}
                      rightLabelES={'Superficie'}
                      rightLabelEN={'Area'}
                      leftActive
                    />
                  )}
                </InnerContainer>

                {esSuperficieCalc ? (
                  <>
                    {inputObj
                      .filter(
                        (input) =>
                          input.section === 'Dimensiones del recinto' &&
                          input.tab.includes(1)
                      )
                      .map((input) =>
                        input.type === 'range' ? (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ) : (
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        )
                      )}
                    <Calculos>
                      <FormattedMessage
                        id="gen.volumen"
                        defaultMessage="Volumen"
                      />
                      : {props.stateData.volumen} m³
                    </Calculos>
                  </>
                ) : (
                  <>
                    {inputObj
                      .filter(
                        (input) =>
                          input.section === 'Dimensiones del recinto' &&
                          input.tab.includes(0)
                      )
                      .map((input) =>
                        input.type === 'range' ? (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ) : (
                          <InputSelect
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            optionsES={input.optionsES}
                            optionsEN={input.optionsEN}
                            values={input.values}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        )
                      )}
                    <Calculos>
                      <FormattedMessage
                        id="gen.superficie"
                        defaultMessage="Superficie"
                      />
                      : {props.stateData.superficie} m² &nbsp; &nbsp; / &nbsp;
                      &nbsp;{' '}
                      <FormattedMessage
                        id="gen.volumen"
                        defaultMessage="Volumen"
                      />
                      : {props.stateData.volumen} m³
                    </Calculos>
                  </>
                )}
              </InnerContainer>
            </Column>
            <Column
              width={50}
              alignY={'center'}
              alignX={'center'}
              style={{ paddingTop: '4rem' }}
              hiddenMobile
            >
              {esSuperficieCalc ? (
                <img
                  src={superficie}
                  alt="Volumen calculado en aula mediante superficie"
                  width="100%"
                />
              ) : (
                <img
                  src={dimensiones}
                  alt="Volumen calculado en aula mediante alto y ancho"
                  width="100%"
                />
              )}
            </Column>
          </Row>
        </section>
        <section>
          <SectionSubtitle primary>
            <FormattedMessage
              id="ambient.subtitle3"
              defaultMessage="Condiciones Ambientales"
            />
          </SectionSubtitle>
          <InnerContainer>
            <h5 style={{ paddingBottom: '0.5rem' }}>
              <FormattedMessage
                id="ambient.subtitle3.content1"
                defaultMessage="Si desconoce los valores de temperatura del aire y humedad relativa en el interior del recinto, así como concentración de CO<sub>2</sub> exterior, tome los dados por defecto (21ºC, 40%, 420 ppm)."
                values={{
                  sub: (words) => <sub>{words}</sub>,
                }}
              />
            </h5>
            <h5>
              <FormattedMessage
                id="ambient.subtitle3.content2"
                defaultMessage="Si desconoce la presión atmosférica, puede estimarla a partir de la altura a la que se encuentra con respecto al nivel del mar. Para ello, pulse uno de los botones siguientes:"
              />
            </h5>
            {esPresionCalc ? (
              <Toggle
                handleClick={handlePresionCalc}
                leftLabelES={'Presión Atmosférica'}
                leftLabelEN={'Atmospheric pressure'}
                rightLabelES={'Altura sobre nivel del mar'}
                rightLabelEN={'Elevation above sea level'}
                rightActive
              />
            ) : (
              <Toggle
                handleClick={handlePresionCalc}
                leftLabelES={'Presión Atmosférica'}
                leftLabelEN={'Atmospheric pressure'}
                rightLabelES={'Altura sobre nivel del mar'}
                rightLabelEN={'Elevation above sea level'}
                leftActive
              />
            )}
          </InnerContainer>
          <InnerContainer>
            {esPresionCalc
              ? inputObj
                  .filter(
                    (input) =>
                      input.section === 'Condiciones ambientales' &&
                      input.tab.includes(1)
                  )
                  .map((input) =>
                    input.type === 'range' ? (
                      <InputRange
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        xstep={input.xstep}
                        precision={input.precision}
                        xmin={input.xmin}
                        xmax={input.xmax}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    ) : (
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    )
                  )
              : inputObj
                  .filter(
                    (input) =>
                      input.section === 'Condiciones ambientales' &&
                      input.tab.includes(0)
                  )
                  .map((input) =>
                    input.type === 'range' ? (
                      <InputRange
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        xstep={input.xstep}
                        precision={input.precision}
                        xmin={input.xmin}
                        xmax={input.xmax}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    ) : (
                      <InputSelect
                        key={input.id}
                        uid={input.id}
                        updateDatos={props.updateDatos}
                        name={input.name}
                        label={input.label}
                        optionsES={input.optionsES}
                        optionsEN={input.optionsEN}
                        values={input.values}
                        byDefault={input.byDefault}
                        description={input.description}
                        stateData={props.stateData}
                      />
                    )
                  )}
          </InnerContainer>
        </section>
        <section>
          <h5>
            <FormattedMessage
              id="ambient.avanzado.content"
              defaultMessage="Si considera que es un usuario avanzado, puede acceder a otras secciones opcionales, en las que podrá indicar parámetros relacionados con la deposición en superficies y la inactivación vírica, así como la implementación de medidas de purificación de aire, pulsando el siguiente botón:"
            />
          </h5>
          {esAvanzado ? (
            <div>
              <InnerContainer>
                <Toggle
                  handleClick={handleAvanzado}
                  leftLabelES={'Básico'}
                  leftLabelEN={'Basic'}
                  rightLabelES={'Avanzado'}
                  rightLabelEN={'Advanced'}
                  rightActive
                />
              </InnerContainer>

              <section>
                <SectionSubtitle primary>
                  <FormattedMessage
                    id="ambient.avanzado.subtitle1"
                    defaultMessage="Parámetros de reducción de la infectividad vírica en aerosoles (Avanzado)"
                  />
                </SectionSubtitle>
                <InnerContainer>
                  {inputObj
                    .filter(
                      (input) => input.section === 'Reduccion de infectividad'
                    )
                    .map((input) =>
                      input.type === 'range' ? (
                        <InputRange
                          key={input.id}
                          uid={input.id}
                          updateDatos={props.updateDatos}
                          name={input.name}
                          label={input.label}
                          xstep={input.xstep}
                          precision={input.precision}
                          xmin={input.xmin}
                          xmax={input.xmax}
                          byDefault={input.byDefault}
                          description={input.description}
                          stateData={props.stateData}
                        />
                      ) : (
                        <InputSelect
                          key={input.id}
                          uid={input.id}
                          updateDatos={props.updateDatos}
                          name={input.name}
                          label={input.label}
                          optionsES={input.optionsES}
                          optionsEN={input.optionsEN}
                          values={input.values}
                          byDefault={input.byDefault}
                          description={input.description}
                          stateData={props.stateData}
                        />
                      )
                    )}
                </InnerContainer>
              </section>
              <section>
                <SectionSubtitle primary>
                  <FormattedMessage
                    id="ambient.avanzado.subtitle2"
                    defaultMessage="Otras medidas de retirada de aerosoles del ambiente: Sistemas HVAC (Avanzado)"
                  />
                </SectionSubtitle>
                <Row style={{ marginBottom: '1rem' }}>
                  <Column stackMobile width={50} alignY={'center'}>
                    <InnerContainer>
                      {inputObj
                        .filter((input) => input.id === 15)
                        .map((input) => (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ))}
                      {inputObj
                        .filter((input) => input.id === 16)
                        .map((input) => (
                          <>
                            <InputSelect
                              key={input.id}
                              uid={input.id}
                              updateDatos={props.updateDatos}
                              name={input.name}
                              label={input.label}
                              optionsES={input.optionsES}
                              optionsEN={input.optionsEN}
                              values={input.values}
                              byDefault={input.byDefault}
                              description={input.description}
                              stateData={props.stateData}
                            />
                            <AclaracionIndividual>
                              <FormattedMessage
                                id="gen.eficienciateorica"
                                defaultMessage="Eficiencia teórica"
                              />
                              : {props.stateData.filtroMERV}%
                            </AclaracionIndividual>
                          </>
                        ))}
                      {inputObj
                        .filter((input) => input.id === 17)
                        .map((input) => (
                          <>
                            <InputSelect
                              key={input.id}
                              uid={input.id}
                              updateDatos={props.updateDatos}
                              name={input.name}
                              label={input.label}
                              optionsES={input.optionsES}
                              optionsEN={input.optionsEN}
                              values={input.values}
                              byDefault={input.byDefault}
                              description={input.description}
                              stateData={props.stateData}
                            />
                            <AclaracionIndividual>
                              <FormattedMessage
                                id="gen.eficienciateorica"
                                defaultMessage="Eficiencia teórica"
                              />
                              : {props.stateData.filtroHEPA}%
                            </AclaracionIndividual>
                          </>
                        ))}
                      {inputObj
                        .filter((input) => input.id === 18)
                        .map((input) => (
                          <InputRange
                            key={input.id}
                            uid={input.id}
                            updateDatos={props.updateDatos}
                            name={input.name}
                            label={input.label}
                            xstep={input.xstep}
                            precision={input.precision}
                            xmin={input.xmin}
                            xmax={input.xmax}
                            byDefault={input.byDefault}
                            description={input.description}
                            stateData={props.stateData}
                          />
                        ))}
                    </InnerContainer>
                  </Column>
                  <Column
                    width={50}
                    alignY={'center'}
                    alignX={'center'}
                    hiddenMobile
                  >
                    {lang.locale === 'es' ? (
                      <img
                        src={sistemaHVAC}
                        alt="sistema de ventilacion con germicida UV y filtro"
                        width="100%"
                      />
                    ) : (
                      <img
                        src={sistemaHVACEN}
                        alt="sistema de ventilacion con germicida UV y filtro"
                        width="100%"
                      />
                    )}

                    <img
                      src={hepaFilter}
                      alt="filtro hepa en un aula"
                      width="100%"
                    />
                  </Column>
                </Row>
              </section>
            </div>
          ) : (
            <InnerContainer>
              <Toggle
                handleClick={handleAvanzado}
                leftLabelES={'Básico'}
                leftLabelEN={'Basic'}
                rightLabelES={'Avanzado'}
                rightLabelEN={'Advanced'}
                leftActive
              />
            </InnerContainer>
          )}
          <ProgressBar progress={48} />
          <Row>
            <Column width={50}>
              <NavigationButtons
                to="/"
                back={'grey'}
                align={'start'}
                textES={'Atrás'}
                textEN={'Back'}
              />
            </Column>
            <Column width={50}>
              <NavigationButtons
                to="/actividad"
                align={'end'}
                forward
                textES={'Continuar'}
                textEN={'Next'}
              />
            </Column>
          </Row>
        </section>
      </ResponsiveContainer>
    </FullSectionContainer>
  );
};

export default ParametrosAmbientales;

const Calculos = styled.h5`
  color: var(--verydark);
  padding: 0.7rem 0;
`;
