import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, size } from '../../globalStyles';

const ModalIntro = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  const handleAccept = () => {
    setIsAccepted(true);
  };

  if (!isAccepted) {
    return (
      <>
        <ModalFondo className="modalFondo" />
        <ModalDisclaimerContainer>
          <Row id="flexColumn_sm">
            <ModalDisclaimer>
              <TarjetaModalDisclaimer>
                <ContentDisclaimer>
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '1.1rem',
                    }}
                  >
                    <FormattedMessage
                      id="modalintro.disclaimer.title"
                      defaultMessage="Se cumple lo siguiente:"
                    />
                  </p>
                  <ul>
                    <li>
                      <input type="checkbox" checked disabled></input>
                      <FormattedMessage
                        id="modalintro.disclaimer.option1"
                        defaultMessage="<p><span>El volumen de aire interior</span> del recinto estudiado <span>puede considerarse como homogéneo</span>, no existiendo fenómenos acusados de estratificación de aire (recintos con dobles y triples alturas, grandes dimensiones, etc.)</p>"
                        values={{
                          p: (words) => <p>{words}</p>,
                          span: (words) => <span>{words}</span>,
                        }}
                      />
                    </li>
                    <li>
                      <input type="checkbox" checked disabled></input>
                      <FormattedMessage
                        id="modalintro.disclaimer.option2"
                        defaultMessage="<p>En el recinto estudiado<span>no hay fuentes adicionales de producción de CO2</span> diferentes de la de los ocupantes humanos, como las derivadas de procesos de combustión (cocinas, chimeneas, aparcamientos, tiendas de animales, etc.).</p>"
                        values={{
                          p: (words) => <p>{words}</p>,
                          span: (words) => <span>{words}</span>,
                        }}
                      />
                    </li>
                    <li>
                      <input type="checkbox" checked disabled></input>
                      <FormattedMessage
                        id="modalintro.disclaimer.option3"
                        defaultMessage="<p>El espacio <span>no cuenta con sumideros de CO2</span>, es decir, equipos especiales que permiten fijar el CO2 ambiental para así reducir su concentración artificialmente. Este apartado no hace referencia a filtros de retención de material particulado (como filtros HEPA).</p>"
                        values={{
                          p: (words) => <p>{words}</p>,
                          span: (words) => <span>{words}</span>,
                        }}
                      />
                    </li>
                  </ul>
                  <button
                    style={{
                      padding: '0.5rem 1rem',
                    }}
                    onClick={handleAccept}
                  >
                    <FormattedMessage
                      id="modalintro.disclaimer.submitbutton"
                      defaultMessage="Aceptar"
                    />
                  </button>
                </ContentDisclaimer>
              </TarjetaModalDisclaimer>
            </ModalDisclaimer>
          </Row>
        </ModalDisclaimerContainer>
      </>
    );
  }
  return (
    <>
      <ModalContainer className="modalContainer" id="modalContainer">
        <Row>
          <p style={{ fontWeight: 'bold', padding: '0 2.2rem' }}>
            <FormattedMessage
              id="modalintro.seleccion"
              defaultMessage="Seleccione uno de los dos métodos:"
            />
          </p>
        </Row>
        <Row id="flexColumn_sm">
          <Modal>
            <TarjetaModal primary to="/formulariosimplificado">
              <TitleCont>
                <FormattedMessage
                  id="modalintro.simplificado.title"
                  defaultMessage="Cálculo simplificado"
                />
              </TitleCont>
              <MainContent>
                <FormattedMessage
                  id="modalintro.simplificado.content"
                  defaultMessage="<p>En este método de cálculo, el usuario debe rellenar un <span>breve formulario</span>, basado en datos conocidos o facilmente cuantificables</p><p>En él se asumen una serie de simplificaciones que dan lugar a la obtención de unos <span>resultados menos precisos</span>.</p><p>Para ello, han sido omitidas algunas cuestiones y resultados de índole más técnica.</p><p>Se recomienda el empleo de esta versión a aquellos usuarios que deseen tener una estimación rápida de un evento, sin añadir capas adicionales de complejidad.</p>"
                  values={{
                    p: (words) => <p>{words}</p>,
                    span: (words) => <span>{words}</span>,
                    br: <br />,
                  }}
                />
              </MainContent>
              <MobileContent>
                <p>
                  <FormattedMessage
                    id="modalintro.simplificado.short"
                    defaultMessage="Formulario breve y sencillo con resultados menos precisos."
                  />
                </p>
              </MobileContent>
            </TarjetaModal>
            <TarjetaModal to="/parametrosambientales">
              <TitleCont>
                <FormattedMessage
                  id="modalintro.detallado.title"
                  defaultMessage="Cálculo detallado"
                />
              </TitleCont>
              <MainContent>
                <FormattedMessage
                  id="modalintro.detallado.content"
                  defaultMessage="<p> Este método se basa en un <span>formulario más extenso y detallado</span>, con cuestiones que pueden requerir un mayor conocimiento.</p><p>Obtendrá <span>resultados más precisos</span>, incluyendo <span>análisis gráfico</span> y una versión imprimible de los mismos.</p><p> Le sugerimos leer la sección de Información de interés, donde se citan las fuentes en las que se basa el modelo y sus limitaciones.</p><p> Esta versión es la recomendada para usuarios con conocimientos técnicos y/o usuarios con un mayor interés en los resultados.</p>"
                  values={{
                    p: (words) => <p>{words}</p>,
                    span: (words) => <span>{words}</span>,
                  }}
                />
              </MainContent>
              <MobileContent>
                <p>
                  <FormattedMessage
                    id="modalintro.detallado.short"
                    defaultMessage="Formulario extenso y complejo con análisis gráfico y resultados más detallados."
                  />
                </p>
              </MobileContent>
            </TarjetaModal>
          </Modal>
        </Row>
      </ModalContainer>
      <ModalFondo className="modalFondo" />
    </>
  );
};

export default ModalIntro;

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  color: white;
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
`;

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  min-width: 300px;
  /* border-top: 2px solid grey; */
  @media (max-width: ${size.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

const ModalFondo = styled.div`
  display: block;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

const TarjetaModal = styled(Link)`
  background: ${(props) => (props.primary ? 'var(--dark)' : 'var(--verydark)')};
  margin: 1rem;
  padding: 2rem 1rem;
  border-radius: 5px;
  border: 2px solid white;
  width: 310px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  box-shadow: 0px 2px 5px 3px rgba(255, 255, 255, 0.2);

  &:hover {
    background: linear-gradient(90deg, #388e3c, #4caf50);
  }
`;

const TitleCont = styled.div`
  width: 90%;
  background: whitesmoke;
  border-radius: 2px;
  color: black;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
`;

const MainContent = styled.div`
  width: 90%;
  border-radius: 2px;
  border: 2px solid whitesmoke;
  padding: 0.5rem;

  p {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 0.5rem;

    span {
      color: var(--verylight);
      font-weight: bold;
    }
  }

  @media (max-width: ${size.lg}) {
    display: none;
  }
`;

const MobileContent = styled.div`
  width: 90%;
  border-radius: 2px;
  border: 2px solid whitesmoke;
  padding: 0.5rem;
  display: none;

  p {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  @media (max-width: ${size.lg}) {
    display: initial;
  }
`;

const ModalDisclaimerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  color: white;
  font-family: 'Montserrat', sans-serif;
`;

const ModalDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;

  @media (max-width: ${size.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

const TarjetaModalDisclaimer = styled.div`
  background: ${(props) => (props.primary ? 'var(--dark)' : 'var(--verydark)')};
  padding: 1.5rem;
  border-radius: 5px;
  border: 2px solid white;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  box-shadow: 0px 2px 5px 3px rgba(255, 255, 255, 0.2);

  @media (max-width: ${size.lg}) {
    max-width: unset;
    max-height: 100vh;
  }
`;

const ContentDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    display: flex;

    justify-content: center;
    align-items: center;
    list-style-type: none;
    gap: 0.5rem;
    margin-bottom: 1rem;

    input {
      transform: scale(1.3);
      margin-right: 0.5rem;
    }
  }

  p {
    font-size: 0.8rem;
    text-align: start;
    margin-bottom: 0.5rem;
    text-align: justify;

    span {
      color: var(--verylight);
      font-weight: bold;
    }
  }

  @media (max-width: ${size.lg}) {
    overflow: auto;
  }
`;
