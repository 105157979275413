import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import NavigationButtons from '../UI/NavigationButtons';
import Dato from '../UI/Dato';
import NivelRiesgo from '../UI/NivelRiesgo';

import {
  FullSectionContainer,
  ResponsiveContainer,
  truncToPrecision,
} from '../../globalStyles';
import { FormattedMessage } from 'react-intl';
import FormNavigation from './FormNavigation';

const Simplificado = ({ stateData }) => {
  return (
    <SimplificadoCont>
      <FormattedMessage
        id="impresion.pagetitle"
        defaultMessage="Resumen de Resultados"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Vista de impresión o simplificada de los resultados obtenidos de infección por COViD-19 y otros resultados relacionados de interés"
            />
          </Helmet>
        )}
      </FormattedMessage>
      <Helmet>
        <title> </title>
      </Helmet>
      <StyledResponsiveContainer>
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: '680px',
          }}
        >
          <FormNavigation
            goBack={'/resultados'}
            disableNext
            maxWidth={'680px'}
          />
        </div>

        <Box id="resultados">
          <BarraTitulo>
            <Titulo>
              <FormattedMessage
                id="impresion.title"
                defaultMessage="Resumen de resultados"
              />
            </Titulo>
          </BarraTitulo>
          <Texto>
            {stateData.esCalcIA ? (
              <FormattedMessage
                id="resultados.short.ia"
                defaultMessage="En este recinto de <span>{volumen}</span> m³ de volumen, asumiendo un incidencia acumulada de <span>{ia}</span> casos / 100.000 habitantes, durante <span>{duracion}</span> minutos, dada una ventilación de <span>{renovaciones}</span> renovaciones/hora, existe un:<ul><li>RIESGO de infección por transmisión de aerosoles: <spanUpper>{riesgo1}</spanUpper>.</li><li>RIESGO de infección relativo asociado: <spanUpper>{riesgo2}</spanUpper>.</li><li>Promedio estadístico de casos de infección: <span>{casosResultantes}</span> casos (Tasa de ataque de <span>{tasaAtaque}</span> %).</li><li>Nivel de CO2 promedio: <span>{co2ProporcionMediaMezcla}</span> ppm.</li><li>Valor de CO2 máximo: <span>{co2IntDuracion}</span> ppm.</li></ul>"
                values={{
                  span: (words) => <span>{words}</span>,
                  spanUpper: (words) => (
                    <span style={{ textTransform: 'uppercase' }}>{words}</span>
                  ),
                  ul: (words) => <ul>{words}</ul>,
                  li: (words) => <li>{words}</li>,
                  volumen: stateData.volumen,
                  ia: stateData.casosIA100k,
                  duracion: stateData.duracion,
                  renovaciones: stateData.renovacionesAireDefinitivo,
                  riesgo1: (
                    <NivelRiesgo
                      resultado={stateData.riesgoInfeccion}
                      limite1={stateData.limiteHInf}
                      limite2={stateData.limiteHSup}
                    />
                  ),
                  riesgo2: (
                    <NivelRiesgo
                      resultado={stateData.riesgoInfeccionRelativo}
                      limite1={stateData.limiteHrInf}
                      limite2={stateData.limiteHrSup}
                    />
                  ),
                  casosResultantes: truncToPrecision(
                    stateData.casosResultantes,
                    2
                  ),
                  tasaAtaque: truncToPrecision(stateData.tasaAtaque * 100, 2),
                  co2ProporcionMediaMezcla: truncToPrecision(
                    stateData.co2ProporcionMediaMezcla,
                    0
                  ),
                  co2IntDuracion: truncToPrecision(stateData.co2IntDuracion, 0),
                }}
              />
            ) : (
              <FormattedMessage
                id="resultados.short"
                defaultMessage="En este recinto de <span>{volumen}</span> m³ de volumen, en el que permanecen <span>{personas}</span> ocupantes durante <span>{duracion}</span> minutos, dada una ventilación de <span>{renovaciones}</span> renovaciones/hora, existe un:<ul><li>RIESGO de infección por transmisión de aerosoles: <spanUpper>{riesgo1}</spanUpper>.</li><li>RIESGO de infección relativo asociado: <spanUpper>{riesgo2}</spanUpper>.</li><li>Promedio estadístico de casos de infección: <span>{casosResultantes}</span> casos (Tasa de ataque de <span>{tasaAtaque}</span> %).</li><li>Nivel de CO2 promedio: <span>{co2ProporcionMediaMezcla}</span> ppm.</li><li>Valor de CO2 máximo: <span>{co2IntDuracion}</span> ppm.</li></ul>"
                values={{
                  span: (words) => <span>{words}</span>,
                  spanUpper: (words) => (
                    <span style={{ textTransform: 'uppercase' }}>{words}</span>
                  ),
                  ul: (words) => <ul>{words}</ul>,
                  li: (words) => <li>{words}</li>,
                  volumen: stateData.volumen,
                  personas: stateData.personas,
                  duracion: stateData.duracion,
                  renovaciones: stateData.renovacionesAireDefinitivo,
                  riesgo1: (
                    <NivelRiesgo
                      resultado={stateData.riesgoInfeccion}
                      limite1={stateData.limiteHInf}
                      limite2={stateData.limiteHSup}
                    />
                  ),
                  riesgo2: (
                    <NivelRiesgo
                      resultado={stateData.riesgoInfeccionRelativo}
                      limite1={stateData.limiteHrInf}
                      limite2={stateData.limiteHrSup}
                    />
                  ),
                  casosResultantes: truncToPrecision(
                    stateData.casosResultantes,
                    2
                  ),
                  tasaAtaque: truncToPrecision(stateData.tasaAtaque * 100, 2),
                  co2ProporcionMediaMezcla: truncToPrecision(
                    stateData.co2ProporcionMediaMezcla,
                    0
                  ),
                  co2IntDuracion: truncToPrecision(stateData.co2IntDuracion, 0),
                }}
              />
            )}
          </Texto>

          <ParrillaSecciones>
            <SectionWrapper>
              <BarraSubtitulo>
                <Subtitulo>
                  <FormattedMessage
                    id="impresion.card1.title"
                    defaultMessage="OCUPACIÓN"
                  />
                </Subtitulo>
              </BarraSubtitulo>
              <ParrillaDatos>
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card1.p1"
                      defaultMessage="Ocupación"
                    />
                  }
                  valor={truncToPrecision(stateData.densidadSup, 1)}
                  unidad={['m', <sup>2</sup>]}
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card1.p2"
                      defaultMessage="Volumen por ocupante"
                    />
                  }
                  valor={truncToPrecision(stateData.densidadVol, 1)}
                  unidad={['m', <sup>3</sup>]}
                />
              </ParrillaDatos>
            </SectionWrapper>
            <SectionWrapper>
              <BarraSubtitulo>
                <Subtitulo>
                  <FormattedMessage
                    id="impresion.card2.title"
                    defaultMessage="Ventilación"
                  />
                </Subtitulo>
              </BarraSubtitulo>
              <ParrillaDatos>
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card2.p1"
                      defaultMessage="Nivel de ventilación en el recinto"
                    />
                  }
                  valor={stateData.renovacionesAireDefinitivo}
                  unidad={'ACH'}
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card2.p2"
                      defaultMessage="Caudal de ventilación por ocupante"
                    />
                  }
                  valor={truncToPrecision(
                    stateData.renovacionesAireDefinitivoOcupante,
                    1
                  )}
                  unidad={
                    <FormattedMessage
                      id="gen.lsocup"
                      defaultMessage="l / s ocup"
                    />
                  }
                />
              </ParrillaDatos>
            </SectionWrapper>
            <SectionWrapper>
              <BarraSubtitulo>
                <Subtitulo>
                  <FormattedMessage
                    id="impresion.card3.title"
                    defaultMessage="Emisión e inhalación"
                  />
                </Subtitulo>
              </BarraSubtitulo>
              <ParrillaDatos>
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card3.p1"
                      defaultMessage="Quanta por ocupante"
                    />
                  }
                  valor={truncToPrecision(stateData.quantaPersona, 4)}
                  unidad={'quanta'}
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card3.p2"
                      defaultMessage="Tasa de perdida de primer orden"
                    />
                  }
                  valor={truncToPrecision(stateData.tasaPerdidaPrimerOrden, 2)}
                  unidad={['h', <sup>-1</sup>]}
                />
              </ParrillaDatos>
            </SectionWrapper>
            <SectionWrapper>
              <BarraSubtitulo>
                <Subtitulo>
                  <FormattedMessage
                    id="impresion.card4.title"
                    defaultMessage="Riesgo de infección"
                  />
                </Subtitulo>
              </BarraSubtitulo>
              <ParrillaDatos>
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card4.p1"
                      defaultMessage="Riesgo de infección"
                    />
                  }
                  valor={truncToPrecision(stateData.riesgoInfeccion, 4)}
                  unidad={
                    <FormattedMessage
                      id="gen.unidad.h2ocupm3"
                      defaultMessage="h<sup>2</sup>·ocup/m<sup>3</sup>"
                      values={{
                        sup: (words) => <sup>{words}</sup>,
                      }}
                    />
                  }
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card4.p2"
                      defaultMessage="Riesgo de infección relativo"
                    />
                  }
                  valor={truncToPrecision(stateData.riesgoInfeccionRelativo, 4)}
                  unidad={['h', <sup>2&nbsp;</sup>, '/ m', <sup>3</sup>]}
                />
              </ParrillaDatos>
            </SectionWrapper>
            <SectionWrapper style={{ height: '225px' }}>
              <BarraSubtitulo>
                <Subtitulo>
                  <FormattedMessage
                    id="impresion.card5.title"
                    defaultMessage="Resultados condicionales de infección"
                  />
                </Subtitulo>
              </BarraSubtitulo>
              <ParrillaDatos>
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card5.p1"
                      defaultMessage="Probabilidad de infección individual"
                    />
                  }
                  valor={truncToPrecision(
                    stateData.probInfeccionPersona * 100,
                    4
                  )}
                  unidad={'%'}
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card5.p2"
                      defaultMessage="Casos de COViD-19 resultantes"
                    />
                  }
                  valor={truncToPrecision(stateData.casosResultantes, 4)}
                  unidad={
                    <FormattedMessage
                      id="gen.unidad.casos"
                      defaultMessage="casos"
                    />
                  }
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card5.p3"
                      defaultMessage="Susceptibles de enfermar"
                    />
                  }
                  valor={truncToPrecision(
                    stateData.personasSusceptiblesEnfermar,
                    4
                  )}
                  unidad={
                    <FormattedMessage
                      id="gen.unidad.casos"
                      defaultMessage="casos"
                    />
                  }
                />
              </ParrillaDatos>
            </SectionWrapper>

            <SectionWrapper style={{ height: '225px' }}>
              <BarraSubtitulo style={{ height: '50px' }}>
                <Subtitulo>
                  <FormattedMessage
                    id="impresion.card6.title"
                    defaultMessage="Riesgo de infección por CO<sub>2</sub>"
                    values={{
                      sub: (words) => <sub>{words}</sub>,
                    }}
                  />
                </Subtitulo>
              </BarraSubtitulo>
              <ParrillaDatos>
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card6.p1"
                      defaultMessage="Proporcion media de mezcla de CO<sub>2</sub>"
                      values={{
                        sub: (words) => <sub>{words}</sub>,
                      }}
                    />
                  }
                  valor={truncToPrecision(
                    stateData.co2ProporcionMediaMezcla,
                    0
                  )}
                  unidad={['ppm CO', <sub>2</sub>]}
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card6.p2"
                      defaultMessage="CO<sub>2</sub> a inhalar en 1 h para 1% de infección"
                      values={{
                        sub: (words) => <sub>{words}</sub>,
                      }}
                    />
                  }
                  valor={truncToPrecision(stateData.co2Hora1PorCiento, 0)}
                  unidad={'ppm'}
                />
                <Dato
                  nombre={
                    <FormattedMessage
                      id="impresion.card6.p3"
                      defaultMessage="Fracción de aire reinhalado"
                    />
                  }
                  valor={truncToPrecision(stateData.aireReinhalado * 100, 2)}
                  unidad={'%'}
                />
              </ParrillaDatos>
            </SectionWrapper>
          </ParrillaSecciones>
        </Box>
        <NavigationButtons
          to="/resultados"
          align={'center'}
          back={'red'}
          textES={'Atrás'}
          textEN={'Back'}
        />
      </StyledResponsiveContainer>
    </SimplificadoCont>
  );
};

export default Simplificado;

const SimplificadoCont = styled(FullSectionContainer)`
  background: var(--gdlight);
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  background: var(--gdwhite);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  max-width: 680px;
  border-radius: 3px;
  margin-bottom: 1rem;

  @media print {
    box-shadow: none;
  }
`;

const ParrillaSecciones = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`;

const BarraTitulo = styled.div`
  background: var(--verydark);
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
`;

const Titulo = styled.h4`
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-transform: uppercase;
  text-align: center;

  @media print {
    color: black;
  }
`;

const SectionWrapper = styled.div`
  background: var(--gdwhite);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 3px;
  width: 320px;

  @media print {
    box-shadow: none;
    border: 1px solid black;
  }
`;

const BarraSubtitulo = styled.div`
  background: whitesmoke;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--verydark);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--verydark);

  @media print {
    border: 1px solid black;
  }
`;

const Subtitulo = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  @media print {
    color: black;
  }
`;

const ParrillaDatos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Texto = styled.p`
  font-family: 'Nunito', sans-serif;
  line-height: 1.2;
  font-size: 0.9rem;
  font-weight: normal;
  color: black;
  padding: 1rem 1rem 1.5rem 1rem;
  text-align: justify;
  width: 100%;

  span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  sub {
    font-size: 0.6rem;
  }

  ul {
    padding-top: 0.3rem;
    li {
      margin-left: 1.5rem;
    }
  }
`;
