import React, { useState } from 'react';
import MsgEnglish from '../lang/english.json';
import MsgSpanish from '../lang/spanish.json';
import { IntlProvider } from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
  let defaultLocale;
  let defaultMsg;
  const lsLang = localStorage.getItem('lang');

  if (lsLang) {
    defaultLocale = lsLang;

    if (lsLang === 'es') {
      defaultMsg = MsgSpanish;
    } else if (lsLang === 'en') {
      defaultMsg = MsgEnglish;
    } else {
      defaultLocale = 'es';
      defaultMsg = MsgSpanish;
    }
  } else {
    defaultMsg = MsgSpanish;
    defaultLocale = 'es';
  }

  const [msg, setMsg] = useState(defaultMsg);
  const [locale, setLocale] = useState(defaultLocale);

  const setLang = (lang) => {
    switch (lang) {
      case 'spanish':
        setMsg(MsgSpanish);
        setLocale('es');
        localStorage.setItem('lang', 'es');
        break;
      case 'english':
        setMsg(MsgEnglish);
        setLocale('en');
        localStorage.setItem('lang', 'en');
        break;
      default:
        setMsg(MsgSpanish);
        setLocale('es');
    }
  };

  return (
    <langContext.Provider value={{ setLang: setLang, locale: locale }}>
      <IntlProvider locale={locale} messages={msg}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext };
