//CORE
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
//STYLES
import {
  Column,
  FullSectionContainer,
  ResponsiveContainer,
  Row,
  SectionTitle,
  size,
  truncToPrecision,
} from '../../globalStyles';
//IMAGES
import coronaVirus from '../../images/coronavirusBlue.svg';
//COMPONENTS
import NavigationButtons from '../UI/NavigationButtons.js';
import NivelRiesgo from '../UI/NivelRiesgo';
//DATA
import { CARGA_VIRAL_EMITIDA } from '../../data/constantes';

//LANG
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../context/langContext';
import FormNavigation from './FormNavigation.js';

const ResultadoSimplificado = (props) => {
  const lang = useContext(langContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Correccion de personas infectivas cuando se introduce que son mas o igual que las presentes.
  useEffect(() => {
    if (props.stateData.infectivas >= props.stateData.personas) {
      props.updateDatos(props.stateData.personas - 1, 'infectivas');
    }
  }, [props.stateData.infectivas, props.stateData.personas]);

  // Aseguramos que se carga el nivelEmisionViralInfectados correspondiente al percentil 85.
  useEffect(() => {
    if (
      props.stateData.nivelEmisionViralInfectados !== CARGA_VIRAL_EMITIDA.P85
    ) {
      props.updateDatos(CARGA_VIRAL_EMITIDA.P85, 'nivelEmisionViralInfectados');
    }
  }, [props.stateData.nivelEmisionViralInfectados]);

  const [nivel, setNivel] = useState('INADECUADA');
  const [nivelEN, setNivelEN] = useState('INSUFFICIENT');

  useEffect(() => {
    if (
      props.stateData.nivelVentilacionSimplificado ===
      'Inadecuado - Ventanas cerradas'
    ) {
      setNivel('INADECUADA');
      setNivelEN('INSUFFICIENT');
    }
    if (
      props.stateData.nivelVentilacionSimplificado ===
      'Medio - Ventanas entreabiertas'
    ) {
      setNivel('INTERMEDIA');
      setNivelEN('INTERMEDIATE');
    }
    if (
      props.stateData.nivelVentilacionSimplificado ===
      'Adecuado - Ventanas abiertas'
    ) {
      setNivel('ADECUADA');
      setNivelEN('SUFFICIENT');
    }
  }, [props.stateData.nivelVentilacionSimplificado]);

  return (
    <StyledFullSectionContainer light>
      <FormattedMessage
        id="resultados.pagetitle"
        defaultMessage="Resultados simplificados"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
            <meta
              name="description"
              content="Obtención y presentación visual simplificada de resultados relacionados con la infección por COViD-19, descripción ilustrativa y análisis"
            />
          </Helmet>
        )}
      </FormattedMessage>
      <Imagen src={coronaVirus} alt="Fondo que ilustra el COViD-19" />;
      <StyledResponsiveContainer>
        <FormNavigation goBack="/formulariosimplificado" disableNext />
        <Results>
          <SeccResults>
            <TitleCont>
              <Title>
                <FormattedMessage
                  id="rtdosimplif.title"
                  defaultMessage="RESULTADOS"
                />
              </Title>
            </TitleCont>
            <SectionBar>
              <h4>
                <FormattedMessage
                  id="rtdosimplif.subtitle1"
                  defaultMessage="Cálculo Simplificado"
                />
              </h4>
            </SectionBar>
            <Wrapper style={{ justifyContent: 'flex-start' }}>
              <Texto>
                <Row>
                  <Column>
                    <FormattedMessage
                      id="rtdosimplif.subtitle1.content1"
                      defaultMessage="<p>En este recinto de <span>{volumen}</span> m<sup>3</sup> de volumen, en el que permanecen <span>{personas}</span> ocupantes durante <span>{duracion}</span> minutos, dada una ventilación <span>{nivel}</span>, existe un:</p>"
                      values={{
                        p: (words) => <p>{words}</p>,
                        sup: (words) => <sup>{words}</sup>,
                        span: (words) => <span>{words}</span>,
                        volumen: props.stateData.volumen,
                        personas: props.stateData.personas,
                        duracion: props.stateData.duracion,
                        nivel: lang.locale === 'es' ? nivel : nivelEN,
                      }}
                    />
                    <ul>
                      <li>
                        <FormattedMessage
                          id="rtdosimplif.subtitle1.list1"
                          defaultMessage="RIESGO de infección por transmisión de aerosoles: <span>{Riesgo}</span>."
                          values={{
                            span: (words) => (
                              <span style={{ textTransform: 'uppercase' }}>
                                {words}
                              </span>
                            ),
                            Riesgo: (
                              <NivelRiesgo
                                resultado={props.stateData.riesgoInfeccion}
                                limite1={props.stateData.limiteHInf}
                                limite2={props.stateData.limiteHSup}
                              />
                            ),
                          }}
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="rtdosimplif.subtitle1.list2"
                          defaultMessage=""
                          values={{
                            span: (words) => (
                              <span style={{ textTransform: 'uppercase' }}>
                                {words}
                              </span>
                            ),
                            Riesgo: (
                              <NivelRiesgo
                                resultado={
                                  props.stateData.riesgoInfeccionRelativo
                                }
                                limite1={props.stateData.limiteHrInf}
                                limite2={props.stateData.limiteHrSup}
                              />
                            ),
                          }}
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="rtdosimplif.subtitle1.list3"
                          defaultMessage="Promedio estadístico de casos de infección: <span>{casosResultantes}</span> casos (Tasa de ataque de <span>{tasaAtaque}</span> %)."
                          values={{
                            span: (words) => (
                              <span style={{ textTransform: 'uppercase' }}>
                                {words}
                              </span>
                            ),
                            casosResultantes: truncToPrecision(
                              props.stateData.casosResultantes,
                              2
                            ),
                            tasaAtaque: truncToPrecision(
                              props.stateData.tasaAtaque * 100,
                              2
                            ),
                          }}
                        />
                      </li>
                    </ul>
                  </Column>
                </Row>
              </Texto>
            </Wrapper>
            <BoxAccent>
              <Texto style={{ color: 'white' }}>
                <TituloAccent>
                  <FormattedMessage
                    id="rtdosimplif.subtitle2"
                    defaultMessage="POSIBLES ACTUACIONES DE MEJORA"
                  />
                </TituloAccent>

                <p>
                  <FormattedMessage
                    id="rtdosimplif.subtitle2.content1"
                    defaultMessage="Si desea disminuir el nivel de riesgo existente durante el evento estudiado, se pueden implementar las siguientes actuaciones (por orden de efectividad):"
                  />
                </p>
                <ul>
                  <li>
                    <FormattedMessage
                      id="rtdosimplif.subtitle2.list1"
                      defaultMessage="Uso de mascarillas de tipo FFP2/KN95 bien ajustadas."
                    />
                  </li>
                  <li>
                    <ul>
                      <FormattedMessage
                        id="rtdosimplif.subtitle2.list2"
                        defaultMessage="Aumentar la tasa de ventilación, ya sea por:"
                      />

                      <li>
                        <FormattedMessage
                          id="rtdosimplif.subtitle2.list2.list1"
                          defaultMessage="Apertura continuada y distribuida de puertas y ventanas (ventilación cruzada)."
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="rtdosimplif.subtitle2.list2.list2"
                          defaultMessage="Empleo de un sistema de ventilación mecánica controlada."
                        />
                      </li>
                    </ul>
                  </li>
                  <li>
                    <FormattedMessage
                      id="rtdosimplif.subtitle2.list3"
                      defaultMessage="Adición de sistemas de purificación de aire (filtrado y esterilización por UV), disponibles en el cálculo detallado."
                    />
                  </li>
                </ul>
                <p>
                  <FormattedMessage
                    id="rtdosimplif.subtitle2.list4"
                    defaultMessage="Es muy recomendable que esta tasa de ventilación se controle mediante monitorización continua de la concentración de CO<sub>2</sub>."
                    values={{
                      sub: (words) => <sub>{words}</sub>,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="rtdosimplif.subtitle2.content2"
                    defaultMessage="Asimismo, se recomienda mantener en lo posible una distancia interpersonal de 2 m con no convivientes, dado que, a menor distancia, aumenta la concentración de aerosoles exhalados con  posible carga infectiva (contagio por proximidad)."
                  />
                </p>
              </Texto>
            </BoxAccent>
          </SeccResults>
        </Results>
        <Row>
          <Column width={50}>
            <NavigationButtons
              to="/formulariosimplificado"
              align={'start'}
              back={'red'}
              textES={'Atrás'}
              textEN={'Back'}
            />
          </Column>
          <Column width={50}>
            <NavigationButtons
              to="/parametrosambientales"
              resultadosdarkhover
              align={'end'}
              textES={'Detallado'}
              textEN={'Detailed'}
            />
          </Column>
        </Row>
      </StyledResponsiveContainer>
    </StyledFullSectionContainer>
  );
};

export default ResultadoSimplificado;

const Imagen = styled.img`
  position: fixed;
  bottom: -60px;
  right: -60px;
  width: 600px;
  z-index: 1;

  @media (max-width: ${size.sm}) {
    width: 250px;
  }
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  position: relative;
  z-index: 2;
`;

const StyledFullSectionContainer = styled(FullSectionContainer)`
  position: relative;
  z-index: 0;
`;

const Results = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  sub {
    font-size: 0.7rem;
  }
`;

const SeccResults = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--gdwhite);
  backdrop-filter: blur(3px);
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
`;

const TitleCont = styled.div`
  background: var(--gdverydark);
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  backdrop-filter: blur(3px);
  width: 100%;
  margin-bottom: 2rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
`;

const Title = styled(SectionTitle)`
  color: var(--verylight);
  padding: 0;
  font-size: 1.8rem;
`;

const SectionBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 97.5%;
  min-height: 3.5rem;
  background: #e5e9eb;

  padding: 0.5rem;
  margin: 1.5rem 0 1rem 0;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  color: var(--verydark);

  h4 {
    width: 100%;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 97.5%;
  background: white;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  color: var(--verydark);
  margin-bottom: 1rem;
`;

const Texto = styled.p`
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: normal;
  color: black;
  padding: 1rem 1rem 1.5rem 1rem;
  text-align: justify;

  span {
    font-weight: bold;
    font-size: 0.9rem;
  }

  sub {
    font-size: 0.6rem;
  }

  ul {
    margin-bottom: 1rem;
    li {
      margin-left: 1.2rem;
      padding-left: 0.5rem;
    }
  }

  @media (max-width: ${size.md}) {
    text-align: start;
  }
`;

const BoxAccent = styled.div`
  background: var(--dark);
  width: 97.5%;

  ul {
    margin-bottom: 0.5rem;
  }
`;

const TituloAccent = styled.div`
  background: white;
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: var(--dark);
  font-weight: bold;
  text-align: center;
  font-family: 'Nunito', sans-serif;
`;
