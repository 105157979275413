import React from 'react';
import { Column, Row } from '../../globalStyles';
import NavigationButtons from '../UI/NavigationButtons';

const FormNavigation = ({
  goBack,
  goNext,
  disableBack,
  disableNext,
  maxWidth,
}) => {
  return (
    <Row>
      {disableBack && (
        <Row>
          <Column width={50}>
            <NavigationButtons
              to={goNext}
              rightnavigationarrow
              red
              align={'end'}
            />
          </Column>
        </Row>
      )}
      {disableNext && (
        <Row style={{ maxWidth: maxWidth, margin: 'auto' }}>
          <Column>
            <NavigationButtons
              to={goBack}
              leftnavigationarrow
              blue
              align={'start'}
            />
          </Column>
        </Row>
      )}

      {!disableBack && !disableNext && (
        <Row>
          <Column width={50}>
            <NavigationButtons
              to={goBack}
              leftnavigationarrow
              grey
              align={'start'}
            />
          </Column>
          <Column width={50}>
            <NavigationButtons
              to={goNext}
              rightnavigationarrow
              red
              align={'end'}
            />
          </Column>
        </Row>
      )}
    </Row>
  );
};

export default FormNavigation;
