import React from 'react';
import styled from 'styled-components';

const Dato = ({ nombre, valor, unidad }) => {
  return (
    <DatoCont>
      <Nombre>{nombre}</Nombre>
      <RecuadroValorUnidad>
        <Valor>{valor}</Valor>
        <Unidad>{unidad}</Unidad>
      </RecuadroValorUnidad>
    </DatoCont>
  );
};

export default Dato;

const DatoCont = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: 0.2rem;
  font-family: 'Montserrat', sans-serif;
  grid-template-rows: auto;
  grid-template-columns: 8rem 10rem;
  grid-template-areas: 'nombre valorUnidad';
`;

const Nombre = styled.div`
  grid-area: nombre;
  font-weight: bold;
  font-size: 0.7rem;
  border-radius: 2px;
  color: white;
  text-align: center;
  width: 100%;
  padding: 0.5rem 0;
  color: black;
`;
const Valor = styled.div`
  grid-area: valor;
  text-align: center;
  margin: auto 0;
  color: black;
  font-weight: bold;
  font-size: 0.8rem;
`;
const Unidad = styled.div`
  grid-area: unidad;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
`;

const RecuadroValorUnidad = styled.div`
  grid-area: valorUnidad;
  display: grid;
  gap: 0.3rem;
  grid-template-areas: 'valor unidad';
  grid-template-rows: auto;
  grid-template-columns: 3.7rem auto;
  border: 1px solid var(--verydark);
  padding: 0.3rem;
  background: white;
`;
