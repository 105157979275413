export const ticksX = [0.0001, 0.001, 0.01, 0.1, 1, 5];
export const ticksY = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const data = [
  {
    // Prisión - Sala de estar
    y: 1,
    bajo: 0.00047,
    medio: 0.01194,
    alto: 0.02404,
  },
  {
    // Aula secundaria
    y: 2,
    bajo: 0.00089,
    medio: 0.01116,
    alto: 0.0225,
  },
  {
    // Teatro - Sala de conciertos
    y: 3,
    bajo: 0.00102,
    medio: 0.02313,
    alto: 0.04612,
  },
  {
    // Restaurante
    y: 4,
    bajo: 0.0016,
    medio: 0.00393,
    alto: 0.00776,
  },
  {
    // Hospital - Sala de examen
    y: 5,
    bajo: 0.00071,
    medio: 0.2614,
    alto: 0.52617,
  },
  {
    // Biblioteca
    y: 6,
    bajo: 0.0001,
    medio: 0.00037,
    alto: 0.00074,
  },
  {
    // Lugar de culto religioso
    y: 7,
    bajo: 0.00036,
    medio: 0.03808,
    alto: 0.07665,
  },
  {
    // Supermercado
    y: 8,
    bajo: 0.00035,
    medio: 0.01314,
    alto: 0.0262,
  },
  {
    // Aula de educación física
    y: 9,
    bajo: 0.0001,
    medio: 0.01419,
  },
  {
    // Restaurante Guangzhou
    y: 10,
    bajo: 0.00169,
    medio: 0.02313,
  },
  {
    // Coro Skagit
    y: 11,
    bajo: 0.00471,
    medio: 0.05428,
  },
  {
    // Cena familiar numerosa
    y: 12,
    bajo: 0.02427,
    medio: 0.49676,
  },
];
