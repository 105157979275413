import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { langContext } from '../../context/langContext';
import es from '../../images/es.svg';
import en from '../../images/en.svg';

const Header = () => {
  const lang = useContext(langContext);

  return (
    <NavBar>
      <div>
        <StyledLink to="/">
          <h1>
            Covid Risk<sup>airborne</sup>
          </h1>
        </StyledLink>
      </div>
      <div>
        <button
          className="langBtn"
          onClick={() => {
            lang.setLang('spanish');
          }}
        >
          <img src={es} alt="Icono bandera España" />
        </button>
        <button
          className="langBtn"
          onClick={() => {
            lang.setLang('english');
          }}
        >
          <img src={en} alt="Icono bandera Reino Unido" />
        </button>
      </div>
    </NavBar>
  );
};

export default Header;

const NavBar = styled.nav`
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--verydark);
  background: var(--gdverydark);
  color: var(--verylight);
  font-family: 'Montserrat', sans-serif;
  z-index: 2;

  div {
    h1 {
      padding: 0.5rem 1rem;
      min-width: 239.2px;
      font-size: 1.5rem;

      sup {
        color: var(--light);
        font-size: 0.8rem;
        padding: 0 0.3rem;
      }
    }
  }

  .langBtn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.3rem;

    &:last-child {
      margin-right: 1rem;
    }

    &:hover {
      img {
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
      }
    }

    @media (max-width: 355px) {
      padding: 0rem;

      &:last-child {
        margin-right: 1px;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  &:visited {
    color: white;
  }
  color: white;
`;
